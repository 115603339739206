import * as React from "react";
import {useState} from "react";
import {css} from "@linaria/core";

import {getThemeVariable} from "../../styles/linaria_variable_factory";
import {gtmSelectHomepageCitiesTab} from "../../tracking/google_tag_manager/gtm_select_homepage_cities_tab";
import {CityListFilterTabCities} from "../constatns/constants";
import {CitiesSectionTabs} from "./CitiesSectionTabs";

export const CitesSection = () => {
    const [citiesFilter, setCityFilter] = useState<CityListFilterTabCities | null>(null);

    const onFilterChange = (tab: CityListFilterTabCities) => {
        if (citiesFilter === tab) {
            setCityFilter(null);
        } else {
            setCityFilter(tab);
            gtmSelectHomepageCitiesTab(tab);
        }
    };

    return (
        <section className={sectionHolder}>
            <CitiesSectionTabs activeFilter={citiesFilter} onFilterChange={onFilterChange} />
        </section>
    );
};

const sectionHolder = css`
    background: ${getThemeVariable("colors-gray_very_bright")};
    padding: 2rem 1.2rem;
`;
