import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink} from "@web2/gh_routes";
import {GoogleMap, MarkerGroupDefinition} from "@web2/map";
import {useMounted} from "@web2/react_utils";

import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {GOOGLE_MAPS_API_KEY} from "../../app/constants/keys";
import {imageStyles} from "../../styles/mixins";
import {AboutUsLPHeader} from "../atoms/about_us_landing_page_styles";

import markerIcon from "../../styles/assets/svg/gethome_pin.svg";
import icon2 from "../../styles/assets/svg/mail.svg";
import icon1 from "../../styles/assets/svg/placeholder.svg";

export const AboutUsContactSection = () => {
    const mapConfig = {
        maxZoom: 19,
        zoom: 19,
        gestureHandling: "cooperative" as const,
        center: {lat: 52.1904798, lng: 21.0172476},
        streetViewControl: true,
        mapTypeControl: false,
        fullscreenControl: true
    };

    const markers: Record<string, MarkerGroupDefinition> = {
        default: {
            list: [{coords: [21.0174576, 52.1904998], icon: markerIcon, options: {scaledSize: {width: 45, height: 45}}}]
        }
    };

    const isMounted = useMounted();

    return (
        <>
            <Wrapper>
                <SectionHeader>Kontakt</SectionHeader>

                <BoxWrapper>
                    <ContactBox justifyContent="center">
                        <Icon src={icon1} height={90} width={83} />
                        <Paragraph fontSize={18}>Adres</Paragraph>
                        <ParagraphLight fontSize={12}>
                            Property Group Sp. z o.o. <br />
                            02-627 Warszawa <br />
                            ul. Adama Naruszewicza 27 lok. 101
                        </ParagraphLight>
                    </ContactBox>

                    <ContactBox justifyContent="center">
                        <Icon src={icon2} height={90} width={83} />
                        <Paragraph fontSize={18}>Dane kontaktowe</Paragraph>
                        <ParagraphLight fontSize={12}>
                            <a href="tel:+48733133531">Tel: +48 733 133 531</a>
                            Kontakt w dni robocze: 09.00-17.00
                            <a href="mailto:biuro@gethome.pl">E-mail: biuro@gethome.pl</a>
                        </ParagraphLight>
                    </ContactBox>
                </BoxWrapper>
                <p css={paragraphTAndC}>
                    Administratorem danych jest Property Group Sp. z o.o.{" "}
                    <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                        więcej o ochronie danych
                    </a>
                    .
                </p>
            </Wrapper>
            <MapWrapper>
                <Map>
                    {isMounted && (
                        <GoogleMap
                            apiKey={GOOGLE_MAPS_API_KEY}
                            config={mapConfig}
                            markers={markers}
                            fitBoundsOnUpdate={false}
                            customZoomOffset={{lat: 0.003, lng: 0.003}}
                        />
                    )}
                </Map>
            </MapWrapper>
        </>
    );
};

/*
Styles
 */

const Wrapper = styled.section`
    background: linear-gradient(180deg, #fbfbfb 0%, #f6f6f6 100%);
    padding: 1rem;
`;

const MapWrapper = styled(Flex)``.withComponent("section");

const SectionHeader = styled(AboutUsLPHeader)`
    text-align: center;
`;
const BoxWrapper = styled(FlexColumn)`
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        flex-direction: row;
        justify-content: center;
    }
`;

const ContactBox = styled(FlexColumn)`
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding: 1rem 0 2rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        height: 280px;
        width: 380px;

        &:first-of-type {
            margin-right: 2.5rem;
        }
    }
`;

const Icon = styled("img")`
    @media screen and (max-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        height: 65px;
        width: 60px;
    }

    ${imageStyles};
    margin: 0 auto;
`;

const Paragraph = styled(ParagraphGray)`
    font-weight: 500;
    line-height: 2.7rem;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        font-size: 2.4rem;
        line-height: 3.5rem;
    }
`;

const ParagraphLight = styled(ParagraphGray)`
    font-weight: 300;
    line-height: 1.8rem;
    text-align: center;

    a {
        text-decoration: none;
        color: #37474f;
        display: block;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
`;

const Map = styled.div`
    height: 200px;
    width: 100%;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: 500px;
    }
`;
const paragraphTAndC = (theme: Theme) => css`
    font-size: ${theme.fonts.font_size_small};
    max-width: 905px;
    text-align: center;
    margin: 2rem auto;
    color: ${theme.colors.text_color};

    a {
        color: ${theme.colors.text_color};
    }

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: ${theme.fonts.font_size_base};
    }
`;
