import {OfferDealType} from "../../../offer/utils/constants_offer";
import {ViewType} from "../view_type";

export const getGtmOfferListViewType = (dealType: OfferDealType) => {
    return dealType === OfferDealType.SELL
        ? ViewType.OFFER_LIST_FOR_SALE
        : dealType === OfferDealType.RENT
          ? ViewType.OFFER_LIST_FOR_RENT
          : ViewType.OFFER_LIST_ALL;
};
