import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {ButtonGreen} from "../../app/atoms/Buttons";
import {FlexColumn} from "../../app/atoms/Flex";
import {GreenDarkButton} from "./atoms";
import {StyledBuylandoButton} from "./atoms";

const link_PR = "https://buylando.pl/panel/rynek-pierwotny/";
export const HeroBox = () => {
    return (
        <>
            <ContactBox justifyContent="center">
                <p css={boxText}>
                    Rozpocznij współpracę z <strong>serwisem Buylando</strong>, dzięki któremu skutecznie sprzedasz swoją działkę. Oferta trafi do prywatnych
                    osób poszukujących działek oraz deweloperów i inwestorów.
                </p>
                <SellButton href={link_PR}>Sprzedaj działkę już teraz</SellButton>
            </ContactBox>
        </>
    );
};

const ContactBox = styled(FlexColumn)`
    border-radius: 4px;
    padding: 4rem 2rem;
    margin: 0 auto;
    margin-top: -85px;
    z-index: 10;
    background: linear-gradient(90deg, #9069c0 0%, #7848b1 100%);
    width: 90%;
    position: relative;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xs}) {
        max-width: 650px;
        margin-top: -115px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        margin-top: -155px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xl}) {
        margin-top: -145px;
    }
`;

export const boxText = (theme: Theme) => css`
    margin: 0 auto;
    color: #fff;
    text-align: center;
    font-size: ${theme.fonts.font_size_big};
    line-height: 1.5;
`;

const SellButton = styled(StyledBuylandoButton)`
    margin: 3rem auto 1rem;
    height: 65px;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.font_size_base};
    @media (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        font-size: ${(props) => props.theme.fonts.font_size_big};
        padding: 1.5rem 6rem;
    }
`;
