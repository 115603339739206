import * as React from "react";
import {useEffect, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {darken} from "polished";
import {appPath} from "@web2/gh_routes";

import {ButtonGreen} from "../../app/atoms/Buttons";
import {GridStatic} from "../../app/atoms/Layout";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {HeroSectionContactElement} from "./HeroSectionContactElement";
import {HeroSectionSimpleContactBtn} from "./HeroSectionSimpleContactBtn";

import coverImg from "../../styles/assets/background/agency_lp_cover_light.png";
import logo from "../../styles/assets/logo/gethome.svg";

export const HeroSection = () => {
    const [textIndex, setTextIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const headingTexts = ["najlepsze biura nieruchomości", "brak duplikatów ofert", "jasne zasady współpracy", "rozliczenie za efekt"];

    useEffect(() => {
        const txtInterval = setInterval(() => {
            setTextIndex((textIndex) => (textIndex === headingTexts.length - 1 ? 0 : textIndex + 1));
        }, 4000);

        return () => clearInterval(txtInterval);
    }, []);

    useEffect(() => {
        setTimeout(() => setIsLoading(true), 500);
        setTimeout(() => setIsLoading(false), 4000);
    }, [textIndex]);

    return (
        <section css={heroWrapper}>
            <GridStatic width="1230px" css={contentWrapper}>
                <div css={headingWrapper}>
                    <div css={ghLogo} />
                    <span>oznacza</span>
                    <h1>rozliczenie za efekt</h1>
                </div>

                <h2 css={subHeading}>Pomożemy Ci dotrzeć do osób realnie poszukujących nieruchomości.</h2>

                <RegisterButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn}>
                    rozpocznij współpracę
                </RegisterButton>

                <div css={subText}>
                    Masz pytania? Z chęcią <a href="#contact">odpowiemy!</a>
                </div>

                <HeroSectionSimpleContactBtn href="#contact" />
            </GridStatic>

            <HeroSectionContactElement />
        </section>
    );
};

/*
HeroSectionStyles
 */

const heroWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    background-image: url("${coverImg}");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    padding: 4rem 0.5rem;
    max-height: 540px;
    height: auto;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        background-position: bottom;
        padding: 9rem 0.5rem 6rem;
    }
`;

const contentWrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ghLogo = (theme: Theme) => css`
    width: 140px;
    height: 32px;
    margin-right: 1rem;
    background: url("${logo}") no-repeat center;
    margin-bottom: 0.5rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        width: 263px;
        height: 60px;
        margin-bottom: unset;
        margin-right: 2rem;
    }
`;

const headingWrapper = (theme: Theme) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    h1 {
        font-size: 1.6rem;
        color: ${theme.colors.brand_primary};
        margin: 0;
        line-height: 2.7rem;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            height: 2px;
            width: 100%;
            background: #45bc83;
            z-index: 2;
            transition: width 0.3s -0.1s;
        }

        &.is-loading {
            &:after {
                width: 100%;
                transition: width 3s;
            }
        }
    }

    span {
        font-size: 1.7rem;
        color: ${theme.colors.text_color};
        line-height: 2.7rem;
        font-weight: 500;
        margin-right: 0.5rem;
    }

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        h1 {
            font-size: 4rem;
            color: ${theme.colors.brand_primary};
            margin: 0;
            line-height: 4rem;

            &:after {
                height: 3px;
                bottom: -10px;
            }
        }

        span {
            font-size: 4rem;
            color: ${theme.colors.text_color};
            line-height: 4rem;
            font-weight: 500;
            margin-right: 1rem;
        }
    }
`;

const subHeading = (theme: Theme) => css`
    font-size: 1.4rem;
    color: ${theme.colors.text_color};
    text-align: center;
    font-weight: 400;
    margin-bottom: 4rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 2.5rem;
    }
`;

const RegisterButton = styled(ButtonGreen)`
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    box-shadow: 0 0 1.5rem 0.3rem rgba(200, 209, 219, 0.83);

    @media (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        font-size: 2.4rem;
        padding: 1.5rem 6rem;
        margin-bottom: 3rem;
    }
`.withComponent("a");

const subText = (theme: Theme) => css`
    font-weight: 500;
    color: ${theme.colors.text_color};
    font-size: 1.2rem;

    a {
        color: ${theme.colors.dark_blue};
        text-decoration: none;

        &:focus,
        &:hover,
        &:active {
            text-decoration: none;
            color: ${darken(0.1, theme.colors.dark_blue)};
        }
    }

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 1.4rem;
        color: ${theme.colors.gray_light};
    }
`;
