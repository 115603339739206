import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {GridStatic} from "../../app/atoms/Layout";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {CollaborationGraph} from "./CollaborationGraph";
import {sectionHeaderBase} from "./RegisterChecklist";

export const CollaborationStartSection = () => (
    <section css={collaborationStartWrapper}>
        <GridStatic width="1230px">
            <h2 css={sectionHeaderBase}>Jak zacząć współpracę?</h2>

            <Paragraph>
                Zadbaliśmy o Twoją wygodę w eksporcie danych. Jesteśmy zintegrowani w dedykowany sposób z ASARI, EstiCRM, Galactica Virgo, Mediarent oraz IMO.
                Pracujemy nad kolejnymi integracjami.
            </Paragraph>

            <CollaborationGraph />

            <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg">
                Zarejestruj biuro nieruchomości
                <ArrowNextIcon mainColor="#fff" />
            </StyledAgencyButton>
        </GridStatic>
    </section>
);

/*
CollaborationStartSection Styles
 */

const collaborationStartWrapper = (theme: Theme) => css`
    padding: 2rem 0.5rem;
    background: linear-gradient(180deg, #fbfbfb 0%, #f6f6f6 100%);

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 5rem 0.5rem 2rem;
    }
`;

const Paragraph = styled(ParagraphGray)`
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 auto 5rem;
    max-width: 905px;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        margin: 0 auto 6.5rem;
    }
`;
