import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {ParagraphLight} from "../../app/atoms/Paragraph";

import asari from "../../styles/assets/svg/asari_integration_icon.svg";
import base from "../../styles/assets/svg/data_base_icon.svg";
import evaluation from "../../styles/assets/svg/evaluation_icon.svg";
import mapSearch from "../../styles/assets/svg/no_duplicates_icon.svg";
import verification from "../../styles/assets/svg/research_icon.svg";

export const MapSearchGraph = () => (
    <Graph>
        <ContainerTop justifyContent={"space-between"}>
            <RelativeContainer>
                <ParagraphLeft fontSize={16}>
                    Biuro nieruchomości dokonuje <b>bezpiecznego</b> eksportu z ASARI, EstiCRM, Galactica Virgo, Mediarent lub IMO do gethome.pl
                </ParagraphLeft>
                <Icon src={asari} alt="asari" top={"5px"} right={"-60px"} />
            </RelativeContainer>

            <RelativeContainer>
                <Icon src={base} alt="base" top={"5px"} left={"-60px"} />
                <ParagraphRight fontSize={16}>
                    <b>Baza jest specjalnie zabezpieczona po naszej stronie!</b> Zadbaliśmy o nowoczesne rozwiązania technologiczne.
                </ParagraphRight>
            </RelativeContainer>
        </ContainerTop>

        <GraphDivider>
            <Image src={mapSearch} alt="map search" height={120} width={120} loading="lazy" />
        </GraphDivider>

        <ContainerBottom justifyContent={"space-between"}>
            <RelativeContainer>
                <ParagraphLeft fontSize={16}>Nasz system otrzymuje dane o ofertach i publikuje je na portalu. Trwa to zazwyczaj około 2 dni.</ParagraphLeft>
                <Icon src={verification} alt="evaluation" top={"20px"} right={"-60px"} />
            </RelativeContainer>

            <RelativeContainer>
                <Icon src={evaluation} alt="verification" top={"20px"} left={"-60px"} />
                <ParagraphRight fontSize={16}>
                    Stale ulepszamy portal wprowadzając szereg udogodnień dla pośredników oraz nowych funkcjonalności dla osób poszukujących.
                </ParagraphRight>
            </RelativeContainer>
        </ContainerBottom>
    </Graph>
);

/*
NoDuplicatesGraph Styles
 */

const ParagraphRight = styled(ParagraphLight)`
    letter-spacing: 0.22px;
    line-height: 2.1rem;
    text-align: center;
    a {
        color: inherit;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        text-align: left;
    }
`;

const ParagraphLeft = styled(ParagraphLight)`
    text-align: center;
    letter-spacing: 0.22px;
    line-height: 2.1rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        text-align: right;
    }
`;

const Graph = styled(Flex)`
    position: relative;
    max-width: 790px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    > div {
        height: 100%;

        @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
            height: 50%;

            p {
                max-width: 277px;
            }
        }
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        height: 420px;
        margin-bottom: 4rem;
    }
`;

const ContainerTop = styled(Flex)`
    position: relative;
    flex-direction: column;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        flex-direction: row;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 50%;
            width: 2px;
            height: 125px;
            background-color: ${(props) => props.theme.colors.brand_primary_light};
        }
    }
`;

const ContainerBottom = styled(Flex)`
    position: relative;
    flex-direction: column;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        flex-direction: row;
        padding-top: 6rem;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 50%;
            width: 2px;
            height: 125px;
            background-color: ${(props) => props.theme.colors.brand_primary_light};
        }
    }
`;

interface IImageStylesProps {
    theme?: Theme;
    height: number;
    width: number;
}

const imageStyles = (props: IImageStylesProps) => css`
    @media screen and (min-width: ${props.theme?.breakpoints.screen_sm}) {
        height: ${`${props.height}px`};
        width: ${`${props.width}px`};
        position: absolute;
        right: 50%;
        bottom: 0;
        transform: translate(50%, 50%);
    }
`;

const Image = styled("img")(imageStyles);

const GraphDivider = styled.span`
    display: none;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        display: block;
        position: relative;

        &:before {
            content: "";
            width: 300px;
            height: 2px;
            background-color: ${(props) => props.theme.colors.brand_primary_light};
            position: absolute;
            bottom: 50%;
            left: 0;
        }

        &:after {
            content: "";
            width: 300px;
            height: 2px;
            background-color: ${(props) => props.theme.colors.brand_primary_light};
            position: absolute;
            bottom: 50%;
            right: 0;
        }
    }
`;

const RelativeContainer = styled(FlexColumn)`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;

    &:nth-of-type(odd) {
        flex-direction: column-reverse;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        position: relative;

        img {
            position: absolute;
        }

        &:nth-of-type(odd) {
            flex-direction: column;
        }
    }
`;

interface IIconStylesProps {
    theme?: Theme;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
}

const iconStyles = (props: IIconStylesProps) => css`
    height: 30px;
    width: 30px;
    display: block;
    margin-bottom: 1.5rem;

    @media screen and (min-width: ${props.theme?.breakpoints.screen_sm}) {
        position: absolute;
        margin-bottom: 0;
        top: ${`${props.top}`};
        right: ${`${props.right}`};
        bottom: ${`${props.bottom}`};
        left: ${`${props.left}`};
    }
`;

const Icon = styled("img")(iconStyles);
