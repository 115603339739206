import {apiLink} from "../../app/routes/api_link";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {agencyRegistrationInitialValues} from "../components/AgencyRegisterForm";

export enum AgencyRegistrationType {
    STANDARD = 1,
    PREMIUM = 2
}

export const agencyRegistrationPost = (jsonData: Partial<typeof agencyRegistrationInitialValues>, isPremium?: boolean) => {
    const url = apiLink.agencies.registrations.simple({})(null);

    const data = {
        ...jsonData,
        registration_type: isPremium ? AgencyRegistrationType.PREMIUM : AgencyRegistrationType.STANDARD
    };

    return postRequest({}, url, data).then(() => {
        return true;
    });
};
