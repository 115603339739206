import {Dispatch} from "redux";

import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {defaultMetaData} from "../../meta_data/reducers/meta_data_reducer";

export const update404NotFoundMetaData = (pathname: string) => (dispatch: Dispatch) => {
    const title = "404 - Nie znaleziono strony";

    const description = defaultMetaData.description;

    dispatch(updateMetaData(title, description, {pathname, query: null}, {hideSuffix: false}));

    return Promise.resolve(true);
};
