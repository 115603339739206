import React, {ReactNode} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {ISection} from "../reducer/site_map_reducer";
import {getSiteMapSectionData, SiteMapSection} from "../utils/siteMapUtils";

interface IProps {
    link: ISection;
    isTopLevel?: boolean;
    children?: ReactNode;
}

export const SiteMapItem = (props: IProps) => {
    const sectionData = getSiteMapSectionData(props.link.section as SiteMapSection);

    return props.isTopLevel ? (
        <li css={sitemMapListItem}>
            <SitemMapTitle>{sectionData.title}</SitemMapTitle>

            {props.children}
        </li>
    ) : (
        <>{props.children}</>
    );
};

const sitemMapListItem = css`
    list-style: none;
`;
export const SitemMapTitle = styled.h2`
    color: ${(props) => props.theme.colors.gray_dark};
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.5625;
    display: block;
`;
