import * as React from "react";
import styled from "@emotion/styled";
import {map} from "lodash";

import {FlexColumn} from "../../app/atoms/Flex";
import {ParagraphLight} from "../../app/atoms/Paragraph";
import {imageStyles} from "../../styles/mixins";
import {AboutUsLPHeader} from "../atoms/about_us_landing_page_styles";
import {aboutUsStats} from "../constants/about_us_data";

export const NumberSection = () => {
    return (
        <Wrapper>
            <Container>
                <SectionHeader>gethome.pl w liczbach</SectionHeader>

                <InnerWrapper>
                    {map(aboutUsStats, (item, idx) => (
                        <div key={idx} className="stats-item">
                            <ImageHolder>
                                <ImageIcon src={item.image} height={item.height} width={item.width} loading="lazy" />
                            </ImageHolder>

                            <Paragraph>
                                <span>{item.number}</span> {item.text}
                            </Paragraph>
                        </div>
                    ))}
                </InnerWrapper>
            </Container>
        </Wrapper>
    );
};

/*
Styles
 */

const Wrapper = styled.section`
    padding: 1rem;
    background: linear-gradient(180deg, #fbfbfb 0%, #f6f6f6 100%);
`;

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;

const ImageHolder = styled.div`
    height: 108px;
    width: 100px;
    margin: 2.5rem auto 0;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        img {
            height: 100%;
            width: auto;
        }
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: 157px;
        width: 145px;
        margin-top: 1rem;
    }
`;

const ImageIcon = styled.img`
    ${imageStyles};
`;

const InnerWrapper = styled(FlexColumn)`
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .stats-item {
            width: calc(100% / 2);
        }
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        .stats-item {
            max-width: 370px;
            width: calc(100% / 3);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 6rem;

            &:nth-of-type(2),
            &:nth-of-type(5) {
                margin: 0 4rem 6rem;
            }
        }
    }
`;

const SectionHeader = styled(AboutUsLPHeader)`
    text-align: center;
`;

const Paragraph = styled(ParagraphLight)`
    font-size: 1.4rem;
    line-height: 2.5rem;
    text-align: center;
    padding: 2rem 0;
    max-width: 275px;
    margin: 0 auto;

    span {
        font-size: 2.5rem;
        color: ${(props) => props.theme.colors.brand_primary};
        font-weight: 600;
        margin-right: 0.5rem;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        font-size: 1.8rem;
        line-height: 2.7rem;
        padding: 0.5rem 0;
        margin: unset;
        max-width: unset;

        span {
            font-size: 3.5rem;
            line-height: 5.3rem;
        }
    }
`;
