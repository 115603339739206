import * as React from "react";
import {connect} from "react-redux";
import {css, Theme} from "@emotion/react";
import {Breadcrumbs} from "@web2/breadcrumbs";
import {appLink} from "@web2/gh_routes";

import {GridStatic} from "../../app/atoms/Layout";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {Footer} from "../../footer/Footer";
import {mb_xxl} from "../../styles/helpers";
import {ISection, ISiteMapStore} from "../reducer/site_map_reducer";
import {getSiteMapSectionData, SiteMapSection} from "../utils/siteMapUtils";
import {SiteMapLinkList} from "./SiteMapLinkList";

interface IStateProps {
    siteMap: ISection[];
    fistLevel: boolean;
}
interface IProps extends ISiteMapStore {}

const SiteMapDynamicC = (props: IProps) => {
    const breadcrumb = [
        {
            label: `Mapa strony`,
            link: appLink.siteMap.base()
        }
    ];

    if (!props.fistLevel) {
        breadcrumb.push({
            label: getSiteMapSectionData(props.siteMap[0].section as SiteMapSection).title,
            link: getSiteMapSectionData(props.siteMap[0].section as SiteMapSection).slug
        });
    }

    const Heading = props.fistLevel ? breadcrumb[0].label : breadcrumb[1].label;
    return (
        <>
            <Breadcrumbs breadcrumbs={breadcrumb} variant="simple" />
            <GridStatic css={mb_xxl} as="section">
                <h1 css={heading}>{Heading}</h1>
                <div css={siteMapBox}>
                    <SiteMapLinkList isTopLevel={props.fistLevel} items={props.siteMap} />
                </div>
            </GridStatic>

            <Footer />
        </>
    );
};
const mapStateToProps = (store: IStore): IStateProps => ({
    siteMap: store.siteMap.siteMap,
    fistLevel: store.siteMap.fistLevel
});

export const SiteMapDynamic = connect(mapStateToProps)(SiteMapDynamicC);

const heading = (theme: Theme) => css`
    font-size: 2.4rem;
    font-weight: 500;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 3.4rem;
    }
`;

const siteMapBox = (theme: Theme) => css`
    box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.3);
    border-radius: 0.8rem;
    background-color: #fff;
    padding: 1.6rem;
`;
