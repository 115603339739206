import * as React from "react";
import styled from "@emotion/styled";
import {CheckIcon} from "@web2/icons";

import {Flex} from "../../app/atoms/Flex";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {imageStyles} from "../../styles/mixins";
import {AboutUsLPHeader} from "../atoms/about_us_landing_page_styles";
import {aboutUsBullets} from "../constants/about_us_data";
import {WroteAboutUsBoxes} from "./WroteAboutUsBoxes";

import bannerImg from "../../styles/assets/background/gethome_rynekpierwotny_new.png";
import heroImg from "../../styles/assets/background/main_illustration.png";

export const AboutUsHeroSection = () => (
    <>
        <HeroWrapper>
            <Banner />

            <ContentWrapper>
                <HeroSloganContainer>
                    <HeroSlogan>gethome.pl to rewolucyjny portal nieruchomości z ofertami rynku wtórnego oraz pierwotnego</HeroSlogan>

                    <Paragraph fontSize={14}>Nasz cel to rewolucja w sposobie poszukiwania wymarzonego M!</Paragraph>

                    <BulletContainer>
                        {aboutUsBullets.map((item, idx) => (
                            <BulletItem key={idx}>
                                <Flex>
                                    <CheckIcon height={14} width={15} />
                                </Flex>
                                <div>{item.text}</div>
                            </BulletItem>
                        ))}
                    </BulletContainer>
                </HeroSloganContainer>

                <Flex alignItems="flex-end">
                    <ImageHero src={heroImg} height={100} width={100} />
                </Flex>
            </ContentWrapper>
        </HeroWrapper>

        <AboutUsWrapper>
            <ContentWrapper>
                <SectionHeader className="inner-header">Napisali o nas</SectionHeader>
                <WroteAboutUsBoxes />
            </ContentWrapper>
        </AboutUsWrapper>
    </>
);

/*
HeroSectionStyles
 */

const HeroWrapper = styled.section`
    height: auto;
    background-image: url("${heroImg}");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 50%;
    background-position-y: bottom;
`;

const Banner = styled.div`
    display: none;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: block;
        height: 80px;
        max-height: 150px;
        width: 100%;
        margin-top: 3rem;
        background-image: url("${bannerImg}");
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        background-position-y: top;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
        height: 150px;
    }
`;

const ImageHero = styled.img`
    ${imageStyles};
    width: 100%;
    height: auto;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: none;
    }
`;

const BulletContainer = styled.div`
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin-bottom: 12rem;
    }
`;

const AboutUsWrapper = styled.section`
    padding: 2rem 1rem;
    background: linear-gradient(180deg, #fbfbfb 0%, #f6f6f6 100%);

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        padding: 0;

        .inner-header {
            margin-bottom: 5rem;
        }
    }
`;

const HeroSloganContainer = styled.div`
    padding: 1rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        max-width: 670px;
        padding: 0 2rem;
    }
`;

const HeroSlogan = styled.h1`
    color: ${(props) => props.theme.colors.text_color};
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 3rem;
    margin: 0.5rem 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin-top: 10rem;
        font-size: 4rem;
        line-height: 6rem;
    }
`;
const ContentWrapper = styled.div`
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        align-items: flex-start;
    }
`;

const Paragraph = styled(ParagraphGray)`
    line-height: 2rem;
    font-weight: 500;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin: 0.5rem 0;
        font-size: 1.8rem;
        line-height: 3.5rem;
    }
`;

const BulletItem = styled.div`
    position: relative;
    font-size: 1.2rem;
    line-height: 2rem;
    color: ${(props) => props.theme.colors.text_color};
    display: flex;

    .svg-icon {
        fill: ${(props) => props.theme.colors.dark_blue};
        margin: 0.3rem 0.5rem 0 0;
        display: block;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        font-size: 1.8rem;
        line-height: 3.5rem;

        .svg-icon {
            margin: 1rem 0.5rem 0 0;
            height: 15px;
            width: 20px;
        }
    }
`;

const SectionHeader = styled(AboutUsLPHeader)`
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin-top: 3.5rem;
        margin-bottom: 3rem;
        line-height: 6rem;
    }
`;
