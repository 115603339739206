import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {GridStatic} from "../../app/atoms/Layout";
import {Paragraph} from "../../app/atoms/Paragraph";

import quoteImgBg from "../../styles/assets/background/blue_wave_bg.jpg";
import avatar from "../../styles/assets/images/pawel_moszczynski.png";
import quote from "../../styles/assets/svg/quote_icon.svg";

export const QuoteSection = () => (
    <section css={quoteWrapper}>
        <FlexGridStatic width="1230px">
            <Image src={avatar} height={137} width={137} alt="pawel-moszczynski" loading="lazy" />

            <Name fontSize={18} color="#fff">
                Paweł Moszczyński
            </Name>

            <Paragraph fontSize={14} color="#fff">
                Członek Zarządu rynekpierwotny.pl
            </Paragraph>

            <div>
                <ImageQuote src={quote} height={108} width={114} alt={"quote-icon"} color="#fff" />

                <QuoteParagraph fontSize={20} color="#fff">
                    “Misją gethome.pl jest doprowadzenie internauty do jak najszybszych transakcji. Osiągniemy to poprzez przejrzyste reguły współpracy z
                    agencjami oraz marketing i technologie, które pozwolą nam precyzyjnie docierać do potencjalnych klientów.”
                </QuoteParagraph>
            </div>
        </FlexGridStatic>
    </section>
);

/*
QuoteSection Styles
 */

const quoteWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: auto;
    background-image: url("${quoteImgBg}");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 5rem 0.5rem;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        height: 450px;
    }
`;

const FlexGridStatic = styled(GridStatic)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const QuoteParagraph = styled(Paragraph)`
    font-style: italic;
    font-weight: 500;
    line-height: 3rem;
    max-width: 790px;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-top: 3rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        margin-top: 0.5rem;
    }
`;

const Name = styled(Paragraph)`
    margin-bottom: 0;
    line-height: 1.8rem;
    font-weight: 600;
    margin-top: 1rem;
`;

interface IImageStyles {
    height: number;
    width: number;
}

const Image = styled.img<IImageStyles>`
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};
`;

const ImageQuote = styled.img<IImageStyles>`
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};
    position: absolute;
    z-index: 0;
    top: 255px;
    margin-bottom: 1rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        top: 195px;
    }
`;
