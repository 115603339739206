import styled from "@emotion/styled";

export const AboutUsLPHeader = styled.h2`
    color: ${(props) => props.theme.colors.gray_dark};
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        line-height: 44px;
        font-size: 36px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        font-size: 40px;
        line-height: 56px;
    }
`;
