import * as React from "react";
import styled from "@emotion/styled";
import {SliderGallery} from "@web2/slider_gallery";

import {IAboutUsGallery} from "../constants/about_us_data";
import {SliderArrow} from "./slider/SliderArrow";

interface IProps {
    images: IAboutUsGallery[];
    openedImage: number;
}

export const ModalGalleryContent = (props: IProps) => {
    return (
        <GalleryHolder>
            <SliderGallery
                slides={props.images.map((image) => {
                    return (
                        <picture key={image.alt} className="gallery-image">
                            <img src={image.source} alt={image.alt} />
                        </picture>
                    );
                })}
                arrows={{
                    left: <SliderArrow orientation="left" />,
                    right: <SliderArrow orientation="right" />
                }}
                clonedSlidesCount={3}
                initialSlide={props.openedImage}
            />
        </GalleryHolder>
    );
};

const GalleryHolder = styled("div")`
    height: 100%;
    overflow: hidden;
`;
