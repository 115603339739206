import React from "react";
import {connect} from "react-redux";
import {css} from "@emotion/react";
import {truncate} from "lodash";
import {Breadcrumbs} from "@web2/breadcrumbs";
import {appLink} from "@web2/gh_routes";

import {useRouteHandler} from "../../client/utils/use_route_handler";
import {GridStatic} from "../app/atoms/Layout";
import {IAuthorDetailArticle, IAuthorDetailAuthor} from "../app/interfaces/response/author_detail";
import {IStore} from "../app/reducers/hybrid_reducer";
import {RequestState} from "../app/utils/request_response_utils/factories/reduce_request_state";
import {ArticleList} from "../article/components/ArticleList";
import {ViewType} from "../tracking/view_type/view_type";
import {AuthorDetailAuthorInfo} from "./components/AuthorDetailAuthorInfo";
import {SchemaAuthorDetail} from "./schemas/SchemaAuthorDetail";

interface IStateProps {}
interface IProps extends IStateProps {
    authorDetail: {
        author: IAuthorDetailAuthor;
    };
    authorDetailRequestState: RequestState;
    authorDetailArticleList: {
        count: number;
        next: number;
        page: number;
        page_size: number;
        previous: number;
        articles: IAuthorDetailArticle[];
    };
    authorDetailArticleListRequestState: RequestState;
}

export const AuthorDetailDynamic = connect(mapStateToProps)((props: IProps) => {
    useRouteHandler();
    const {author} = props.authorDetail;
    const {articles, count, page, page_size} = props.authorDetailArticleList;

    const authorDescriptionTruncate = truncate(author.description, {
        length: 400,
        separator: ". ",
        omission: "."
    });

    const breadcrumb = [
        {
            label: `Blog`,
            link: appLink.article.base()
        },
        {
            label: `Autorzy`,
            link: appLink.article.author.base()
        },
        {
            label: author.name,
            link: appLink.article.author.detail({authorSlug: author.slug})
        }
    ];

    return (
        <>
            <Breadcrumbs breadcrumbs={breadcrumb} variant="simple" />

            <GridStatic as="article">
                <AuthorDetailAuthorInfo author={author} page={page} />

                {articles.length > 0 && (
                    <>
                        <p css={articlesHeading}>Artykuły autora:</p>

                        <ArticleList
                            articles={articles}
                            page_count={Math.ceil(count / page_size)}
                            page={page}
                            page_size={page_size}
                            requestState={props.authorDetailArticleListRequestState}
                            viewType={ViewType.BLOG_AUTHOR}
                            scrollTopAfterPageChange
                            disableAds
                        />
                    </>
                )}

                <SchemaAuthorDetail author={author} authorImage={author.image?.aut_img_160x160} description={authorDescriptionTruncate} />
            </GridStatic>
        </>
    );
});

function mapStateToProps(state: IStore): IStateProps {
    return {
        authorDetail: state.authors.authorDetail,
        authorDetailRequestState: state.authors.authorDetailRequestState,
        authorDetailArticleList: state.authors.authorDetailArticleList,
        authorDetailArticleListRequestState: state.authors.authorDetailArticleListRequestState
    };
}

const articlesHeading = css`
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 2rem;
`;
