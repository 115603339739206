import {combineReducers, Reducer} from "redux";

import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {customMetaActionTypes, CustomMetaData} from "../actions/fetch_custom_meta_data";
import {
    IMetaDataOptions,
    IUpdateMetaDataAction,
    IUpdateMetaTitleDescriptionAction,
    UPDATE_META_DATA,
    UPDATE_META_TITLE_DESCRIPTION
} from "../actions/update_meta_data";

export interface IMetaDataStore {
    metaData: IStandardMetaData;
    customMetaData?: CustomMetaData;
}
export interface IStandardMetaData extends IMetaDataOptions {
    title: string;
    description: string;
}

export const defaultMetaData = {
    description: "Oferty mieszkań i domów na sprzedaż oraz na wynajem. Bezpośrednio od deweloperów i biur nieruchomości. Bez duplikatów✅",
    robots: "index, follow",
    title: "Mieszkania i domy na sprzedaż i wynajem - znajdź na mapie",
    lang: "pl"
};

export interface IStore {
    metaData: IMetaDataStore;
}

export const standardMetaDataReducer = (
    state: IStandardMetaData = defaultMetaData,
    action: IUpdateMetaDataAction | IUpdateMetaTitleDescriptionAction
): IStandardMetaData => {
    switch (action.type) {
        case UPDATE_META_TITLE_DESCRIPTION:
            return {
                ...state,
                ...(action.title ? {title: action.title} : {}),
                ...(action.description ? {description: action.description} : {})
            };
        case UPDATE_META_DATA:
            return {
                title: action.title,
                description: action.description,
                canonical: action.canonical,
                relPrev: action.relPrev,
                relNext: action.relNext,
                robots: action.robots,
                image: action.image,
                keywords: action.keywords,
                author: action.author,
                url: action.url,
                hideSuffix: action.hideSuffix,
                lang: action.lang,
                maxImagePreview: action.maxImagePreview
            };
        default:
            return state;
    }
};

const metaDataReducerObj: Record<keyof IMetaDataStore, Reducer<any, any>> = {
    metaData: standardMetaDataReducer,
    customMetaData: reduceResponse(customMetaActionTypes)
};

export const metaDataReducer = combineReducers(metaDataReducerObj);
