import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Flex} from "../../app/atoms/Flex";
import {Paragraph} from "../../app/atoms/Paragraph";
import {square} from "../../styles/mixins";

import personIcon from "../../styles/assets/svg/person.svg";

interface ITeamDetailProps {
    name: string;
    source?: string;
    email: string;
    title: string;
    phone_number?: string;
    className?: string;
}

export const TeamDetail = (props: ITeamDetailProps) => {
    return (
        <Wrapper alignItems={"center"}>
            <div className={props.className}>
                <Image src={props.source ? props.source : personIcon} height={100} width={100} alt={props.name} isPlaceholder={!props.source} loading="lazy" />
            </div>

            <div>
                <Name fontSize={18} color="#fff">
                    {props.name}
                </Name>
                <Title fontSize={12} color="#fff">
                    {props.title}
                </Title>
                <Contact href={`mailto:${props.email}`}>{props.email}</Contact>
                {props.phone_number && <Contact href={`tel:${props.phone_number}`}>{props.phone_number}</Contact>}
            </div>
        </Wrapper>
    );
};

/*
Styles
 */

const Wrapper = styled(Flex)`
    flex-direction: column;
    margin-bottom: 3rem;
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_xs}) {
        width: 50%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 25%;
    }

    .team-anchor {
        border: 4px solid #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        ${square(100)};

        img {
            border: none;
            border-radius: 0;
            ${square(60)};
        }

        @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
            ${square(150)};

            img {
                ${square(80)};
            }

            + div p {
                line-height: 2rem;
                margin-top: 1.6rem;
            }
        }
    }
`;

interface IImageStylesProps {
    isPlaceholder: boolean;
    height: number;
    width: number;
}

const Image = styled.img<IImageStylesProps>`
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};
    border: 4px solid #fff;
    border-radius: 50%;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        ${square(150)};
    }

    ${(props) =>
        props.isPlaceholder &&
        css`
            opacity: 0.82;
            padding: 1rem;
        `}
`;

const Name = styled(Paragraph)`
    text-align: center;
    font-weight: 600;
    line-height: 2.7rem;
    margin-bottom: 0.5rem;
    max-width: 240px;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin-bottom: 0.2rem;
    }
`;

const Title = styled(Paragraph)`
    text-align: center;
    line-height: 1.8rem;
    margin: 0;
`;

const Contact = styled(Paragraph)`
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
    display: block;
    text-align: center;
    line-height: 1.8rem;
    margin: 0;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: underline;
    }
`.withComponent("a");
