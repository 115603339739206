import {join} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {IOfferBoxOffer} from "../../offer/detail/components/offer_box/OfferBox";

interface IFavouriteOfferListResponse {
    meta: {
        total_investments: number;
        total_offers: number;
    };
    data: IOfferBoxOffer[];
}

export const fetchFavouriteOfferListTypes = createRequestActionTypes({view: "FavouriteOffers", type: "GET", name: "FavouriteOffersDynamic"});
export const fetchFavouriteOfferList = () => (dispatch: Dispatch, getState: any) => {
    dispatch({type: fetchFavouriteOfferListTypes.start});
    const favouriteOffers = getState().favourites.favourites;
    const link = apiLink.offerList.base({})(null);
    const url = appendQueryString(link, {ids: join(favouriteOffers, ",")});

    return getRequest({}, url).then(async (json: IFavouriteOfferListResponse) => {
        const sortedOffers = json.data.sort((a, b) => {
            return favouriteOffers.indexOf(a.id) - favouriteOffers.indexOf(b.id);
        });

        dispatch({type: fetchFavouriteOfferListTypes.success, result: sortedOffers});
        return sortedOffers;
    });
};

export const removeFetchedFavourite = (offerId: string) => async (dispatch: Dispatch, getState: () => IStore) => {
    const oldFavourites = getState().favourites.favouriteOfferList;

    const favourites = oldFavourites.filter((fav) => {
        return fav.id !== offerId;
    });

    return dispatch({
        type: fetchFavouriteOfferListTypes.success,
        result: favourites
    });
};
