import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {FlexColumn} from "../../app/atoms/Flex";
import {GridStatic} from "../../app/atoms/Layout";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {sectionHeaderBase} from "./RegisterChecklist";

import hom from "../../styles/assets/images/partnerLogo/homfi_logo.png";
import max from "../../styles/assets/images/partnerLogo/maxon.png";
import sdp from "../../styles/assets/images/partnerLogo/sdp.jpg";
import tur from "../../styles/assets/images/partnerLogo/tur_logo.png";

export const PartnerCollaborationSection = () => (
    <section css={partnerCollaborationWrapper}>
        <GridStatic width="1230px">
            <h2 css={sectionHeaderBase}>Współpracują z nami</h2>

            <LogoContainer justifyContent="space-around" alignItems="center">
                <PartnerLogo src={hom} height={80} width={223} alt="homfi_logo" loading="lazy" />
                <PartnerLogo src={max} height={70} width={200} alt="maxon_logo" loading="lazy" />
                <PartnerLogo src={tur} height={21} width={181} alt="tur_nieruchomosci_logo" loading="lazy" />
                <PartnerLogo src={sdp} height={60} width={110} alt="sdp nieruchomości" loading="lazy" />
            </LogoContainer>

            <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg">
                Dołącz do najlepszych!
                <ArrowNextIcon mainColor="#fff" />
            </StyledAgencyButton>
        </GridStatic>
    </section>
);

const partnerCollaborationWrapper = (theme: Theme) => css`
    padding: 2rem 0.5rem;

    @media screen and (min-width: ${theme.breakpoints_correct.screen_sm}) {
        padding: 3.5rem 0.5rem 5.5rem;
    }
`;

const LogoContainer = styled(FlexColumn)`
    margin-bottom: 5rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        flex-direction: row;
    }
`;

interface IImageStyles {
    height: number;
    width: number;
}

const PartnerLogo = styled.img<IImageStyles>`
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};

    &:not(:last-of-type) {
        margin-bottom: 4rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        margin-bottom: 0 !important;
    }
`;
