import {css, Theme} from "@emotion/react";

import {ISection} from "../reducer/site_map_reducer";
import {getSiteMapSectionData, getSiteMapTitle, SiteMapSection} from "../utils/siteMapUtils";
import {SiteMapItem} from "./SiteMapItem";

interface IProps {
    items: ISection[];
    isTopLevel?: boolean;
}

export const SiteMapLinkList: React.FC<IProps> = (props) => {
    return (
        <ul css={toplinkList}>
            {props.items.map((link) => {
                const seeMoreLink = getSiteMapSectionData(link.section as SiteMapSection);

                return (
                    <SiteMapItem isTopLevel={props.isTopLevel} key={link.section} link={link}>
                        <ul css={linkList}>
                            {link.items.map((item, index) => (
                                <li key={`${item.title}_${index}`}>
                                    <a css={linkStyle} href={item.url}>
                                        {getSiteMapTitle(link.section, item.title)}
                                    </a>{" "}
                                    {item.count && <span css={counter}>({item.count})</span>}
                                </li>
                            ))}
                        </ul>
                        {props.isTopLevel && seeMoreLink.slug !== "artykuly/" && seeMoreLink.slug !== "oferty/" && (
                            <a css={linkStyle} href={seeMoreLink.slug}>
                                ZOBACZ WIĘCEJ »
                            </a>
                        )}
                    </SiteMapItem>
                );
            })}
        </ul>
    );
};

const commonListStyles = (theme: Theme) => css`
    list-style: none;
    margin: 0rem;
    display: grid;
    grid-gap: 2.4rem;
    padding: 1.6rem 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
`;

const linkList = (theme: Theme) => css`
    ${commonListStyles(theme)}

    @media screen and (min-width: ${theme.breakpoints_correct.screen_md}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const toplinkList = (theme: Theme) => css`
    ${commonListStyles(theme)}
    padding: 1.6rem 2.4rem;
`;

const linkStyle = (theme: Theme) => css`
    color: ${theme.colors.text_color};
`;
const counter = (theme: Theme) => css`
    color: ${theme.colors.gray_lighter};
`;
