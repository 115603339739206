import {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

/**
 * A custom React hook that manages the state of a modal dialog in a React application.
 * The state of the modal is tied to a specific route parameter in the URL.
 *
 * @param {string} routeParam - The route parameter that will be used to control the modal state.
 *
 * @returns {Object} An object containing the current modal state and functions to open and close the modal.
 */

export const useRoutedModalState = (routeParam: string, value?: string | number) => {
    const [modalState, setModalState] = useState(false);

    const normalizedValue = typeof value === "number" ? value.toString() : value;
    const location = useLocation();
    const history = useHistory();
    const isManuallyOpened = useRef<boolean>(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get(routeParam);

        if (paramValue === normalizedValue || paramValue === routeParam) {
            setModalState(true);
        } else {
            setModalState(false);
        }
    }, [location, routeParam, value]);

    const updateModalState = (modalParam: string, shouldOpen: boolean, value?: string) => {
        const searchParams = new URLSearchParams(location.search);

        if (shouldOpen) {
            searchParams.set(modalParam, value || modalParam);
        } else {
            searchParams.delete(modalParam);
        }

        history.push({search: searchParams.toString()});
    };

    const openModal = () => {
        isManuallyOpened.current = true;
        updateModalState(routeParam, true, normalizedValue);
    };

    const closeModal = (forceClose?: boolean) => {
        if (!forceClose && isManuallyOpened.current) {
            history.goBack();
            return;
        }

        // since we don't know user history create new stack
        updateModalState(routeParam, false, normalizedValue);
    };

    return {
        modalState,
        openModal,
        closeModal
    };
};
