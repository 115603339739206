import {css, Theme} from "@emotion/react";
import {map} from "lodash";
import {darken} from "polished";

import {getOfferListUrl} from "../../../offer/list/url_utils/get_offer_list_url";
import {IHomepageDistrict} from "../../constatns/constants";

import popularIcon from "../../../styles/assets/images/homepage/popular.svg";
import trendingIcon from "../../../styles/assets/images/homepage/trending_popular.svg";

interface IProps {
    title: string;
    isPopular?: boolean;
    districts: IHomepageDistrict[];
    mainDistrict: string;
}

export const DistrictSectionCityDistrictList = (props: IProps) => {
    const renderSingleDistrict = (district: IHomepageDistrict, idx: number) => {
        const slug = district.slug.split("/").slice(0, 2);
        const url = getOfferListUrl({location: slug[0], subLocation: slug[1], offerType: "mieszkania", type: "na-sprzedaz"});
        return (
            <li key={idx}>
                <a href={url} css={listItem} title={`Mieszkania na sprzedaż ${props.mainDistrict} ${district.name}`}>
                    {district.name}
                </a>
            </li>
        );
    };

    return (
        <section css={sectionHolder}>
            <div css={headingHolder}>
                <div style={{backgroundImage: `url(${props.isPopular ? popularIcon : trendingIcon})`}} css={img} />

                <h2 css={headline}>{props.title}</h2>
            </div>

            <ul css={list}>{map(props.districts, (district, idx) => renderSingleDistrict(district, idx))}</ul>
        </section>
    );
};

const sectionHolder = (theme: Theme) => css`
    padding: 0 1.5rem;
    overflow-x: hidden;
    margin: 0 0 3rem;
    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin: 3rem 0 0;
    }
`;

const headingHolder = (theme: Theme) => css`
    display: flex;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        padding-left: 5rem;
        white-space: nowrap;
    }
`;

const headline = (theme: Theme) => css`
    color: ${theme.colors.gray_dark};
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1rem;

    @media screen and (min-width: 660px) {
        font-size: 2.2rem;
        margin: 0;
    }
`;

const img = css`
    width: 26px;
    height: 26px;
    background: center center no-repeat;
    background-size: contain;
    margin-right: 1rem;
`;

const list = (theme: Theme) => css`
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    padding: 0;
    list-style: none;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        padding-left: 4rem;
    }
    @media (min-width: ${theme.breakpoints_correct.screen_xl}) {
        width: 80rem;
    }
    @media (min-width: ${theme.breakpoints_correct.screen_xxl}) {
        width: 95rem;
    }
`;

const listItem = (theme: Theme) => css`
    display: block;
    padding: 0 1.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
    text-align: center;
    height: 30px;
    min-width: 116px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    background-color: ${theme.colors.brand_primary};
    color: #fff;
    margin: 1rem 1.5rem 1rem 0;
    border-radius: 1.5rem;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
        background-color: ${darken(0.05, theme.colors.brand_primary.toString())};
    }

    @media screen and (max-width: ${theme.breakpoints.screen_sm}) {
        margin: 0.5rem 0.5rem;
        font-size: 1.1rem;
        min-width: 80px;
    }
`;
