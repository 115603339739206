import * as React from "react";
import {css, keyframes} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

import market from "../../styles/assets/images/first_second_market.png";
import flatRealStreet from "../../styles/assets/images/flat_real_street.png";
import house from "../../styles/assets/images/house_real_street.png";
import commercial from "../../styles/assets/images/no_commercial.png";
import duplicates from "../../styles/assets/images/no_duplicates.png";

const ImageWrapper = styled.div`
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: 580px;
        margin: 0 auto;
    }
`;

const opacityAnimationStyle = () => css`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const animationOne = keyframes`
  ${opacityAnimationStyle()};
`;

const animationTwo = keyframes`
  ${opacityAnimationStyle()};
`;

const animationThree = keyframes`
  ${opacityAnimationStyle()};
`;

const animationFour = keyframes`
  ${opacityAnimationStyle()};
`;

const animationFive = keyframes`
  ${opacityAnimationStyle()};
`;

const ImageBackground = styled.div`
    display: none;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: block;

        &.duplicates {
            animation: ${animationOne} 0.7s forwards;
            background: url(${duplicates}) no-repeat center;
            background-size: cover;
            width: 525px;
            height: 438px;
            margin-left: 3rem;
            margin-top: 5rem;
        }

        &.market {
            animation: ${animationTwo} 0.7s ease-in-out;
            background: url(${market}) no-repeat center;
            background-size: cover;
            width: 525px;
            height: 420px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            margin-left: 3rem;
            margin-top: 4rem;
        }

        &.commercial {
            animation: ${animationThree} 0.7s ease-in-out;
            background: url(${commercial}) no-repeat center;
            background-size: cover;
            width: 525px;
            height: 338px;
            margin-left: 3rem;
            margin-top: 8rem;
        }

        &.flatRealStreet {
            animation: ${animationFour} 0.7s ease-in-out;
            background: url(${flatRealStreet}) no-repeat center;
            background-size: cover;
            width: 545px;
            height: 458px;
        }

        &.houseRealStreet {
            animation: ${animationFive} 0.7s ease-in-out;
            background: url(${house}) no-repeat center;
            background-size: cover;
            width: 545px;
            height: 458px;
        }
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
        &.duplicates {
            width: 684px;
            height: 571px;
            margin-left: 5rem;
            margin-top: 0;
        }

        &.market {
            width: 584px;
            height: 467px;
            margin-left: 5rem;
            margin-top: 0;
        }

        &.commercial {
            width: 627px;
            height: 404px;
            margin-left: 5rem;
            margin-top: 0;
        }

        &.flatRealStreet {
            width: 664px;
            height: 558px;
        }

        &.houseRealStreet {
            width: 664px;
            height: 558px;
        }
    }
`;

interface Props {
    className: string;
}

export const QualityViewPort = (props: Props) => {
    return (
        <ImageWrapper>
            <ImageBackground className={classNames(props.className)} />
        </ImageWrapper>
    );
};
