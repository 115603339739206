import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {find} from "lodash";
import {Breadcrumbs} from "@web2/breadcrumbs";
import {appLink} from "@web2/gh_routes";

import {useRouteHandler} from "../../client/utils/use_route_handler";
import {AdTargetingPageName, getAdTargetingString} from "../advertisement/get_ad_targeting";
import {GridStatic} from "../app/atoms/Layout";
import {IArticleCategory} from "../app/interfaces/response/article_category_list";
import {IArticleListArticle} from "../app/interfaces/response/article_list";
import {IStore} from "../app/reducers/hybrid_reducer";
import {RequestState} from "../app/utils/request_response_utils/factories/reduce_request_state";
import {Footer} from "../footer/Footer";
import {ViewType} from "../tracking/view_type/view_type";
import {ArticleBoxWide} from "./components/ArticleBoxWide";
import {ArticleBoxWideLoader} from "./components/ArticleBoxWideLoader";
import {ArticleCategories} from "./components/ArticleCategories";
import {ArticleDistrictList} from "./components/ArticleDistrictList";
import {ArticleDistricts} from "./components/ArticleDistricts";
import {ArticleList} from "./components/ArticleList";
import {ArticleListDesktopPlacement} from "./components/ArticleListDesktopPlacement";
import {ArticleSectionHeader} from "./components/ArticleSectionHeader";
import {blogHeadHolder} from "./components/atoms";
import {cityBlogCategoriesSelector, mainBlogCategoriesSelector} from "./utils/category_selectors";

interface IStateProps {
    articleList: {
        count: number;
        page: number;
        page_size: number;
        articles: IArticleListArticle[];
    };
    mainCategories: IArticleCategory[];
    cityCategories: IArticleCategory[];
    districtArticleList: IArticleListArticle[];
    categoryListRequestState: RequestState;
    articleListRequestState: RequestState;
}

interface IProps extends IStateProps {}

export const ArticleListDynamic = connect(mapStateToProps)((props: IProps) => {
    useRouteHandler();

    const [cityCategory, setCityCategory] = useState<string>(props.cityCategories[0].uuid);
    const adTargeting = getAdTargetingString({pageNames: AdTargetingPageName.BLOG});

    const breadcrumb = {
        label: `Blog`,
        link: appLink.article.base()
    };

    const [mainArticle, ...articles] = props.articleList.articles;

    const activeCityCategory = find(props.cityCategories, (cat) => cat.uuid === cityCategory);

    return (
        <>
            <Breadcrumbs breadcrumbs={[breadcrumb]} variant="simple" />

            <ArticleListDesktopPlacement adTargeting={adTargeting} />

            <GridStatic as="section">
                <div css={blogHeadHolder}>
                    <ArticleCategories categories={props.mainCategories} />
                </div>

                {props.articleListRequestState === RequestState.Success ? <ArticleBoxWide mainArticle={mainArticle} /> : <ArticleBoxWideLoader />}

                <ArticleList
                    articles={articles}
                    page_count={Math.ceil(props.articleList.count / props.articleList.page_size)}
                    page={props.articleList.page}
                    page_size={props.articleList.page_size}
                    requestState={props.articleListRequestState}
                    viewType={ViewType.BLOG}
                    scrollTopAfterPageChange
                    adTargeting={adTargeting}
                />

                <ArticleSectionHeader title={"Dzielnice Miast"} />

                <ArticleDistricts
                    cityCategories={props.cityCategories}
                    setCityCategory={setCityCategory}
                    activeCategory={cityCategory}
                    activeCategoryName={activeCityCategory ? activeCityCategory.name : ""}
                />
                <ArticleDistrictList articles={props.districtArticleList} activeCategory={cityCategory} />
            </GridStatic>

            <Footer />
        </>
    );
});

/**
 * Connect
 */
function mapStateToProps(state: IStore): IStateProps {
    return {
        articleList: state.articles.articleList,
        mainCategories: mainBlogCategoriesSelector(state),
        cityCategories: cityBlogCategoriesSelector(state),
        districtArticleList: state.articles.districtArticleList,
        categoryListRequestState: state.articles.categoryListRequestState,
        articleListRequestState: state.articles.articleListRequestState
    };
}
