import React from "react";
import {css} from "@emotion/react";
import {useUserDevice} from "@web2/user-device";

import {imgResponsive, tac, w100} from "../../styles/helpers";
import {ghLogo} from "./AgencyRegisterDynamic";

import imageMobile from "../../styles/assets/background/agency_register_confirmation_mobile_opt.svg";
import imageDesktop from "../../styles/assets/background/agency_register_confirmation_opt.svg";
import logo from "../../styles/assets/logo/gethome.svg";

export const AgencyRegisterConfirmation = () => {
    const {isMobile} = useUserDevice();

    return isMobile ? (
        <>
            <header css={agencyRegisterConfirmation}>
                <h1 css={tac}>Dziękujemy!</h1>

                <h2 css={subeHeading}>Nasz Opiekun Klienta skontaktuje się z Tobą telefonicznie najszybciej jak to możliwe.</h2>
            </header>

            <img css={[imgResponsive, w100]} src={imageMobile} alt="Dziękujemy za wysłanie zgłoszenia" />
        </>
    ) : (
        <>
            <header css={agencyRegisterConfirmation}>
                <img src={logo} alt="GetHome logo" width={227} css={ghLogo} />

                <h1 css={desktopHeading}>Dziękujemy! Nasz Opiekun Klienta skontaktuje się z Tobą telefonicznie najszybciej jak to możliwe.</h1>
            </header>

            <img css={imgResponsive} src={imageDesktop} alt="Dziękujemy za wysłanie zgłoszenia" />
        </>
    );
};

const subeHeading = css`
    text-align: center;
    margin: 2rem auto 4rem;
    font-size: 1.6rem;
`;

const desktopHeading = css`
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 5rem;
`;

const agencyRegisterConfirmation = css`
    padding: 0 1rem;
`;
