// slug list used in navigation and to handle some exceptions
export enum ArticleCategorySlug {
    CONTRACT_TEMPLATES = "wzory-umow",
    NEWS = "branzowe-newsy",
    FOR_PROFESSIONALS = "dla-profesjonalistow",
    EXPERT_ANALYSIS = "analizy-eksperta",
    ARRANGEMENTS = "jak-urzadzic",
    ARCHITECTURE = "architektura",
    KNOWLEDGE_BASE = "strefa-wiedzy",
    DISTRICTS_DESCRIPTION = "opisy-dzielnic",
    FINANCE_LAW = "finanse-prawo",
    LOCATIONS = "gdzie-zamieszkac",
    LIFESTYLE = "lifestyle"
}

/**
 * we only want to handle exceptions here. Standard category names should be fetched from category list endpoint
 * @param slug
 */
export const getCategoryNameExceptionsBySlug = (slug: string) => {
    switch (slug) {
        case ArticleCategorySlug.DISTRICTS_DESCRIPTION:
            return "opisy dzielnic";
        case ArticleCategorySlug.CONTRACT_TEMPLATES:
            return "wzory umów do pobrania";
        default:
            return null;
    }
};
