import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const PhoneIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M8.82,5.81a4.87,4.87,0,0,1-1.18-.3,1.37,1.37,0,0,0-1.45.32l-.33.33A6.64,6.64,0,0,1,3.76,4l.33-.32a1.37,1.37,0,0,0,.3-1.44A5.32,5.32,0,0,1,4.1,1.12,1.31,1.31,0,0,0,2.74,0S.76-.06.33.37a1.28,1.28,0,0,0-.32,1,9.53,9.53,0,0,0,1.47,4.2A9.52,9.52,0,0,0,4.36,8.48,9.62,9.62,0,0,0,8.51,10l.11,0h0A1.41,1.41,0,0,0,10,8.58V7.2A1.38,1.38,0,0,0,8.82,5.81ZM9.1,8.58a.48.48,0,0,1-.5.47A8.45,8.45,0,0,1,4.85,7.7,8.3,8.3,0,0,1,2.24,5.07,8.72,8.72,0,0,1,.9,1.28.38.38,0,0,1,1,1,.52.52,0,0,1,1.18.87c.24,0,1.56,0,1.56,0s.42,0,.47.33A5.53,5.53,0,0,0,3.55,2.6a.43.43,0,0,1-.1.47l-.58.58a.45.45,0,0,0-.08.55A7.82,7.82,0,0,0,5.71,7.13a.45.45,0,0,0,.54-.07l.58-.59a.47.47,0,0,1,.49-.11,6,6,0,0,0,1.38.35.48.48,0,0,1,.4.49Z" />
        </SvgIcon>
    );
};
