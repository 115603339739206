import * as htmlparser from "htmlparser2";

import {getPhraseRegExp} from "./get_phrase_regexp";

enum TagStackAction {
    NONE,
    OPEN,
    CLOSE
}

// disallow placing links inside some tags
const TAG_BLACKLIST = ["a", "h1", "h2", "h3", "h4", "h5", "h6"];

/**
 * Function iterates over phrase relations and runs article body parser for each of them
 *
 * @param phraseRelations
 * @param text
 * @param currentPath
 */

export const bindRelatedPhrasesToText = (phraseRelations: {phrase: string; destination_url: string}[], text: string, currentPath: string): string => {
    let result = text;

    const urlsUsed: string[] = []; // track what links were used - useful if we receive two phrases with the same url

    const onPhraseHit = (url: string) => {
        urlsUsed.push(url);
    };

    phraseRelations.every((relation) => {
        const phraseRegex = getPhraseRegExp(relation.phrase);

        const isUrlAlreadyUsed = urlsUsed.some((usedUrl) => {
            return usedUrl === relation.destination_url;
        });
        const destinationPathName = new URL(relation.destination_url).pathname;
        const urlIsCurrentPath = destinationPathName === currentPath;
        // don't place link if url was used before or leads to current url
        if (isUrlAlreadyUsed || urlIsCurrentPath) {
            return true;
        }

        // RegExp creation failed
        if (phraseRegex === null) {
            return false;
        }

        // match phrases with text and add url
        result = renderLinkInText(result, phraseRegex, relation.destination_url, onPhraseHit);

        return true;
    });

    return result;
};

/**
 * Function parses HTML to replace "phrase" with links in HTML
 *
 * @param htmlBody - HTML to parse
 * @param phraseRegExp - RegExp pattern
 * @param url - link to use
 * @param onPhraseHit - callback triggered when phrase is found and link is placed
 */

function renderLinkInText(htmlBody: string, phraseRegExp: RegExp, url: string, onPhraseHit: (url: string) => void): string {
    let articleText = htmlBody;
    let currentParagraph = 0;
    let previousTextEndIndex = 0;
    const tagStack: string[] = []; // track the current HTML node path

    let lastTagStackAction: TagStackAction = TagStackAction.NONE;

    const parser = new htmlparser.Parser(
        {
            onopentag: (name: string) => {
                tagStack.push(name);
                if (name === "p") {
                    currentParagraph++;
                }
                lastTagStackAction = TagStackAction.OPEN;
            },
            ontext: (text: string) => {
                // do not place links in listed tags
                if (tagStack.some((tag) => TAG_BLACKLIST.some((blacklistedTag) => tag === blacklistedTag))) {
                    return;
                }

                let begin = articleText.slice(previousTextEndIndex).indexOf(text);
                if (begin !== -1) {
                    begin = begin + previousTextEndIndex;
                }
                const end = begin === -1 ? -1 : Math.min(begin + text.length, articleText.length);
                if (begin === -1 || end === -1) {
                    return;
                }

                /*
                 * We need to track when previous text fragment (argument passed to the `ontext` method) ends.
                 * This value will be the start point for article text parsing. This action will prevent a situation,
                 * when `text` will be found in article text, which was already parsed. This may be a common situation,
                 * especially when `text` will be a single word.
                 */
                previousTextEndIndex = end;

                const matched = text.match(phraseRegExp);
                const matchBegin = matched?.index && matched.index;
                const matchPhrase = matched && matched[1]; // second element of regex match is the result of actual phrase capture
                if (matchBegin == null || matchPhrase == null) {
                    return;
                }

                // update articleText
                const beginPhrase = matchBegin + begin;
                if (beginPhrase >= 0 && beginPhrase < end) {
                    const endPhrase = beginPhrase + matchPhrase.length;
                    const articleTextBeforePhrase = articleText.slice(0, beginPhrase);
                    const articleTextAfterPhrase = articleText.slice(endPhrase);
                    articleText = articleTextBeforePhrase + `<a href="${url}" target="_blank">${matchPhrase}</a>` + articleTextAfterPhrase;

                    onPhraseHit(url);

                    // break parsing
                    parser.parseComplete("");
                }
            },
            onclosetag: () => {
                tagStack.pop();
                lastTagStackAction = TagStackAction.CLOSE;
            }
        },
        {decodeEntities: false, lowerCaseTags: true, recognizeSelfClosing: true}
    );

    parser.write(articleText);
    return articleText;
}
