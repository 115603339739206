import styled from "@emotion/styled";
import {darken} from "polished";
import {Button} from "@web2/button";

export const GreenDarkButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.green_darker};
    border-color: ${(props) => props.theme.colors.green_darker};

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        background-color: ${(props) => darken(0.1, props.theme.colors.green_darker)};
        border-color: ${(props) => darken(0.1, props.theme.colors.green_darker)};
    }
`;

export const StyledBuylandoButton = styled(GreenDarkButton)`
    max-width: 360px;
    display: flex;
`;
