import {css, Theme} from "@emotion/react";

export const sectionHeadline = (theme: Theme) => css`
    color: ${theme.colors.gray_dark};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.53;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.screen_lg}) {
        font-size: 3rem;
    }
`;

export const sectionWrapper = (theme: Theme) => css`
    margin: 3rem auto;
    padding: 0 1.5rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin: 5rem auto 3rem;
    }
`;
