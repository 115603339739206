import {hitGoogleTagManager} from "./gtm_init";

export const gtmSelectHomepageCitiesTab = (label: string) => {
    const gtmData = {
        event: "GAEvent",
        GAEventCategory: "navigation",
        GAEventAction: "selected_offers",
        GAEventLabel: label
    };
    hitGoogleTagManager(gtmData);
};
