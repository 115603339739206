import {Dispatch} from "redux";

import {apiLink} from "../routes/api_link";
import {IServices} from "../services/IServices";
import {appendQueryString} from "../utils/append_query_string";
import {getRequest} from "../utils/request_response_utils/request";
import {catch404} from "../utils/request_response_utils/response_error";
import {enable301ResponseState, enable404ResponseState} from "../utils/request_response_utils/response_state/response_state_actions";
import {update404NotFoundMetaData} from "./update_404_not_found_meta_data";

// used by server and `redirectOrEnable404ResponseState`
export const fetch404Redirect = (appUrl: string, services: Partial<IServices>): Promise<string | null> => {
    const url = appendQueryString(apiLink.redirect.redirects({})(null), {old_path: appUrl});
    return getRequest(services, url)
        .then((json: {new_path: string}) => {
            return json.new_path;
        })
        .catch(
            catch404((error) => {
                return null;
            })
        );
};

export const redirectOrEnable404ResponseState =
    (services: Partial<IServices>, pathname: string) =>
    async (dispatch: Dispatch): Promise<any> => {
        dispatch(update404NotFoundMetaData(pathname));
        const newPath = await fetch404Redirect(pathname, services);
        if (newPath) {
            dispatch(enable301ResponseState(newPath));
        } else {
            dispatch(enable404ResponseState());
        }
        return Promise.resolve(newPath);
    };
