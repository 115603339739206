import {css} from "@emotion/react";

export const centerAlign = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const horizontalAlign = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const verticalAlign = css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

export const ellipsis = css`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const multilineEllipsis = (fontSize: number, lineHeight: number, linesToShow: number) => css`
    display: -webkit-box;
    max-height: ${fontSize * lineHeight * linesToShow}px;
    font-size: ${fontSize}px;
    -webkit-line-clamp: ${linesToShow};
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: ${lineHeight};
    text-overflow: ellipsis;
`;

export const square = (width: number, height: number = width) => css`
    width: ${width}px;
    height: ${height}px;
`;

export const btn = css`
    text-decoration: none;
    outline: 0;

    -webkit-appearance: none;
    -moz-appearance: none;
`;

export const mapBtn = css`
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
`;

export const cover = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const bgImgProps = (props: {bgImg?: string}) => css`
    background-image: url("${props.bgImg}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const cubicTransition = "cubic-bezier(.25, .8, .25, 1)";

export const cubicTransitionStyle = (speed = 700) => `transition: transform ${speed}ms ${cubicTransition};`;

interface IImageStylesProps {
    theme?: {
        breakpoints: {
            screen_md: string;
            screen_sm: string;
        };
    };
    height: number;
    width: number;
}

export const imageStyles = (props: IImageStylesProps) => css`
    height: ${`${props.height}px`};
    width: ${`${props.width}px`};
`;
