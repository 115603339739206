import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {darken} from "polished";

interface IProps {
    height?: number;
    width?: number;
    viewBox?: string;
    mainColor?: string;
    accentColor?: string;
    className?: string;
    strokeWidth?: number;
    strokeColor?: string;
}

export const HeartIcon = (props: IProps) => {
    return (
        <svg
            className={classNames(props.className, "svg-icon")}
            width={props.width || "20px"}
            height={props.height || "17px"}
            viewBox={props.viewBox || "0 0 20 17"}
        >
            <Polygon
                className="fav-polygon"
                color={props.mainColor || "#e53935"}
                stroke={props.strokeColor || "#fff"}
                strokeWidth={props.strokeWidth || 1.6}
                d="M9.7,3.6C8.9,1.7,7.1,0.5,5.1,0.5c-2.5,0-4.6,1.9-4.6,4.4c0,0.1,0,0.1,0,0.2c0,3.6,3.9,6.4,9.2,11.8 C15.1,11.5,19,8.7,19,5.1c0-2.5-1.9-4.6-4.4-4.6c-0.1,0-0.1,0-0.2,0C12.3,0.5,10.5,1.7,9.7,3.6z"
            />
        </svg>
    );
};

const PolygonStyles = (props: {color: string}) => css`
    fill: ${props.color};
    &:hover {
        fill: ${darken(0.1, props.color)};
    }
`;

const Polygon = styled("path")(PolygonStyles);
