import * as React from "react";
import {css, Theme} from "@emotion/react";

import buylandoHeroImg from "../assets/buylando_hero.jpg";

export const HeroSection = () => {
    return (
        <section css={heroWrapper}>
            <div css={heroClaim}>
                <h1 css={heroTitle}>Działki na sprzedaż - Sprzedaj grunt z gethome.pl i buylando</h1>

                <p css={heroSubTitle}>
                    Chcesz sprzedać działkę, jednak zastanawiasz się, czy to dobry moment na taką transakcję? Nie wiesz, jak wycenić grunty i jaką dokumentację
                    przygotować?
                </p>
            </div>
        </section>
    );
};

const heroWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 485px;
    padding: 5rem 1rem 2rem;
    background-image: url("${buylandoHeroImg}");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (min-width: ${theme.breakpoints_correct.screen_sm}) {
        padding: 3rem 2rem 2rem;
        height: 430px;
    }
    @media (min-width: ${theme.breakpoints_correct.screen_xl}) {
        padding: 11rem 2rem 2rem;
        height: 600px;
    }
`;

export const heroClaim = (theme: Theme) => css`
    margin: 0 auto;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        width: 92%;
    }
    @media (min-width: ${theme.breakpoints_correct.screen_lg}) {
        width: 80%;
    }
`;
export const heroTitle = (theme: Theme) => css`
    margin: 0 auto;
    color: white;
    text-align: center;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 1.5;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        font-size: 4rem;
    }
    @media (min-width: ${theme.breakpoints_correct.screen_xl}) {
        font-size: 6rem;
    }
`;

const heroSubTitle = (theme: Theme) => css`
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    margin: 0.8rem auto 3rem;
    width: 80%;

    @media (min-width: ${theme.breakpoints_correct.screen_sm}) {
        font-size: 2rem;
        margin-top: 1rem;
        margin-bottom: 2.8rem;
    }

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        font-size: 2.2rem;
    }
`;
