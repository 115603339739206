import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {mb_xl} from "../../styles/helpers";
import {ColumnTitle} from "./ColumnTitle";
import {WhatCanISellList} from "./WhatCanISellList";

import buylandoLogo from "../assets/buylando_logo.png";
import form from "../assets/form.svg";
import lokalizacja from "../assets/lokalizacja.svg";
import ogloszenie from "../assets/ogloszenie.svg";
import poland from "../assets/poland.svg";
export const WhatCanISell = () => {
    return (
        <div css={sectionBackground}>
            <SectionAsContainer>
                <Container justifyContent="start" css={mb_xl}>
                    <SellColumn alignItems="start" justifyContent="start">
                        <ColumnTitle title="Jaką działkę możesz" coloredTitle=" sprzedać?" />
                        <WhatCanISellList />
                    </SellColumn>
                    <BuylandoColumn alignItems="start" justifyContent="start">
                        <img src={buylandoLogo} css={buylandoImage} />
                        <Container justifyContent="center">
                            <BuylandoBox>
                                <Image width={93} height={93} src={poland} alt={"Oferty działek z całej Polski"} />
                                <p css={pStyle}>Oferty działek z całej Polski</p>
                            </BuylandoBox>
                            <BuylandoBox>
                                <Image width={93} height={93} src={form} alt={"Intuicyjny formularz"} />
                                <p css={pStyle}>Intuicyjny formularz</p>
                            </BuylandoBox>
                            <BuylandoBox>
                                <Image width={93} height={93} src={lokalizacja} alt={"Lokalizacja oferty na mapie"} />
                                <p css={pStyle}>Lokalizacja oferty na mapie</p>
                            </BuylandoBox>
                            <BuylandoBox>
                                <Image width={93} height={93} src={ogloszenie} alt={"Profesjonalne ogłoszenie"} />
                                <p css={pStyle}>Profesjonalne ogłoszenie</p>
                            </BuylandoBox>
                        </Container>
                    </BuylandoColumn>
                </Container>
            </SectionAsContainer>
        </div>
    );
};

const sectionBackground = css`
    background: #fbfbfb;
    padding-top: 14.5rem;
    margin-top: -14.5rem;
`;
const Container = styled(Flex)`
    padding: 0;
    flex-wrap: wrap;
    justify-content: ${(props) => props.justifyContent};

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        padding: 0 2rem;
    }
`;

const SectionAsContainer = styled("section")`
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        padding-top: 7rem;
    }
`;
const SellColumn = styled(FlexColumn)`
    width: 90%;
    margin: 2rem auto;
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        width: 40%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        width: 50%;
    }
`;
const BuylandoColumn = styled(FlexColumn)`
    width: 90%;
    margin: 2rem auto;
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        width: 60%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        width: 50%;
    }
`;

const BuylandoBox = styled(FlexColumn)`
    border-radius: 4px;
    box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 1rem 0;
    flex-basis: 100%;
    background: #fff;
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        flex-basis: 45%;
        margin: 10px;
    }
`;

const pStyle = css`
    width: 60%;
    margin: 1rem auto 0;
    text-align: center;
`;

const buylandoImage = css`
    margin: 0 auto 6.2rem;
`;

interface IImageStylesProps {
    theme?: Theme;
    height: number;
    width: number;
}

const imageStyles = (props: IImageStylesProps) => css`
    margin: 0 auto;
`;

const Image = styled("img")(imageStyles);
