import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

interface IProps {
    title: string;
    coloredTitle?: string;
}
export const ColumnTitle = (props: IProps) => {
    return (
        <>
            <h2 css={columnTitleStyle}>
                {props.title}
                <span css={coloredTitle}>{props.coloredTitle}</span>
            </h2>
        </>
    );
};
const columnTitleStyle = css`
    font-size: 2.8rem;
    margin-bottom: 1rem;
`;
const coloredTitle = css`
    color: #9069c0;
    display: block;
`;
