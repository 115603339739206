import React, {useState} from "react";

import {AgencyRegisterConfirmation} from "./AgencyRegisterConfirmation";
import {AgencyRegisterGridStatic, agencyRegisterHeadline, AgencyRegisterHolder, ghLogo} from "./AgencyRegisterDynamic";
import {AgencyRegisterForm} from "./AgencyRegisterForm";

import logo from "../../styles/assets/logo/gethome.svg";

export const AgencyRegisterPremiumDynamic = () => {
    const [isSent, setIsSent] = useState(false);

    return (
        <AgencyRegisterHolder isSent={isSent}>
            <AgencyRegisterGridStatic width="700px" isSent={isSent}>
                {isSent ? (
                    <AgencyRegisterConfirmation />
                ) : (
                    <>
                        <img src={logo} alt="GetHome logo" width={227} css={ghLogo} />

                        <h1 css={agencyRegisterHeadline}>Otrzymaj indywidualną&nbsp;ofertę GetHome Premium!</h1>

                        <AgencyRegisterForm setIsSent={setIsSent} isPremium />
                    </>
                )}
            </AgencyRegisterGridStatic>
        </AgencyRegisterHolder>
    );
};
