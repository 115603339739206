import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {darken} from "polished";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {Flex} from "../../app/atoms/Flex";
import {ParagraphLight} from "../../app/atoms/Paragraph";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {QualitySectionBoxType} from "./QualitySection";

export interface IQualityList {
    type: number;
    header: string;
    icon: string;
    text: string;
}

interface Props {
    list: IQualityList[];
    setActiveBoxImage: (activeBox: number) => void;
    className?: string;
    active: QualitySectionBoxType;
    showRegistrationButton?: boolean;
}

export const QualityList = (props: Props) => {
    return (
        <div className={props.className}>
            {props.list.map((item, idx) => {
                return (
                    <QualityListItem
                        key={idx}
                        onClick={() => props.setActiveBoxImage(item.type)}
                        className={classNames(item.type === props.active && "active")}
                    >
                        <ItemHeader alignItems="center" className="list-element">
                            <Icon src={item.icon} alt="icon" />
                            {item.header}
                        </ItemHeader>
                        <Paragraph className="list-element">{item.text}</Paragraph>
                    </QualityListItem>
                );
            })}

            {props.showRegistrationButton && (
                <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg" css={styledAgencyButton}>
                    Zarejestruj biuro nieruchomości
                    <ArrowNextIcon mainColor="#fff" />
                </StyledAgencyButton>
            )}
        </div>
    );
};

/*
QualitySection Styles
 */

const ItemHeader = styled(Flex)`
    color: ${(props) => props.theme.colors.brand_primary};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin: 0;
`;

const Paragraph = styled(ParagraphLight)`
    font-size: 14px;
    line-height: 21px;
`;

const QualityListItem = styled.div`
    max-width: 100%;
    border-radius: 1rem;
    background-color: #f7f7f7;
    padding: 2rem;
    cursor: pointer;
    margin-bottom: 2.5rem;
    width: 100%;
    transition:
        background-color 100ms ease-out,
        opacity 100ms ease-out;

    &.active {
        background-color: ${(props) => props.theme.colors.brand_primary};
        color: #fff;

        .list-element {
            color: #fff;
        }

        &:hover {
            background-color: ${(props) => darken(0.1, props.theme.colors.brand_primary)};
        }
    }

    &.active {
        color: #fff;
    }

    &:hover {
        background-color: ${darken(0.1, "#f7f7f7")};
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 425px;
        margin-bottom: 1.5rem;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 483px;
    }
`;

const Icon = styled.img`
    height: 30px;
    width: 30px;
    margin-right: 1.5rem;
`;

const styledAgencyButton = css`
    max-width: unset;
`;
