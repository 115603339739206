import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const BuildingsIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M9.59,4.05H6.94V.57a.4.4,0,0,0-.41-.4H.41A.4.4,0,0,0,0,.57V9.43a.4.4,0,0,0,.41.4H8.19A1.82,1.82,0,0,0,10,8V4.46A.41.41,0,0,0,9.59,4.05ZM6.13,9H.81V1H6.13ZM9.19,8a1,1,0,0,1-1,1H6.94V4.86H9.19ZM2.55,4A.4.4,0,0,0,3,3.55V2.44a.4.4,0,0,0-.4-.4.41.41,0,0,0-.41.4V3.55A.41.41,0,0,0,2.55,4ZM4.39,4a.4.4,0,0,0,.4-.41V2.44a.41.41,0,0,0-.81,0V3.55A.41.41,0,0,0,4.39,4ZM7.53,6.65h1.1a.4.4,0,0,0,.4-.4.41.41,0,0,0-.4-.41H7.53a.41.41,0,0,0-.41.41A.4.4,0,0,0,7.53,6.65Zm0,1.49h1.1A.41.41,0,0,0,9,7.73a.4.4,0,0,0-.4-.4H7.53a.4.4,0,0,0-.41.4A.41.41,0,0,0,7.53,8.14Zm-5-2a.4.4,0,0,0,.4-.4V4.67a.4.4,0,0,0-.4-.41.41.41,0,0,0-.41.41V5.78A.41.41,0,0,0,2.55,6.18Zm1.84,0a.4.4,0,0,0,.4-.4V4.67a.41.41,0,1,0-.81,0V5.78A.4.4,0,0,0,4.39,6.18ZM2.55,8.52A.4.4,0,0,0,3,8.11V7a.4.4,0,0,0-.4-.4.41.41,0,0,0-.41.4V8.11A.41.41,0,0,0,2.55,8.52Zm1.84,0a.4.4,0,0,0,.4-.41V7A.41.41,0,0,0,4,7V8.11A.41.41,0,0,0,4.39,8.52Z" />
        </SvgIcon>
    );
};
