import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {GridStatic} from "../../app/atoms/Layout";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {sectionHeaderBase} from "./RegisterChecklist";

import bigData from "../../styles/assets/images/big_data_icon.svg";
import effects from "../../styles/assets/images/effects_icon.svg";
import search from "../../styles/assets/images/search_loupe_icon.svg";
import team from "../../styles/assets/images/team_icon.svg";

export const MarketSection = () => (
    <section css={marketWrapper}>
        <GridStatic width="1230px">
            <h2 css={sectionHeader}>gethome.pl to nowe spojrzenie na rynek</h2>

            <Container justifyContent="center" style={{marginBottom: "2rem"}}>
                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={157} width={145} src={team} alt="team" loading="lazy" />

                    <Paragraph>
                        Projekt realizuje <span>100-osobowy </span>
                        zespół portalu rynekpierwotny.pl
                    </Paragraph>
                </FlexColumn>

                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={147} width={156} src={bigData} alt="big-data" loading="lazy" />

                    <Paragraph>
                        Korzystamy z <span>Big Data </span>
                        aby dostarczyć jakościowe leady
                    </Paragraph>
                </FlexColumn>

                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={159} width={156} src={effects} alt="effects" loading="lazy" />

                    <Paragraph>
                        Jasny <span>sposób rozliczenia</span> - płacisz <span>za efekt</span>
                    </Paragraph>
                </FlexColumn>

                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={150} width={164} src={search} alt="search" loading="lazy" />

                    <Paragraph>
                        Mamy <span>600 000 osób</span> szukających mieszkań i domów
                    </Paragraph>
                </FlexColumn>
            </Container>

            <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg" css={styledAgencyButton}>
                Zarejestruj biuro nieruchomości
                <ArrowNextIcon mainColor="#fff" />
            </StyledAgencyButton>
        </GridStatic>
    </section>
);

/*
MarketSection Styles
 */

const marketWrapper = (theme: Theme) => css`
    padding: 3.5rem 0.5rem;
    background: linear-gradient(180deg, #fbfbfb 0%, #f6f6f6 100%);

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        padding: 5rem 0.5rem;
    }
`;

const Container = styled(Flex)`
    flex-wrap: wrap;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        max-width: 700px;
        margin: 0 auto;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
        justify-content: space-between;
        max-width: 100%;
    }
`;

const sectionHeader = (theme: Theme) => css`
    ${sectionHeaderBase(theme)};

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin-bottom: 8.5rem;
    }
`;

const Paragraph = styled(ParagraphGray)`
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.7rem;
    max-width: 278px;
    margin-bottom: 2rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        height: 75px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
        margin-bottom: 0.5rem;
    }

    span {
        font-weight: bold;
        color: ${(props) => props.theme.colors.brand_primary};
    }
`;

interface IImageStylesProps {
    height: number;
    width: number;
}

const Image = styled.img<IImageStylesProps>`
    height: 120px;
    width: 100px;

    @media screen and (min-width: ${(props) => props.theme?.breakpoints.screen_md}) {
        height: ${(props) => `${props.height}px`};
        width: ${(props) => `${props.width}px`};
        margin-bottom: 2rem;
    }

    @media screen and (min-width: ${(props) => props.theme?.breakpoints.screen_lg}) {
        margin-bottom: 5rem;
    }
`;

const styledAgencyButton = css`
    margin: 0 auto;
`;
