import * as React from "react";
import {useEffect, useState} from "react";
import {css, Theme} from "@emotion/react";

import {Flex} from "../../app/atoms/Flex";
import {GridStatic} from "../../app/atoms/Layout";
import {IQualityList, QualityList} from "./QualityList";
import {QualityViewPort} from "./QualityViewPort";
import {sectionHeaderBase} from "./RegisterChecklist";

import cart from "../../styles/assets/svg/cart_icon.svg";
import search from "../../styles/assets/svg/search_icon.svg";
import warning from "../../styles/assets/svg/warning_icon.svg";

interface IProps {
    title?: string;
    showRegistrationButton?: boolean;
}

export enum QualitySectionBoxType {
    map,
    market,
    commercial
}

const qualitySectionTexts: IQualityList[] = [
    {
        header: "Wyszukiwanie na mapie",
        icon: search,
        text: "Wygodnie wyszukuj oferty w okolicy, która Cię interesuje. Dzięki temu sprawdzisz też otoczenie (np. szkoły, sklepy, przystanki).",
        type: QualitySectionBoxType.map
    },
    {
        header: "Prezentujemy rynek pierwotny i wtórny",
        icon: cart,
        text: "Na gethome.pl użytkownik może łatwo przeglądać oferty z rynku pierwotnego i wtórnego. Oferty z rynku pierwotnego pochodzą zawsze w pierwszej kolejności od deweloperów.",
        type: QualitySectionBoxType.market
    },
    {
        header: "Brak reklam, podbić i wyróżnień",
        icon: warning,
        text: "W portalu stawiamy w 100% na użytkownika – z tego powodu nie emitujemy żadnych reklam, systemu podbić czy wyróżnień.",
        type: QualitySectionBoxType.commercial
    }
];
export const QualitySection = (props: IProps) => {
    const [activeBox, setActiveBox] = useState(QualitySectionBoxType.map);

    useEffect(() => {
        const changeActiveBoxImage = setInterval(() => {
            setActiveBox(activeBox < qualitySectionTexts.length - 1 ? activeBox + 1 : 0);
        }, 5000);
        return () => clearInterval(changeActiveBoxImage);
    });

    const addClassToImage = () => {
        switch (activeBox) {
            case QualitySectionBoxType.map:
                return "commercial";
            case QualitySectionBoxType.market:
                return "market";
            case QualitySectionBoxType.commercial:
                return "commercial";
            default:
                return "commercial";
        }
    };

    return (
        <section css={qualityWrapper}>
            <GridStatic width="1230px">
                <h2 css={sectionHeader} className="quality-section-header">
                    {props.title || "Stawiamy na jakość!"}
                </h2>

                <Flex>
                    <QualityList
                        showRegistrationButton={props.showRegistrationButton}
                        setActiveBoxImage={setActiveBox}
                        list={qualitySectionTexts}
                        active={activeBox}
                    />
                    <QualityViewPort className={addClassToImage()} />
                </Flex>
            </GridStatic>
        </section>
    );
};

const qualityWrapper = (theme: Theme) => css`
    padding: 2rem 0.5rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        padding: 4.5rem 0.5rem 0;
    }
`;

const sectionHeader = (theme: Theme) => css`
    ${sectionHeaderBase(theme)};
    margin-bottom: 5rem;
`;
