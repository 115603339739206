import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {IServices} from "../../app/services/IServices";
import {RequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {ILocation} from "../list/actions/fetch_location_by_slug_at_route";

export const fetchLocationBySlug =
    (services: Partial<IServices>, slug: string, actionTypes: RequestActionTypes, requestId?: string) => (dispatch: Dispatch) => {
        dispatch({type: actionTypes.start});

        const url = apiLink.locations.detail({})({slug});

        return getRequest(services, url, requestId).then((location: ILocation) => {
            dispatch({type: actionTypes.success, result: location});
            return location;
        });
    };
