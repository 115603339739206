import React from "react";
import {css, Theme} from "@emotion/react";
import {truncate} from "lodash";
import {appLink} from "@web2/gh_routes";

import {IAuthorListAuthor} from "../../app/interfaces/response/author_list";
import {m_0} from "../../styles/helpers";
import {SchemaAuthorDetail} from "../schemas/SchemaAuthorDetail";
import {AuthorArticleCount} from "./AuthorArticleCount";
import {AuthorAvatar} from "./AuthorAvatar";

interface IProps {
    authors: IAuthorListAuthor[];
}

export const AuthorListList = (props: IProps) => {
    return (
        <ul css={authorListList}>
            {props.authors.map((author) => {
                const authorDescriptionTruncate = truncate(author.description, {
                    length: 400,
                    separator: ". ",
                    omission: "."
                });

                return (
                    <li css={authorListListItem} key={author.id}>
                        <AuthorAvatar name={author.name} image={author.image && author.image.aut_img_160x160} css={authorAvatar} />

                        <div>
                            <h2 css={authorHeading}>
                                <a href={appLink.article.author.detail({authorSlug: author.slug})} css={authorName}>
                                    {author.name}
                                </a>
                            </h2>

                            <AuthorArticleCount authorArticleCount={author.article_count} />

                            <h2 css={newestArticles}>Najnowsze artykuły:</h2>

                            <ul css={newestArticlesList}>
                                {author.newest_articles.map((article) => {
                                    return (
                                        <li key={article.id}>
                                            <h3 css={m_0}>
                                                <a css={newestArticlesLink} href={appLink.article.detailOrCategoryList({slug: article.slug})}>
                                                    {article.title}
                                                </a>
                                            </h3>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <SchemaAuthorDetail author={author} authorImage={author.image?.aut_img_160x160} description={authorDescriptionTruncate} />
                    </li>
                );
            })}
        </ul>
    );
};

const authorListList = (theme: Theme) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: ${theme.breakpoints.screen_md}) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem;
    }
`;

const authorListListItem = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 2rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
`;

const authorAvatar = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.screen_md}) {
        margin-right: 2.5rem;
    }
`;

const authorHeading = (theme: Theme) => css`
    text-align: center;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        margin-top: 0;
        margin-bottom: 0.5rem;
        text-align: left;
    }
`;

const newestArticles = (theme: Theme) => css`
    color: ${theme.colors.gray_darker};
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    text-align: center;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        text-align: left;
    }
`;

const authorName = (theme: Theme) => css`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.6;
    color: ${theme.colors.text_color};

    &:hover,
    &:focus,
    &:active {
        color: ${theme.colors.text_color};
        text-decoration: none;
    }
`;

const newestArticlesList = css`
    list-style: disc;
    padding-left: 2.2rem;
`;

const newestArticlesLink = (theme: Theme) => css`
    display: block;
    color: ${theme.colors.text_color};
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: underline;
    padding-bottom: 1.5rem;

    &:hover,
    &:focus,
    &:active {
        color: ${theme.colors.text_color};
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding-bottom: 0.5rem;
    }
`;
