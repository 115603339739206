import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const PersonIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M6,6.06H4A3.48,3.48,0,0,0,.51,9.55.45.45,0,0,0,1,10H9a.45.45,0,0,0,.45-.45A3.48,3.48,0,0,0,6,6.06Zm-4.56,3A2.59,2.59,0,0,1,4,7H6A2.59,2.59,0,0,1,8.55,9.09ZM5,5A2.48,2.48,0,1,0,2.53,2.47,2.48,2.48,0,0,0,5,5Zm0-4A1.57,1.57,0,1,1,3.43,2.47,1.57,1.57,0,0,1,5,.91Z" />
        </SvgIcon>
    );
};
