import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {differenceInYears} from "date-fns";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";
import {pluralize} from "@web2/string_utils";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {FlexColumn} from "../../app/atoms/Flex";
import {GridStatic} from "../../app/atoms/Layout";
import {ParagraphLight} from "../../app/atoms/Paragraph";
import {m_0} from "../../styles/helpers";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {sectionHeaderBase} from "./RegisterChecklist";

import agencyChartImage from "../../styles/assets/images/agency_chart.png";

const yearsPlural = pluralize(["rok", "lata", "lat"]);
const yearsDiff = Math.abs(differenceInYears(new Date(), new Date("2009-10-01")));

export const CollaborationSection = () => (
    <section css={collaborationWrapper}>
        <GridStatic width="1230px">
            <Column justifyContent="space-between">
                <div>
                    <h2 css={sectionHeader}>Współpraca, która przynosi efekty</h2>

                    <Paragraph fontSize={16}>
                        Jako rynekpierwotny.pl jesteśmy na rynku od {yearsDiff} {yearsPlural(yearsDiff)}.{" "}
                        <b>Współpracuje z nami 85% deweloperów, którzy dzięki nam sprzedają 43% swoich mieszkań.</b>
                    </Paragraph>

                    <Paragraph fontSize={16}>W 2021 przekazaliśmy ponad 650 000 wartościowych zapytań o nieruchomości od internautów.</Paragraph>

                    <Paragraph fontSize={16} marginBottom={3}>
                        Portal gethome.pl to nasz <strong>nowy projekt dla rynku wtórnego</strong> oraz pierwotnego. Już w tej chwili{" "}
                        <strong>serwis odwiedza kilkaset tysięcy osób miesięcznie</strong>. Od startu dostarczyliśmy dla biur pośrednictwa kilkadziesiąt tysięcy
                        zapytań o zakup nieruchomości!
                    </Paragraph>

                    <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg" css={styledAgencyButton}>
                        Zarejestruj biuro nieruchomości
                        <ArrowNextIcon mainColor="#fff" />
                    </StyledAgencyButton>
                </div>

                <div css={chartWrapper}>
                    <div css={chartImage} />
                </div>
            </Column>
        </GridStatic>
    </section>
);

/*
CollaborationSection Styles
 */

const collaborationWrapper = (theme: Theme) => css`
    padding: 3.5rem 0.5rem;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 7rem 0.5rem;
    }
`;

const Column = styled(FlexColumn)`
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        flex-direction: row;
    }
`;

const Paragraph = styled(ParagraphLight)`
    text-align: center;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        max-width: 480px;
        text-align: left;
    }
`;

const sectionHeader = (theme: Theme) => css`
    ${sectionHeaderBase(theme)};
    margin-bottom: 3rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        max-width: 500px;
        text-align: left;
    }
`;

const chartWrapper = (theme: Theme) => css`
    position: relative;
    height: 250px;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        height: 500px;
    }
`;

const chartImage = (theme: Theme) => css`
    background: url("${agencyChartImage}") no-repeat center;
    background-size: contain;
    height: 100%;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        width: 610px;
    }

    @media screen and (min-width: ${theme.breakpoints.screen_lg}) {
        width: 670px;
    }

    @media screen and (min-width: ${theme.breakpoints.screen_lg_ipad}) {
        width: 763px;
        height: 479px;
        position: absolute;
        right: -75px;
    }
`;

const styledAgencyButton = (theme: Theme) => css`
    margin: 0 auto 2rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        ${m_0};
    }
`;
