import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appPath} from "@web2/gh_routes";
import {ArrowNextIcon} from "@web2/icons";

import {StyledAgencyButton} from "../../app/atoms/Buttons";
import {GridStatic} from "../../app/atoms/Layout";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {handleRegisterAgencyBtn} from "../../tracking/google_tag_manager/registerAgencyBtnEvents";
import {MapSearchGraph} from "./MapSearchGraph";
import {sectionHeaderBase} from "./RegisterChecklist";

export const MapSearchSection = () => (
    <GridStatic width="1230px" css={mapSearchWrapper} as="section">
        <h2 css={sectionHeaderBase}>Łatwe poszukiwania</h2>

        <Paragraph>Naszym celem jest maksymalna przejrzystość dla poszukującego, dlatego wszystkie oferty prezentujemy na mapie.</Paragraph>

        <MapSearchGraph />

        <StyledAgencyButton href={appPath.agencyRegister} onClick={handleRegisterAgencyBtn} variant="success" size="lg">
            Zarejestruj biuro nieruchomości
            <ArrowNextIcon mainColor="#fff" />
        </StyledAgencyButton>
    </GridStatic>
);

/*
NoDuplicatesSection Styles
 */

const mapSearchWrapper = (theme: Theme) => css`
    padding: 2rem 0.5rem;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 3.5rem 0.5rem;
    }
`;

const Paragraph = styled(ParagraphGray)`
    font-size: 2rem;
    line-height: 3rem;
    max-width: 905px;
    text-align: center;
    margin: 0 auto 5rem;
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin: 0 auto 6.5rem;
    }
`;
