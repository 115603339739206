import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {BuylandoLPHeader} from "./styles/buylando_styles";

import rp_widok from "../assets/Rynek_Pierwotny_Widok_B2B 3.png";
export const WeRelyOn = () => (
    <BuylandoWrapper>
        <Container justifyContent="center">
            <ImageColumn>
                <Image src={rp_widok} alt="Rynek Pierwotny Widok" loading="lazy" />
            </ImageColumn>
            <Article>
                <header>
                    <SectionHeader>Stawiamy na jakość</SectionHeader>
                </header>
                <Paragraph>
                    Przykładamy szczególną wagę do jakości publikowanych ofert. <strong>We współpracy z serwisem buylando</strong> udostępniamy intuicyjny
                    formularz, dzięki któremu szybko stworzysz profesjonalne ogłoszenie o sprzedaży gruntu. Wspólnie wspieramy Klientów w procesie kupna i
                    sprzedaży nieruchomości.{" "}
                </Paragraph>
            </Article>
        </Container>
    </BuylandoWrapper>
);

/*
WeRelyOn Styles
 */
const dBlock = css`
    display: block;
`;

const BuylandoWrapper = styled.section`
    background: #fbfbfb;
`;
const ImageColumn = styled(FlexColumn)`
    width: 85%;
    height: auto;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        width: 50%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xl}) {
        width: 34%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xxl}) {
        width: 26%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xxxl}) {
        width: 12%;
    }
`;

const Article = styled.article`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
    justify-content: start;
    padding: 0rem 2rem 0 2rem;
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        width: 50%;
        height: 430px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        height: 490px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xl}) {
        height: 548px;
    }
`;

const Container = styled(Flex)`
    flex-wrap: wrap;
    flex-direction: column;
    display: flex;
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        flex-direction: row;
    }
`;

const SectionHeader = styled(BuylandoLPHeader)`
    text-align: left;
    margin: 0px 0 4rem 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        margin-top: 5rem;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        margin-top: 8rem;
    }
`;

const Paragraph = styled(ParagraphGray)`
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 6rem;
`;
const Image = styled.img`
    margin: 5rem 0 5rem -20rem;
    border: 10px solid #4f4f57;
    border-radius: 30px;
    z-index: 1;
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        border-width: 15px;
        position: absolute;
        left: -52%;
        margin: 0;
        transform: translateX(60%);
        width: 62%;
        margin-top: 7.5rem;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xl}) {
        left: -21%;
        transform: none;
        height: 524px;
        width: auto;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xxl}) {
        left: -17%;
    }
`;
