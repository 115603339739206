export enum GtmContentType {
    HOUSE = "dom",
    FLAT = "mieszkanie",
    DEVELOPER = "deweloper",
    INVESTMENT = "inwestycja",
    AGENCY = "agencja",
    AGENT = "agent",
    LOT = "działka",
    AUTHOR = "autor"
}
