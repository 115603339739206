import React from "react";
import {useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {GridStatic} from "../../app/atoms/Layout";
import {AgencyRegisterConfirmation} from "./AgencyRegisterConfirmation";
import {AgencyRegisterForm} from "./AgencyRegisterForm";

import bgImage from "../../styles/assets/background/buildings_opt.svg";
import logo from "../../styles/assets/logo/gethome.svg";

export const AgencyRegisterDynamic = () => {
    const [isSent, setIsSent] = useState(false);

    return (
        <AgencyRegisterHolder isSent={isSent}>
            <AgencyRegisterGridStatic width="700px" isSent={isSent} as="section">
                {isSent ? (
                    <AgencyRegisterConfirmation />
                ) : (
                    <>
                        <img src={logo} alt="GetHome logo" width={227} css={ghLogo} />

                        <h1 css={agencyRegisterHeadline}>Rozpocznij współpracę i&nbsp;opublikuj swoje oferty!</h1>

                        <AgencyRegisterForm setIsSent={setIsSent} />
                    </>
                )}
            </AgencyRegisterGridStatic>
        </AgencyRegisterHolder>
    );
};

interface IAgencyRegister {
    isSent: boolean;
}

export const AgencyRegisterHolder = styled.article<IAgencyRegister>`
    height: calc(100vh - 4rem);
    position: relative;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 90%;

    ${(props) =>
        !props.isSent &&
        css`
            background-image: url(${bgImage});
        `};

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 5rem);
        background-size: initial;
    }
`;

export const AgencyRegisterGridStatic = styled(GridStatic)<IAgencyRegister>`
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4rem;

    ${(props) =>
        props.isSent &&
        css`
            padding-left: 0;
            padding-right: 0;
        `};

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin: 0 auto;
        padding-top: 8rem;
    }
`;

export const ghLogo = (theme: Theme) => css`
    display: block;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin: 0 auto 3rem;
    }
`;

export const agencyRegisterHeadline = (theme: Theme) => css`
    font-size: 2.6rem;
    text-align: center;
    margin-bottom: 2rem;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        margin-bottom: 3rem;
    }
`;
