import styled from "@emotion/styled";
import {darken} from "polished";
import {Button} from "@web2/button";

import {btn, cubicTransition} from "../../styles/mixins";

export const StandardButton = styled("button")`
    ${btn};
    display: inline-block;
    padding: 0.9rem 1.9rem 0.8rem;
    border: 1px solid;
    border-radius: 0.4rem;
    background-color: transparent;
    transition: all 500ms ${cubicTransition};
    font-size: 1.2rem;
    text-align: center;
    cursor: pointer;
    line-height: 1.5833333333;

    & + & {
        margin-left: 1rem;
    }
`;

export const ButtonGreen = styled(StandardButton)`
    max-width: 100%;
    background-color: ${(props) => props.theme.colors.brand_success};
    border-color: ${(props) => props.theme.colors.brand_success};
    color: white;
    font-size: 1.6rem;
    line-height: 1.625;
    text-align: center;

    &:hover,
    &:focus {
        color: white;
        text-decoration: none;
        background-color: ${(props) => darken(0.1, props.theme.colors.brand_success)};
    }

    .svg-icon {
        margin-right: 1rem;
        vertical-align: middle;
    }

    @media print {
        display: none;
    }
`;

export const GreenDarkButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.green_darker};
    border-color: ${(props) => props.theme.colors.green_darker};

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        background-color: ${(props) => darken(0.1, props.theme.colors.green_darker)};
        border-color: ${(props) => darken(0.1, props.theme.colors.green_darker)};
    }
`;

export const StyledAgencyButton = styled(GreenDarkButton)`
    max-width: 360px;
    margin: 0 auto 3rem;
    display: flex;

    .svg-icon {
        margin-left: 1rem;
    }
`;
