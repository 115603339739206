import * as React from "react";

import {BlogSection} from "./BlogSection";
import {HeroBox} from "./HeroBox";
import {HeroSection} from "./HeroSection";
import {OurExpertQuote} from "./OurExpertQuote";
import {StepByStep} from "./StepByStep";
import {WeRelyOn} from "./WeRelyOn";
import {WhatCanISell} from "./WhatCanISell";

export const BuylandoDynamic = () => {
    return (
        <>
            <HeroSection />
            <HeroBox />
            <WhatCanISell />
            <StepByStep />
            <WeRelyOn />
            <OurExpertQuote />
            <BlogSection />
        </>
    );
};
