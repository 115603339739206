import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const ArrowReturnIcon = (props: IProps) => {
    return (
        <svg className={classNames(props.className, "svg-icon")} width={props.width || "20"} height={props.height || "13"} viewBox="0 0 20 13">
            <path
                fill={props.mainColor || "#9069C0"}
                d="M9.0601124,15.8346244 C9.4711906,16.2457026 9.4711906,16.9121921 9.0601124,17.3232703 C8.6490342,17.7343485 7.98254475,17.7343485 7.57146655,17.3232703 L2.30830865,12.0601124 C1.89723045,11.6490342 1.89723045,10.9825447 2.30830865,10.5714665 L7.57146655,5.30830865 C7.98254475,4.89723045 8.6490342,4.89723045 9.0601124,5.30830865 C9.4711906,5.71938685 9.4711906,6.3858763 9.0601124,6.79695451 L5.593,10.263 L14.3976608,10.2631579 C17.8646926,10.2631579 20.400267,11.485667 21.8529385,13.9370501 C22.1493133,14.4371826 21.984135,15.0828794 21.4840025,15.3792542 C20.98387,15.6756291 20.3381732,15.5104508 20.0417984,15.0103183 C18.9993432,13.2511751 17.1684458,12.3684211 14.3976608,12.3684211 L5.594,12.368 L9.0601124,15.8346244 Z"
                transform="translate(-2 -5)"
            />
        </svg>
    );
};
