import {filter, includes, uniq} from "lodash";
import * as lscache from "lscache";
import {Dispatch} from "redux";
import {getCookie, removeCookie} from "@web2/request_utils";

import {FAVOURITES_COOKIE_NAME, FAVOURITES_LS_NAME} from "../constants/storage_keys";
import {IAction} from "../interfaces/IAction";

export interface ILoadLocalStorageFavouritesToStore extends IAction {
    offers: string[];
}

export const loadFavouritesToStore = (offers: string[]) => async (dispatch: Dispatch) => {
    return dispatch({type: LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE, offers});
};

export const LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE = "LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE";
export const loadLocalStorageFavouritesToStore =
    () =>
    async (dispatch: Dispatch): Promise<any> => {
        // FM used to store favourites in cookies. Code below makes sure to transfer favs from cookies to LS.
        const favouritesFromCookies: string = getCookie(FAVOURITES_COOKIE_NAME) || "";
        const favouritesFromLS: string[] = lscache.get(FAVOURITES_LS_NAME) || [];
        const favourites = uniq([...(favouritesFromCookies ? JSON.parse(favouritesFromCookies) : []), ...favouritesFromLS]);
        if (favouritesFromCookies) {
            removeCookie(FAVOURITES_COOKIE_NAME);
            lscache.set(FAVOURITES_LS_NAME, favourites);
        }
        return dispatch(loadFavouritesToStore(favourites));
    };

export interface ISetFavouriteOffer extends IAction {
    offer: string;
}

export const SET_FAVOURITE_OFFER = "SET_FAVOURITE_OFFER";
export const setFavouriteOffer =
    (offer: string) =>
    async (dispatch: Dispatch): Promise<any> => {
        const favouritesFromCookies: string = getCookie(FAVOURITES_COOKIE_NAME) || "";
        const favouritesFromLS: string[] = lscache.get(FAVOURITES_LS_NAME) || [];
        const favourites = uniq([...(favouritesFromCookies ? JSON.parse(favouritesFromCookies) : []), ...favouritesFromLS]);
        if (favourites) {
            if (includes(favourites, offer)) {
                const offersToBeSaved = filter(favourites, (favouriteOffer) => favouriteOffer !== offer);
                lscache.set(FAVOURITES_LS_NAME, offersToBeSaved);
            } else {
                const offersToBeSaved = [offer, ...favourites];
                lscache.set(FAVOURITES_LS_NAME, offersToBeSaved);
            }
        } else {
            lscache.set(FAVOURITES_LS_NAME, [offer]);
        }
        dispatch({type: SET_FAVOURITE_OFFER, offer});
    };
