import {FC, useState} from "react";
import {Form, Formik, FormikConfig, FormikValues, useFormikContext} from "formik";
import {Button, IButtonProps} from "@web2/button";
import {Checkbox, ICheckboxProps, ICheckboxValue} from "@web2/checkbox";
import {IInputProps, Input, ITextareaProps, Textarea} from "@web2/input";
import {IPropsSelect, Select} from "@web2/select";
import {numberNoDelimiter, numberWithDelimiter} from "@web2/string_utils";

import {getFormikFieldProps} from "./get_formik_field_props";
import {OnChangeHandlingComponent} from "./OnChangeHandlingComponent";

interface IProps<TValues> extends FormikConfig<TValues> {
    onChange?: () => void;
    novalidate?: boolean;
}

export const FormikForm = <T extends FormikValues>(props: IProps<T>) => {
    const {children, ...formikProps} = props;

    return (
        <Formik {...formikProps}>
            {(formProps) => {
                return (
                    <>
                        {props.onChange && <OnChangeHandlingComponent onChange={props.onChange} />}
                        <Form onSubmit={formProps.handleSubmit} noValidate={props.novalidate}>
                            {typeof children === "function" ? children(formProps) : children}
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

export type IFieldProps<StandardFieldType> = Omit<StandardFieldType, "onChange" | "name" | "onAfterChange" | "value" | "error" | "checked"> & {
    name: string;
};
const FormikInput = <TValues extends Record<string, unknown>>(props: IFieldProps<IInputProps<string>>) => {
    const formikProps = useFormikContext<TValues>();
    return <Input {...props} {...getFormikFieldProps<string, TValues>(formikProps, props.name)} />;
};
FormikForm.Input = FormikInput;

const FormattedNumberFormikInput = <TValues extends Record<string, unknown>>(props: IFieldProps<IInputProps<string>>) => {
    const formikProps = useFormikContext<TValues>();
    const fieldProps = getFormikFieldProps<string, TValues>(formikProps, props.name);

    const onChange = (name: string, value: string) => {
        const regex = /^[0-9 ]*$/;
        if (value.match(regex)) fieldProps.onChange(props.name, numberNoDelimiter(value));
    };

    const fakeValue = fieldProps.value && numberWithDelimiter(fieldProps.value);
    return <Input {...props} {...fieldProps} type="text" onChange={onChange} value={fakeValue} inputMode="numeric" />;
};

FormikForm.FormattedNumberInput = FormattedNumberFormikInput;

const PerformantFormikInput = <TValues extends Record<string, unknown>>(props: IFieldProps<IInputProps<string>>) => {
    const formikProps = useFormikContext<TValues>();
    const fieldProps = getFormikFieldProps<string, TValues>(formikProps, props.name);
    const [value, setValue] = useState(fieldProps.value);

    const onChange = (name: string, value: string) => {
        setValue(value);
    };
    const onBlur = () => {
        fieldProps.onChange(props.name, value);
    };

    return <Input {...props} {...fieldProps} onChange={onChange} value={value} onBlur={onBlur} />;
};
FormikForm.PerformantInput = PerformantFormikInput;
//

const FormikTextarea = <TValues extends Record<string, unknown>>(props: IFieldProps<ITextareaProps<string>>) => {
    const formikProps = useFormikContext<TValues>();
    return <Textarea {...props} {...getFormikFieldProps<string, TValues>(formikProps, props.name)} />;
};
FormikForm.Textarea = FormikTextarea;
//

const FormikCheckbox = <TValues extends Record<string, unknown>>(props: IFieldProps<ICheckboxProps>) => {
    const formikProps = useFormikContext<TValues>();
    return <Checkbox {...props} {...getFormikFieldProps<ICheckboxValue, TValues>(formikProps, props.name)} />;
};
FormikForm.Checkbox = FormikCheckbox;
//

const FormikSelect = <TValues extends Record<string, unknown>>(props: IFieldProps<IPropsSelect>) => {
    const formikProps = useFormikContext<TValues>();

    return <Select {...props} {...getFormikFieldProps<string, TValues>(formikProps, props.name)} />;
};
FormikForm.Select = FormikSelect;
//

const FormikButton: FC<IButtonProps> = (props) => {
    const formikProps = useFormikContext();
    return (
        <Button {...props} isLoading={formikProps.isSubmitting}>
            {props.children}
        </Button>
    );
};
FormikForm.Button = FormikButton;
