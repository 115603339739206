import {css} from "@linaria/core";

import {ArrowDownIcon} from "../../offer/list/components/icons/ArrowDownIcon";
import {ArrowUpIcon} from "../../offer/list/components/icons/ArrowUpIcon";
import {getThemeBreakpointCorrect} from "../../styles/linaria_variable_factory";

interface IProps {
    active: boolean;
    onClick?: () => void;
}
export const ShowMoreCities = (props: IProps) => {
    const {active, onClick} = props;
    return (
        <span className={showMoreStyle} onClick={onClick}>
            {active ? "Zwiń" : "Rozwiń"} {active ? <ArrowUpIcon fillColor="#9069c0" /> : <ArrowDownIcon fillColor="#9069c0" />}
        </span>
    );
};

const showMoreStyle = css`
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 1rem;
    flex-direction: row;
    column-gap: 0.5rem;
    padding: 0.5rem 1rem;
    height: 5rem;
    cursor: pointer;
    line-height: 1;
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        height: auto;
    }
`;
