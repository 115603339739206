import * as React from "react";
import {css, Theme} from "@emotion/react";

interface IProps {
    href?: string;
}

export const HeroSectionSimpleContactBtn = (props: IProps) => {
    return (
        <a href={props.href} css={contactBtn}>
            <div css={labelHolder}>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="38" viewBox="0 0 31 38">
                    <path
                        d="M28.52,0H5.29A2.47,2.47,0,0,0,2.82,2.46V6.08H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v4H1a1,1,0,1,0,0,2H2.82v3.31A2.47,2.47,0,0,0,5.29,38H28.52A2.47,2.47,0,0,0,31,35.54V2.46A2.47,2.47,0,0,0,28.52,0Zm.42,35.54a.42.42,0,0,1-.42.42H5.29a.42.42,0,0,1-.42-.42V32.23H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87v-4H6.66a1,1,0,1,0,0-2H4.87V2.46A.42.42,0,0,1,5.29,2H28.52a.42.42,0,0,1,.42.42Zm-8-15.76a5.58,5.58,0,0,0,2.39-4.57,5.67,5.67,0,0,0-11.33,0,5.58,5.58,0,0,0,2.39,4.57,8.13,8.13,0,0,0-4,7.13,1,1,0,0,0,2,0c0-3.29,2.33-6,5.19-6s5.18,2.67,5.18,6a1,1,0,0,0,2.06,0A8.14,8.14,0,0,0,20.89,19.78Zm-3.27-1a3.58,3.58,0,1,1,3.61-3.57A3.59,3.59,0,0,1,17.62,18.78Z"
                        fill="#9069c0"
                    />
                </svg>
                Kontakt
            </div>
        </a>
    );
};

const contactBtn = (theme: Theme) => css`
    margin-top: 3rem;
    padding: 0.7rem 2.5rem 0.7rem 1.5rem;
    color: ${theme.colors.brand_primary};
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    background: #efe8f6;
    box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 2.5rem;
    border: 2px solid ${theme.colors.brand_primary};
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: ${theme.colors.brand_primary};
    }

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        display: none;
    }
`;

const labelHolder = css`
    display: flex;
    align-items: center;

    svg {
        margin-right: 1rem;
        height: 30px;
    }
`;
