import {apiLink} from "../../../app/routes/api_link";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catch404, catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {bindRelatedPhrasesToText} from "../../../article/utils/bind_related_phrases_to_text";

interface ILocationDescriptionResponse {
    description: string;
    local_phrases?: {
        phrase: string;
        destination_url: string;
    }[];
}

export const fetchListDescription = (services: Partial<IServices>, path: string) => {
    const url = appendQueryString(apiLink.seoDescription(), {path});

    return getRequest(services, url, "fetchLocationDescriptionAtRoute")
        .then((result: ILocationDescriptionResponse) => {
            return result.local_phrases ? bindRelatedPhrasesToText(result.local_phrases, result.description, path) : result.description;
        })
        .catch(
            catch404(async () => {
                return null;
            })
        )
        .catch(catchAbortedError(() => false));
};
