import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";

interface IProps {
    className?: string;
    error?: string[] | null;
    errorOnBottom?: boolean;
}

interface ITheme {
    // TODO: this interface is incorrect `forms` do not exist in themeGh
    forms: {
        error_message_color: string;
        error_message_font_size: string;
        error_message_line_height: number;
        error_message_padding_bottom: string;
        error_message_onBottom_padding_bottom: string;
        error_message_onBottom_padding_top: string;
    };
    padding: {
        padding_base_horizontal: string;
    };
}

interface IErrorMessageThemeProps {
    theme?: ITheme;
    errorOnBottom?: boolean;
}

export const FormErrorMessage: React.FC<IProps> = (props: IProps) => {
    const errorMessage = props.error && map(props.error, (item: string | null, idx: number) => <span key={idx}>{item}</span>);

    return (
        <ErrorMessage className={props.className} errorOnBottom={props.errorOnBottom}>
            {errorMessage}
        </ErrorMessage>
    );
};

/*
Styles
 */

const ErrorMessage = styled.div<IErrorMessageThemeProps>`
    color: ${(props) => props.theme.forms.error_message_color};
    font-size: ${(props) => props.theme.forms.error_message_font_size};
    text-align: left;
    line-height: ${(props) => props.theme.forms.error_message_line_height};
    text-transform: lowercase;
    padding-bottom: ${(props) => props.theme.forms.error_message_padding_bottom};

    ${(props) =>
        props.errorOnBottom &&
        css`
            padding-bottom: ${props.theme.forms.error_message_onBottom_padding_bottom};
            padding-top: ${props.theme.forms.error_message_onBottom_padding_top};
        `};
`;
