import * as React from "react";
import {Suspense, useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {includes, isEmpty, map} from "lodash";
import * as lscache from "lscache";
import {bindActionCreators, Dispatch} from "redux";
import {appLink, parseOfferSlugToObject} from "@web2/gh_routes";
import {ArrowReturnIcon, HeartIcon} from "@web2/icons";
import {IMarker, LeafletMouseEvent} from "@web2/open-street-map";
import {useMounted} from "@web2/react_utils";
import {PulseLoader} from "@web2/ui_utils";

import {loadFavouritesToStore, setFavouriteOffer} from "../../app/actions/load_local_storage_favourites_to_store";
import {Flex} from "../../app/atoms/Flex";
import {OpenStreetMapGH} from "../../app/components/OpenStreetMapGH";
import {FAVOURITES_LS_NAME} from "../../app/constants/storage_keys";
import {useDebouncedOfferBoxMouseEvents} from "../../app/hooks/use_debounced_offer_box_mouse_events";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {getNormalizedCoordinates} from "../../app/utils/get_normalized_coordinates";
import {osmMapEnable} from "../../app/utils/read_environment_variables";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {ApplicationModal} from "../../application/components/ApplicationModal";
import {ApplicationSourceSection, getOfferApplicationSource} from "../../application/utils/ApplicationSource";
import {handleOfferBoxMetaClick, IOfferBoxOffer, OfferBox, OFFERBOX_MAX_MOBILE_BREAKPOINT} from "../../offer/detail/components/offer_box/OfferBox";
import {MapLoader} from "../../offer/list/components/OfferListMapDesktop";
import {popupStyles} from "../../offer/list/components/OfferListMapOsm";
import {renderDesktopOfferTooltip} from "../../offer/list/map_utils/render_desktop_offer_tooltip";
import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {FullOfferApplicationModalHeader} from "../../offer/utils/constants_offer";
import {AlgolyticsSourceSection} from "../../tracking/algolytics/interaction/application_sent_hit";
import {GtmSource} from "../../tracking/google_tag_manager/utils/gtm_source";
import {ViewType} from "../../tracking/view_type/view_type";
import {fetchFavouriteOfferList, removeFetchedFavourite} from "../actions/fetch_favourite_offer_list";

import emptyBoxIcon from "../../styles/assets/svg/empty_box.svg";
import markerIcon from "../../styles/assets/svg/gethome_pin.svg";

interface IActionProps {
    fetchFavouriteOfferList: typeof fetchFavouriteOfferList;
    setFavouriteOffer: typeof setFavouriteOffer;
    removeFetchedFavourite: typeof removeFetchedFavourite;
}

interface IOfferBoxOfferPopup extends Omit<IOfferBoxOffer, "offer_type"> {
    offer_type: string | string[];
}

interface IStateProps {
    favourites: string[];
    favouriteOfferList: (IOfferBoxOffer & {coordinates: {lat: number; lng: number}})[];
    favouriteOfferListRequest: RequestState;
    visitedOffers: string[];
    viewType: ViewType | null;
}

interface IProps extends IStateProps, IActionProps {}

const FavouriteOffersDynamicC = (props: IProps) => {
    const isMounted = useMounted();

    const [applicationOfferData, setApplicationOfferData] = useState<{offer: IOfferBoxOffer; gtmSource: GtmSource} | null>(null);

    useEffect(() => {
        if (!isEmpty(props.favourites)) {
            props.fetchFavouriteOfferList();
        }
    }, []);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.favouriteOfferListRequest === RequestState.Success && props.favourites.length !== props.favouriteOfferList.length) {
            // offer list endpoint returned less offers that user had saved in LS, that means that those extra offers are now probably inactive
            // replace favourites with offer IDs returned from api, otherwise users favourite count will show too much
            const activeOffers = props.favouriteOfferList.reduce((acc, current) => {
                return [...acc, current.id];
            }, [] as string[]);
            lscache.set(FAVOURITES_LS_NAME, activeOffers);
            dispatch(loadFavouritesToStore(activeOffers));
        }
    }, [props.favouriteOfferListRequest]);

    /**
     * Map Callbacks
     */

    const {hoveredOfferId, onMouseEnterOfferBox, onMouseLeaveOfferBox} = useDebouncedOfferBoxMouseEvents();

    const onFavouriteClick = (offerId: string) => {
        props.setFavouriteOffer(offerId);
        dispatch(removeFetchedFavourite(offerId));
    };

    /**
     * Render
     */

    if (props.favouriteOfferListRequest === RequestState.Waiting || !isMounted) {
        return (
            <LoaderWrapper>
                <PulseLoader
                    color="#9069c0"
                    css={css`
                        margin: 3rem;
                        text-align: center;
                    `}
                />
            </LoaderWrapper>
        );
    }

    if (
        (isEmpty(props.favourites) && isEmpty(props.favouriteOfferList)) ||
        (props.favouriteOfferListRequest === RequestState.Success && isEmpty(props.favouriteOfferList))
    ) {
        return (
            <EmptyListWrapper>
                <EmptyText style={{padding: "0 2rem"}}>
                    Nie masz dodanych jeszcze
                    <br />
                    ulubionych ofert
                </EmptyText>

                <EmptyImage />
            </EmptyListWrapper>
        );
    }

    const onOfferClick = (e: React.MouseEvent<HTMLElement>, offer: {slug: string}) => {
        handleOfferBoxMetaClick(e, () => (window.location.href = appLink.fullOffer.detail.base(parseOfferSlugToObject(offer.slug))));
    };

    const firePopup = (marker: IOfferBoxOfferPopup | undefined) => (
        <div className={popupStyles} dangerouslySetInnerHTML={{__html: marker ? renderDesktopOfferTooltip(marker) : ""}}></div>
    );

    const osmMarkers: IMarker[] | undefined = props.favouriteOfferList.map((offer) => {
        return {
            id: offer.id,
            coords: getNormalizedCoordinates(offer.coordinates),
            icon: {
                url: markerIcon,
                sizes: [45, 45]
            },
            popup: () => firePopup({...offer, offer_type: offer.offer_type ? offer.offer_type : ["nieruchomość"]}),
            onClick: (e: LeafletMouseEvent) => {
                window.location.href = appLink.fullOffer.detail.base(parseOfferSlugToObject(offer.slug));
            }
        };
    });

    return (
        <div>
            <ViewHeader>
                <ReturnToOffers>
                    <a href={getOfferListUrl({offerType: "nieruchomosci", type: "na-sprzedaz"})}>
                        <ArrowReturnIcon width={24} height={24} />
                        Wszystkie oferty
                    </a>
                </ReturnToOffers>

                <HeaderTitle>
                    <HeartHolder>
                        <HeartIcon height={30} width={30} mainColor="rgba(0, 0, 0, 0)" strokeWidth={1.4} strokeColor="#37474f" viewBox="-1 0 21 17" />
                    </HeartHolder>

                    <HeaderTitleHeading>Ulubione nieruchomości</HeaderTitleHeading>
                </HeaderTitle>

                <div style={{flexBasis: "165px"}} />
            </ViewHeader>

            <ViewWrapper>
                {applicationOfferData && (
                    <ApplicationModal
                        modalHeader={FullOfferApplicationModalHeader.ASK_DEVELOPER_PRICE}
                        modalState={!!applicationOfferData}
                        onModalClose={() => setApplicationOfferData(null)}
                        applicationSource={getOfferApplicationSource(applicationOfferData.offer.market_type)}
                        applicationSourceSection={ApplicationSourceSection.MODAL}
                        algolyticsSourceSection={AlgolyticsSourceSection.BUTTON}
                        gtmSource={applicationOfferData.gtmSource}
                        viewType={props.viewType}
                        offerId={applicationOfferData.offer.id}
                    />
                )}

                <ListingWrapper>
                    {map(props.favouriteOfferList, (offer) => {
                        const isVisited = includes(props.visitedOffers, offer.id);
                        const isFavourite = includes(props.favourites, offer.id);

                        return (
                            <div
                                key={offer.id}
                                css={offerBoxWrapper}
                                onMouseEnter={() => onMouseEnterOfferBox(offer.id)}
                                onMouseLeave={() => onMouseLeaveOfferBox()}
                                onClick={(e) => onOfferClick(e, offer)}
                            >
                                <OfferBox
                                    key={offer.id}
                                    offer={offer}
                                    isVisited={isVisited}
                                    isFavourite={isFavourite}
                                    setIsFavourite={onFavouriteClick}
                                    openApplicationModal={setApplicationOfferData}
                                />
                            </div>
                        );
                    })}
                </ListingWrapper>

                <MapWrapper>
                    <Map>
                        {isMounted && osmMapEnable && (
                            <Suspense fallback={<MapLoader />}>
                                <OpenStreetMapGH markers={osmMarkers} />
                            </Suspense>
                        )}
                    </Map>
                </MapWrapper>
            </ViewWrapper>
        </div>
    );
};

function mapStateToProps(state: IStore): IStateProps {
    return {
        favourites: state.favourites.favourites,
        favouriteOfferList: state.favourites.favouriteOfferList,
        favouriteOfferListRequest: state.favourites.favouriteOfferListRequest,
        visitedOffers: state.visited.offers,
        viewType: state.viewType.current
    };
}

function mapActionsToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            fetchFavouriteOfferList,
            setFavouriteOffer,
            removeFetchedFavourite
        },
        dispatch
    );
}

export const FavouriteOffersDynamic = connect(mapStateToProps, mapActionsToProps)(FavouriteOffersDynamicC);

/*
Styles
 */
const navHeight = "116px";

const ViewHeader = styled.div`
    background-color: #fff;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ReturnToOffers = styled(Flex)`
    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.brand_primary};
        font-size: 1.4rem;
        line-height: 2.1rem;
        font-weight: 600;
        white-space: nowrap;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        cursor: pointer;
        margin-right: 1rem;

        &:hover,
        &:focus {
            color: ${(props) => props.theme.colors.brand_primary};
            text-decoration: none;
        }
    }

    .svg-icon {
        margin-right: 1rem;
    }
`;

const HeaderTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HeaderTitleHeading = styled.h1`
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
    color: ${(props) => props.theme.colors.gray_dark};
`;

const HeartHolder = styled.span`
    margin-right: 1rem;
`;

const ViewWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.gray_very_bright};
    height: 100%;
    display: flex;
    align-items: flex-start;
`;

//Listing
const ListingWrapper = styled.section`
    padding: 1rem 0 0 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 0;

    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        max-width: 100%;
        padding: 0;
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 100vh;
        justify-content: center;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 636px;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg_ipad}) {
        width: 943px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.screen_xl}) {
        width: 1255px;
    }
`;

//Map
const MapWrapper = styled.aside`
    height: calc(100vh - ${navHeight});

    @media screen and (max-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 103px);
    }

    .gm-style-iw-t::before,
    .gm-style-iw-t::after {
        display: none;
    }

    .gm-style-iw-c {
        padding: 0;
        border-radius: 0;
        box-shadow: unset;
        background-color: unset;

        > button {
            display: none !important;
        }
    }

    .gm-style-iw {
        top: 15px;

        > div {
            overflow: hidden !important;
        }

        & + div {
            display: none;
        }

        & + button {
            display: none !important;
        }
    }

    .gm-style iframe + div {
        border: none !important;
    }
`;

const Map = styled.div`
    position: fixed;
    top: ${navHeight};
    right: 0;
    height: calc(100vh - ${navHeight});
    @media screen and (max-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 103px);
        display: none;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: block;
        width: calc(100vw - 653px);
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg_ipad}) {
        width: calc(100vw - 965px);
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_xl}) {
        width: calc(100vw - 1275px);
    }
`;

const EmptyListWrapper = styled.div`
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.gray_very_bright};
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 50px);
    }
`;

const LoaderWrapper = styled.div`
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.gray_very_bright};
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 50px);
    }
`;

const EmptyText = styled.h2`
    text-align: center;
    color: ${(props) => props.theme.colors.gray_brighter};
    font-size: 2.6rem;
    font-weight: 500;
`;

const EmptyImage = styled.div`
    margin: 0 auto;
    width: 250px;
    height: 250px;
    background: url("${emptyBoxIcon}") no-repeat center center;
    background-size: contain;
`;

const offerBoxWrapper = css`
    @media (max-width: ${OFFERBOX_MAX_MOBILE_BREAKPOINT}px) {
        width: 100%;
    }
`;
