import {IOfferType} from "../../../offer/utils/constants_offer";
import {GtmContentType} from "../../google_tag_manager/utils/gtm_content_type";

export const getOfferDetailContentPropertyType = (type: IOfferType) => {
    switch (type) {
        case "apartment":
            return GtmContentType.FLAT;
        case "house":
            return GtmContentType.HOUSE;
        case "lot":
            return GtmContentType.LOT;
        default:
            return null;
    }
};
