import {prefixPath} from "@web2/prefix_path";

export const AGENCY_AND_DEVELOPERS_LOCATION_PATHS = [
    "bialystok",
    "bydgoszcz",
    "elblag",
    "gdansk",
    "gdynia",
    "gliwice",
    "katowice",
    "kielce",
    "kolobrzeg",
    "krakow",
    "legionowo",
    "lublin",
    "lodz",
    "olsztyn",
    "poznan",
    "rzeszow",
    "sopot",
    "swinoujscie",
    "szczecin",
    "torun",
    "warszawa",
    "wroclaw"
];

const LOCATION_STRING = AGENCY_AND_DEVELOPERS_LOCATION_PATHS.join("|");

export const appPath = prefixPath("/", {
    mainPage: "",
    siteMap: prefixPath("mapa-strony/", {
        base: "",
        detail: ":siteMapSlug(mieszkania-na-sprzedaz|domy-na-sprzedaz|dzialki-na-sprzedaz|mieszkania-do-wynajecia|domy-do-wynajecia|kawalerki-do-wynajecia|inwestycje-domow|inwestycje-mieszkan|kawalerki-na-sprzedaz|deweloperzy|agencje|)/"
    }),
    aboutUs: "o-nas/",
    favourites: "ulubione/",
    agency: prefixPath("agencja/", {
        base: "",
        location: `:location(${LOCATION_STRING})/`,
        detail: ":agencySlug([\\w-]+[\\d]+)/"
    }),
    agencyRegister: "rejestracja-biura-nieruchomosci/",
    agencyRegisterPremium: "oferta-premium-dla-agencji-nieruchomosci/",
    agent: prefixPath("agent/", {
        base: "",
        detail: ":agentSlug([\\w-]+)/"
    }),
    article: prefixPath("blog/", {
        base: "",
        detailOrCategoryList: ":slug([\\w-]+)/", // path handles both the article detail and list
        author: prefixPath("autorzy/", {
            base: "",
            detail: ":authorSlug([\\w-]+)/"
        })
    }),
    building: prefixPath("budynek/", {
        base: "",
        detail: ":buildingId([\\w-]+)/"
    }),
    buylando: "sprzedaj-dzialke/",
    developer: prefixPath("deweloperzy/", {
        base: "",
        location: `:location(${LOCATION_STRING})/`,
        detail: ":developerSlug([\\w-]+)-:developerId([\\w-]+)/"
    }),
    forAgent: "dla-posrednika/",
    fullOffer: prefixPath("oferta/", {
        base: "",
        detail: prefixPath(":offerSlug([\\w-]+)-:offerId([\\w-]+)/", {
            base: ""
        })
    }),
    investment: prefixPath("inwestycja/", {
        base: "",
        detail: ":investmentSlug([\\w-]+)-:investmentId([\\w-]+)/"
    }),
    offer: prefixPath("oferta/", {
        base: "",
        add: "dodaj/",
        edit: "edytuj/:propertyType([\\w]+)/:uuid([\\w-]+)/",
        verify: "weryfikacja/:uuid([\\w-]+)/:token([\\w-]+)/",
        renew: "odnowienie/:uuid([\\w-]+)/:token([\\w-]+)/",
        oldLotsDetail: ":offerSlug([\\w-]+)-:offerId([\\w-]+l)/",
        detail: prefixPath(":offerSlug([\\w-]+)-:offerId([\\w-]+)/", {
            base: "",
            print: "drukuj/"
        })
    }),
    oauth: prefixPath("oauth/", {
        redirect: "redirect/"
    }),
    page: prefixPath("strony/", {
        base: "",
        detail: ":slug([\\w-]+)/"
    }),
    redirected: {
        oldTerms: "regulamin-partnerzy/",
        oldFavourites: "moje-oferty/"
    },
    search: "search/",
    searchResult: prefixPath(":offerType(domy|mieszkania|dzialki|nieruchomosci)/", {
        base: "",
        universal: ":type(do-wynajecia|na-sprzedaz)?/:location?/:subLocation?/:subSubLocation?/",
        universalTag: ":type(do-wynajecia|na-sprzedaz)?/:location?/:subLocation?/:subSubLocation?/t/:tag/"
    }),
    terms: "regulamin/",
    termsRodo: "regulamin-rodo/",
    yourOffers: "moje-ogloszenia/",
    vendor: prefixPath("deweloperzy/", {
        base: "",
        detail: ":vendorSlug([\\w-]+)/"
    })
});
