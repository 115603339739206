import * as React from "react";
import classNames from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const ArrowNextIcon = (props: IProps) => {
    return (
        <svg
            className={classNames(props.className, "svg-icon")}
            width={props.width || "20"}
            height={props.height || "12"}
            viewBox="0 0 20 12"
            fill={props.mainColor || "#000"}
        >
            <path
                id="arrow-next-a"
                d="M6.70710678,10.2928932 C7.09763107,10.6834175 7.09763107,11.3165825 6.70710678,11.7071068 C6.31658249,12.0976311 5.68341751,12.0976311 5.29289322,11.7071068 L0.292893219,6.70710678 C-0.0976310729,6.31658249 -0.0976310729,5.68341751 0.292893219,5.29289322 L5.29289322,0.292893219 C5.68341751,-0.0976310729 6.31658249,-0.0976310729 6.70710678,0.292893219 C7.09763107,0.683417511 7.09763107,1.31658249 6.70710678,1.70710678 L3.414,5 L11.7777778,5 C15.071458,5 17.4802536,6.16138362 18.8602915,8.49019761 C19.1418476,8.9653235 18.9849283,9.57873545 18.5098024,9.86029153 C18.0346765,10.1418476 17.4212646,9.98492828 17.1397085,9.50980239 C16.149376,7.83861638 14.4100235,7 11.7777778,7 L3.414,7 L6.70710678,10.2928932 Z"
            />
            <use transform="matrix(-1 0 0 1 19.907 0)" />
        </svg>
    );
};
