import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";
import {appLink} from "@web2/gh_routes";

import {teamContact} from "../../about_us/constants/about_us_data";
import {FlexColumn} from "../../app/atoms/Flex";
import {GridStatic} from "../../app/atoms/Layout";
import {Paragraph} from "../../app/atoms/Paragraph";
import {sectionHeaderBase} from "./RegisterChecklist";

import contact_bg from "../../styles/assets/background/contact_bg.jpg";
import personIcon from "../../styles/assets/svg/person.svg";

export const ContactSection = () => (
    <section css={contactWrapper} id="contact">
        <GridStatic width="1230px">
            <h2 css={sectionHeader}>Masz pytania?</h2>

            <ParagraphSection color="#fff">
                Jesteśmy dostępni, aby odpowiedzieć na Twoje pytania i pomóc w rejestracji. <br />
                Zapraszamy do kontaktu bezpośredniego lub na adres <OfficeEmail href="mailto:biuro@gethome.pl">biuro@gethome.pl.</OfficeEmail>
            </ParagraphSection>

            <ContentWrapper justifyContent={"center"}>
                {map(teamContact, (item, idx) => (
                    <PersonalDetails alignItems={"center"} key={idx}>
                        <div>
                            <Image
                                src={item.image ? item.image : personIcon}
                                height={135}
                                width={135}
                                alt={item.name}
                                isPlaceholder={!item.image}
                                loading="lazy"
                            />
                        </div>

                        <div>
                            <Name fontSize={18} color="#fff">
                                {item.name}
                            </Name>
                            <Contact href={`mailto:${item.email}`}>{item.email}</Contact>
                            <Contact href={`tel:${item.phone_number}`}>{item.phone_number}</Contact>
                        </div>
                    </PersonalDetails>
                ))}
            </ContentWrapper>

            <p css={paragraphTAndC}>
                Administratorem danych jest Property Group Sp. z o.o.{" "}
                <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                    więcej o ochronie danych
                </a>
                .
            </p>
        </GridStatic>
    </section>
);

/*
ContactSection Styles
 */

const contactWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: auto;
    background-image: url("${contact_bg}");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 2rem;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        padding: 5rem 2rem 0.5rem 2rem;
    }
`;

const sectionHeader = (theme: Theme) => css`
    ${sectionHeaderBase(theme)};
    color: #fff;

    @media screen and (min-width: ${theme.breakpoints.screen_sm}) {
        margin-bottom: 1.5rem;
    }
`;

const ParagraphSection = styled(Paragraph)`
    font-size: 2rem;
    line-height: 3rem;
    max-width: 905px;
    text-align: center;
    margin: 0 auto 3rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        margin: 0 auto 5rem;
    }
`;
const paragraphTAndC = (theme: Theme) => css`
    font-size: ${theme.fonts.font_size_base};
    max-width: 905px;
    text-align: center;
    margin: 3rem auto 0;
    color: ${theme.colors.text_white};

    a {
        color: ${theme.colors.text_white};
        text-decoration: underline;
    }
`;

const PersonalDetails = styled(FlexColumn)`
    width: 100%;
    margin: 2rem 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        width: 50%;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
        width: 33%;
    }
`;

interface IImageStylesProps {
    height: number;
    width: number;
    isPlaceholder: boolean;
}

const Image = styled.img<IImageStylesProps>`
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};
    border: 4px solid #fff;
    border-radius: 50%;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) and (max-width: ${(props) => props.theme.breakpoints.screen_lg_ipad}) {
        height: 170px;
        width: 170px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        height: 200px;
        width: 200px;
    }

    ${(props) =>
        props.isPlaceholder &&
        css`
            opacity: 0.82;
            padding: 1rem;
        `}
`;

const Contact = styled(Paragraph)`
    color: #fff;
    font-size: 1.6rem;
    text-decoration: none;
    display: block;
    text-align: center;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: underline;
    }
`.withComponent("a");

const Name = styled(Paragraph)`
    text-align: center;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        font-size: 2.8rem;
    }
`;

const ContentWrapper = styled(FlexColumn)`
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        flex-direction: row;
    }
`;

const OfficeEmail = styled.a`
    color: #fff !important;
    text-decoration: none !important;
`;
