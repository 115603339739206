export enum FormField {
    Checkbox = 1,
    CheckboxField,
    Datepicker,
    Hidden,
    Input,
    InputOptional,
    InputRange,
    InputSearch,
    MultiSelect,
    MultiSelectAsync,
    NativeDatepicker,
    Phone,
    Radio,
    RadioField,
    Rooms,
    Select,
    SelectAsync,
    SelectRange,
    SelectRangeNumber,
    Switch,
    Textarea
}

export const ApplicationFormFields = {
    full_name: FormField.Input,
    email: FormField.Input,
    phone: FormField.Input,
    text: FormField.Input,
    new_offers_consent: FormField.Checkbox,
    financing_services: FormField.Checkbox
};
