import {styled} from "@linaria/react";

import {ArrowLeftIcon} from "../../offer/list/components/icons/ArrowLeftIcon";
import {ArrowRightIcon} from "../../offer/list/components/icons/ArrowRightIcon";
import {getThemeBreakpointCorrect, getThemeVariable} from "../../styles/linaria_variable_factory";

export const CitiesSlideArrow = (props: {orientation: "left" | "right"}) => {
    return (
        <SlideArrow orientation={props.orientation}>
            {props.orientation === "left" ? <ArrowLeftIcon size="2" fillColor="#ffffff" /> : <ArrowRightIcon size="2" fillColor="#ffffff" />}
        </SlideArrow>
    );
};

const SlideArrow = styled.span<{orientation: "left" | "right"}>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
    background: ${getThemeVariable("colors-brand_primary_dark")};
    position: absolute;
    bottom: -5rem;
    left: ${({orientation}) => `${orientation === "left" ? "31%" : "unset"}`};
    right: ${({orientation}) => `${orientation === "right" ? "27%" : "unset"}`};

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        left: ${({orientation}) => `${orientation === "left" ? "44%" : "unset"}`};
        right: ${({orientation}) => `${orientation === "right" ? "44%" : "unset"}`};
    }
    &:hover {
        cursor: pointer;
    }
`;
