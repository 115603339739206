import styled from "@emotion/styled";

export const BuylandoContainer = styled("div")`
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
`;

export const BuylandoLPHeader = styled.h2`
    color: ${(props) => props.theme.colors.gray_dark};
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    margin-bottom: 7.5rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        font-size: 2.8rem;
        line-height: 4.2rem;
    }
`;
