export enum SiteMapSection {
    ApartmentSellOffers = "apartment-sell-offers",
    HouseSellOffers = "house-sell-offers",
    LotSellOffers = "lot-sell-offers",
    ApartmentOneRoomSellOffers = "apartment-one-room-sell-offers",
    ApartmentRentOffers = "apartment-rent-offers",
    HouseRentOffers = "house-rent-offers",
    ApartmentOneRoomRentOffers = "apartment-one-room-rent-offers",
    Developers = "developers",
    Agencies = "agencies",
    InvestmentHouses = "investment-houses",
    InvestmentApartments = "investment-apartments",
    Articles = "articles",
    NewestOffers = "newest-offers"
}

export const getSiteMapSectionData = (section: SiteMapSection) => {
    switch (section) {
        case SiteMapSection.ApartmentSellOffers:
            return {title: "Mieszkania na sprzedaż", slug: "mieszkania-na-sprzedaz/"};
        case SiteMapSection.HouseSellOffers:
            return {title: "Domy na sprzedaż", slug: "domy-na-sprzedaz/"};
        case SiteMapSection.LotSellOffers:
            return {title: "Działki na sprzedaż", slug: "dzialki-na-sprzedaz/"};
        case SiteMapSection.ApartmentOneRoomSellOffers:
            return {title: "Kawalerki na sprzedaż", slug: "kawalerki-na-sprzedaz/"};
        case SiteMapSection.ApartmentRentOffers:
            return {title: "Mieszkania do wynajęcia", slug: "mieszkania-do-wynajecia/"};
        case SiteMapSection.HouseRentOffers:
            return {title: "Domy do wynajęcia", slug: "domy-do-wynajecia/"};
        case SiteMapSection.ApartmentOneRoomRentOffers:
            return {title: "Kawalerki do wynajęcia", slug: "kawalerki-do-wynajecia/"};
        case SiteMapSection.Developers:
            return {title: "Deweloperzy Gethome", slug: "deweloperzy/"};
        case SiteMapSection.Agencies:
            return {title: "Agencje Gethome", slug: "agencje/"};
        case SiteMapSection.InvestmentHouses:
            return {title: "Inwestycje domów", slug: "inwestycje-domow/"};
        case SiteMapSection.InvestmentApartments:
            return {title: "Inwestycje mieszkań", slug: "inwestycje-mieszkan/"};
        case SiteMapSection.Articles:
            return {title: "Artykuły", slug: "artykuly/"};
        case SiteMapSection.NewestOffers:
            return {title: "Najnowsze oferty", slug: "oferty/"};
        default:
            return {title: "", slug: ""};
    }
};

export const getSectionFromSlug = (slug: string): SiteMapSection | null => {
    const sections = Object.values(SiteMapSection);
    for (const section of sections) {
        const sectionData = getSiteMapSectionData(section);
        if (sectionData.slug.replace(/\/$/, "") === slug) {
            return section;
        }
    }
    return null;
};

export const getSiteMapTitle = (siteMapSection: string, title: string): string => {
    const validSections = [
        SiteMapSection.Developers,
        SiteMapSection.Agencies,
        SiteMapSection.Articles,
        SiteMapSection.NewestOffers,
        SiteMapSection.InvestmentApartments,
        SiteMapSection.InvestmentHouses
    ];

    if (validSections.includes(siteMapSection as SiteMapSection)) {
        return title;
    }

    const sectionData = getSiteMapSectionData(siteMapSection as SiteMapSection);
    return `${sectionData.title} ${title}`;
};
