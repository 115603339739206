import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    secondaryFillColor?: string;
    size?: string;
}

export const AtEmailIcon = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <SvgIcon size={props.size || "1"} className={className} fillColor={props.fillColor}>
            <path d="M9.46,2.16A3.4,3.4,0,0,0,7.91.79,5.52,5.52,0,0,0,5.51.31a6.16,6.16,0,0,0-2.8.63A4.79,4.79,0,0,0,0,5.37,4.64,4.64,0,0,0,.54,7.68,3.63,3.63,0,0,0,2.13,9.16a5.84,5.84,0,0,0,2.53.53l.23-1a3.91,3.91,0,0,1-2.75-.87A3.17,3.17,0,0,1,1.2,5.37,4.42,4.42,0,0,1,1.68,3.3,3.48,3.48,0,0,1,3.1,1.85a4.35,4.35,0,0,1,2.22-.54,4.21,4.21,0,0,1,1.87.38A2.68,2.68,0,0,1,8.35,2.76a3.09,3.09,0,0,1,.4,1.6,3.16,3.16,0,0,1-.17,1.09,1.63,1.63,0,0,1-.43.68.89.89,0,0,1-.58.23.45.45,0,0,1-.33-.11.43.43,0,0,1-.11-.35,1,1,0,0,1,0-.21l.14-.94c0-.14,0-.26,0-.39A1.79,1.79,0,0,0,6.75,3a2.2,2.2,0,0,0-1.52-.51,2.82,2.82,0,0,0-1.35.34,2.65,2.65,0,0,0-1,.93,2.82,2.82,0,0,0-.42,1.35,2.1,2.1,0,0,0,0,.28A2.45,2.45,0,0,0,2.7,6.45a1.75,1.75,0,0,0,.65.72,1.73,1.73,0,0,0,1,.26,1.84,1.84,0,0,0,1.07-.29,2.19,2.19,0,0,0,.74-.82,1.11,1.11,0,0,0,.42.84,1.55,1.55,0,0,0,1,.27A2.14,2.14,0,0,0,8.73,7,2.84,2.84,0,0,0,9.65,5.9,3.59,3.59,0,0,0,10,4.32,4.15,4.15,0,0,0,9.46,2.16ZM5.92,5.59a1.52,1.52,0,0,1-.52.57,1.25,1.25,0,0,1-.67.2A1,1,0,0,1,4,6.05a1.17,1.17,0,0,1-.3-.86,2,2,0,0,1,.18-.89,1.4,1.4,0,0,1,.5-.57A1.25,1.25,0,0,1,5,3.54a1,1,0,0,1,.79.29,1.1,1.1,0,0,1,.3.84A2,2,0,0,1,5.92,5.59Z" />
        </SvgIcon>
    );
};
