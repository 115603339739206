import * as React from "react";
import {css, Theme} from "@emotion/react";

interface IProps {
    title: string;
}

export const ArticleSectionHeader = (props: IProps) => {
    return (
        <header css={sectionTitleHolder}>
            <h2 css={sectionTitle}>{props.title}</h2>

            <hr css={sectionTitleDivider} />
        </header>
    );
};

const sectionTitleHolder = (theme: Theme) => css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    cursor: pointer;

    @media screen and (max-width: calc(${theme.breakpoints.screen_md} - 1px)) {
        h2 {
            white-space: initial;
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 35px;
            text-align: center;
        }

        hr {
            display: none;
        }
    }

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        h2 {
            white-space: nowrap;
        }
    }
`;

const sectionTitle = (theme: Theme) => css`
    flex-grow: 1;
    margin-right: 2.5rem;
    color: ${theme.colors.brand_primary};
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
    white-space: nowrap;
    pointer-events: none;
    cursor: pointer;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 2rem;
    }
`;

const sectionTitleDivider = (theme: Theme) => css`
    display: block;
    width: 100%;
    height: 1px;
    margin: 15px 0;
    border: 0;
    background-color: ${theme.colors.gray_light};
`;
