import {AboutUsDynamic} from "../src/about_us/components/AboutUsDynamic";
import {AgencyRegisterDynamic} from "../src/agency_register/components/AgencyRegisterDynamic";
import {AgencyRegisterPremiumDynamic} from "../src/agency_register/components/AgencyRegisterPremiumDynamic";
import {ArticleListDynamic} from "../src/article/ArticleListDynamic";
import {AuthorDetailDynamic} from "../src/author/AuthorDetailDynamic";
import {AuthorListDynamic} from "../src/author/AuthorListDynamic";
import {BuylandoDynamic} from "../src/buylando/components/BuylandoDynamic";
import {FavouriteOffersDynamic} from "../src/favourites/components/FavouriteOffersDynamic";
import {ForAgentDynamic} from "../src/for_agent/components/ForAgentDynamic";
import {HomepageDynamic} from "../src/homepage/HomepageDynamic";
import {SiteMapDynamic} from "../src/site_map/components/SiteMapDynamic";
import {prepareHydratedApp} from "./utils/prepare_hydrate_app";

const config = [
    {reactDynamic: "about-us-dynamic", view: <AboutUsDynamic />},
    {reactDynamic: "author-list", view: <AuthorListDynamic />},
    {reactDynamic: "author-detail", view: <AuthorDetailDynamic />},
    {reactDynamic: "agency-register-dynamic", view: <AgencyRegisterDynamic />},
    {reactDynamic: "agency-register-premium-dynamic", view: <AgencyRegisterPremiumDynamic />},
    {reactDynamic: "article-list", view: <ArticleListDynamic />},
    {reactDynamic: "favourite-offers", view: <FavouriteOffersDynamic />},
    {reactDynamic: "for-agent-dynamic", view: <ForAgentDynamic />},
    {reactDynamic: "homepage", view: <HomepageDynamic />},
    {reactDynamic: "buylando-dynamic", view: <BuylandoDynamic />},
    {reactDynamic: "site-map", view: <SiteMapDynamic />}
];

(() => prepareHydratedApp(config))();
