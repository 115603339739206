import * as React from "react";
import {useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {ArrowWithOrientationIcon} from "@web2/icons";

interface Props {
    className?: string;
    onClick?(): void;
    orientation: "right" | "left";
}

export const SliderArrow = (props: Props) => {
    const {onClick, orientation} = props;
    const theme = useTheme();

    return (
        <SlideArrowIcon className={classNames(props.className, orientation)} onClick={onClick}>
            <ArrowWithOrientationIcon orientation={orientation} fill={theme.colors.gray_darker} />
        </SlideArrowIcon>
    );
};

/*
Styles
 */

export const SlideArrowIcon = styled("div")`
    width: 40px;
    height: 40px;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);

    &::after {
        width: 40px;
        height: 40px;
        content: "";
        position: absolute;
        right: 0;
    }

    &.right {
        right: 0;
        left: auto;
        padding-left: 0;

        &:after {
            right: auto;
            left: 0;
        }
    }

    &.left {
        left: 0;
        right: auto;
        padding-left: 0;

        &:after {
            left: auto;
            right: 0;
        }
    }
`;
