import {OfferDealType} from "../../../offer/utils/constants_offer";

export enum GTMMarketType {
    PRIMARY_MARKET = "pierwotny",
    AFTERMARKET = "wtórny",
    PRIMARY_AND_AFTER_MARKET = "wtórny i pierwotny"
}

export const getSearchResultsMarketType = (searchMarketType: string[], dealType: OfferDealType) => {
    const hasPrimaryMarketOffers = searchMarketType.some((offer) => offer.match("primary_market"));
    const hasAfterMarketOffers = searchMarketType.some((offer) => offer.match("aftermarket"));

    switch (true) {
        case dealType === "rent":
            return GTMMarketType.AFTERMARKET;
        case hasPrimaryMarketOffers && hasAfterMarketOffers:
        case searchMarketType.length === 0:
            return GTMMarketType.PRIMARY_AND_AFTER_MARKET;
        case hasPrimaryMarketOffers:
            return GTMMarketType.PRIMARY_MARKET;
        case hasAfterMarketOffers:
            return GTMMarketType.AFTERMARKET;
        default:
            return null;
    }

    // note: we also have market type "lot"
};

export const getGtmMarketType = (marketType: "primary_market" | "aftermarket" | "lot_market") => {
    switch (marketType) {
        case "primary_market":
            return GTMMarketType.PRIMARY_MARKET;
        case "aftermarket":
            return GTMMarketType.AFTERMARKET;
        default:
            return null;
    }
};
