import React from "react";
import {connect} from "react-redux";
import {css, Theme} from "@emotion/react";
import {Breadcrumbs} from "@web2/breadcrumbs";
import {appLink} from "@web2/gh_routes";

import {useRouteHandler} from "../../client/utils/use_route_handler";
import {GridStatic} from "../app/atoms/Layout";
import {IAuthorListAuthor} from "../app/interfaces/response/author_list";
import {IStore} from "../app/reducers/hybrid_reducer";
import {RequestState} from "../app/utils/request_response_utils/factories/reduce_request_state";
import {Footer} from "../footer/Footer";
import {AuthorListList} from "./components/AuthorListList";

interface IStateProps {
    authorList: {
        count: number;
        next: number;
        page: number;
        page_size: number;
        previous: number;
        authors: IAuthorListAuthor[];
    };
    authorListRequestState: RequestState;
}
interface IProps extends IStateProps {}

export const AuthorListDynamic = connect(mapStateToProps)((props: IProps) => {
    useRouteHandler();
    const {authors} = props.authorList;
    const breadcrumb = [
        {
            label: `Blog`,
            link: appLink.article.base()
        },
        {
            label: `Autorzy`,
            link: appLink.article.author.base()
        }
    ];

    return (
        <>
            <Breadcrumbs breadcrumbs={breadcrumb} variant="simple" />

            <article css={authorListHolder}>
                <GridStatic width="1230px" as="section">
                    <h1 css={authorListHeading}>Autorzy artykułów na blogu</h1>

                    <AuthorListList authors={authors} />
                </GridStatic>
            </article>

            <Footer />
        </>
    );
});

function mapStateToProps(state: IStore): IStateProps {
    return {
        authorList: state.authors.authorList,
        authorListRequestState: state.authors.authorListRequestState
    };
}

const authorListHeading = (theme: Theme) => css`
    margin-top: 0;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 3rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 3.4rem;
    }
`;

const authorListHolder = (theme: Theme) => css`
    background: ${theme.colors.gray_bright};
    padding: 2rem 0 3rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 3rem 0;
    }
`;
