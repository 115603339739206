import * as React from "react";
import {useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";
import {Modal} from "@web2/modal2";

import {GreenDarkButton} from "../../app/atoms/Buttons";
import {AboutUsModalGallery, thumbGallery} from "../constants/about_us_data";
import {ModalGalleryContent} from "./ModalGallery";

export const AboutUsGallerySection = () => {
    const [isGalleryModalOpen, setGalleryModalOpen] = useState(false);
    const [openedGalleryImage, setOpenedGalleryImage] = useState(0);

    const openGalleryOnClickedImage = (index: number) => {
        setOpenedGalleryImage(index);
        return setGalleryModalOpen(true);
    };

    const modalContentConfig = {
        overflow: "visible",
        outline: "none",
        padding: 0,
        borderRadius: 0,
        margin: "0 auto",
        width: "1000px",
        height: "auto",
        backgroundColor: "transparent"
    };

    return (
        <Wrapper>
            <UpperBoxContainer>
                <UpperBox className="gallery-description">
                    <h3>Zobacz gdzie pracujemy</h3>
                    <p>
                        Biuro, w którym każdego dnia się pojawiamy jest naszą najlepszą wizytówką! Zlokalizowane w zielonej części Warszawy przy Metrze
                        Wierzbno. Nowoczesne, przestronne, zapewnia najwyższy komfort pracy.
                    </p>

                    <GreenDarkButton variant="success" onClick={() => openGalleryOnClickedImage(0)}>
                        Zobacz całą galerię
                    </GreenDarkButton>
                </UpperBox>

                {map(thumbGallery.slice(0, 3), (image, index) => {
                    return (
                        <PhotoBox
                            key={image.alt}
                            src={image.source}
                            alt={image.alt}
                            className="upper"
                            onClick={() => openGalleryOnClickedImage(index)}
                            loading="lazy"
                        />
                    );
                })}
            </UpperBoxContainer>

            <LowerBoxContainer>
                {map(thumbGallery.slice(3, 11), (image, index) => {
                    return <PhotoBox key={image.alt} src={image.source} alt={image.alt} onClick={() => openGalleryOnClickedImage(index + 3)} loading="lazy" />;
                })}
            </LowerBoxContainer>

            <Modal
                contentStyles={modalContentConfig}
                modalState={isGalleryModalOpen}
                onModalClose={() => setGalleryModalOpen(false)}
                type="window"
                css={aboutUsGallery}
            >
                <ModalGalleryContent images={AboutUsModalGallery} openedImage={openedGalleryImage} />
            </Modal>
        </Wrapper>
    );
};

/*
Styles
 */

const Wrapper = styled.section`
    display: none;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        display: block;
    }
`;

const UpperBoxContainer = styled.div`
    display: flex;
    overflow: hidden;
`;

const UpperBox = styled.div`
    width: 25%;

    &.gallery-description {
        background-color: #333;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 1.5rem;

        h3 {
            font-size: 1.8rem;
            margin-bottom: 2rem;
            font-weight: 300;
            text-align: center;

            @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
                font-size: 2.4rem;
            }
        }

        p {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-weight: 300;
            text-align: center;

            @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
                font-size: 1.4rem;
                margin-bottom: 2rem;
            }
        }
    }
`;

export const PhotoBox = styled("img")`
    height: auto;
    width: 12.5%;
    cursor: pointer;
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;

    &.upper {
        width: 25%;
    }

    &:hover {
        filter: grayscale(0%);
    }
`;

const LowerBoxContainer = styled.div`
    display: flex;
    overflow: hidden;
`;

const aboutUsGallery = (theme: Theme) => css`
    .modal-close-icon {
        fill: ${theme.colors.gray_dark};
    }
`;
