import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";
import {useMounted} from "@web2/react_utils";
import {SliderGallery} from "@web2/slider_gallery";
import {BounceLoader, CenterBox} from "@web2/ui_utils";

import {FlexColumn} from "../../app/atoms/Flex";
import {imageStyles} from "../../styles/mixins";
import {aboutUsBoxes} from "../constants/about_us_data";
import {SliderArrow} from "./slider/SliderArrow";

import quote from "../../styles/assets/svg/quote_dark.svg";

export const WroteAboutUsBoxes = () => {
    const isMounted = useMounted();
    if (!isMounted) {
        return (
            <Wrapper>
                <FallbackLoader />
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            <SliderGallery
                arrows={{
                    left: (
                        <div css={leftArrow}>
                            <SliderArrow orientation="left" css={leftArrow} />
                        </div>
                    ),
                    right: (
                        <div css={rightArrow}>
                            <SliderArrow orientation="right" css={rightArrow} />
                        </div>
                    )
                }}
                slides={map(aboutUsBoxes, (item, idx) => (
                    <div css={articleHolder} key={idx}>
                        <InnerWrapper href={item.link} target="_blank" rel="nofollow">
                            <ImageQuote src={quote} height={43} width={50} alt={"quote-icon"} />
                            <Paragraph>{item.text}</Paragraph>
                            <LogoImage src={item.image} height={35} width={240} alt={item.alt} />
                        </InnerWrapper>
                    </div>
                ))}
                disableKeyboardEvents
                clonedSlidesCount={3}
                breakpoints={[
                    {
                        breakpoint: 1024,
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                ]}
            />
        </Wrapper>
    );
};

const FallbackLoader = () => {
    return (
        <CenterBox css={centerBoxStyles}>
            <BounceLoader color="#9069c0" />
        </CenterBox>
    );
};

/*
Styles
 */

const centerBoxStyles = () => css`
    height: 100%;
`;

const Wrapper = styled(FlexColumn)`
    margin-top: 2rem;
    position: relative;
    padding: 0 4rem;
    width: 100%;
    min-height: 450px;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin-top: 0;
        font-size: 2.8rem;
        flex-direction: row;
        min-height: 350px;
        justify-content: center;
        align-items: center;
    }
`;

const InnerWrapper = styled(FlexColumn)`
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 1rem;
    background-color: #ffffff;
    margin-bottom: 2.5rem;
    position: relative;
    padding: 5rem 2.5rem 2rem;
    text-decoration: none;
    justify-content: space-between;

    &:hover {
        text-decoration: none;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: 300px;
        padding: 5rem 1.5rem;
        max-width: 500px;

        &:nth-of-type(2) {
            margin: 0 4.5rem;
        }
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_lg}) {
        padding: 4.5rem 3.5rem 3.5rem;
    }
`.withComponent("a");

const ImageQuote = styled.img`
    position: absolute;
    z-index: 0;
    top: -13px;
    left: 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: 60px;
        width: 70px;
        top: -19px;
        left: -30px;
    }
`;

const LogoImage = styled.img`
    ${imageStyles};
    margin: 0 auto;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 55px;
`;

const Paragraph = styled.p`
    font-style: italic;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: ${(props) => props.theme.colors.text_color};
    margin-bottom: 2rem;
`;

const articleHolder = css`
    padding: 2rem 1.5rem 0 2.9rem;
`;

const leftArrow = css`
    margin-left: -4rem;
`;

const rightArrow = css`
    margin-right: -4rem;
`;
