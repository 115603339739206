import * as React from "react";
import {useEffect, useState} from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {SliderGallery} from "@web2/slider_gallery";
import {getClosestBreakpoint} from "@web2/slider_gallery/src/get_closest_breakpoint";
import {IBreakpointSettings} from "@web2/slider_gallery/src/SliderGallery";

import {LocationTypes} from "../../app/utils/get_location_from_path";
import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {UrlTagSlug} from "../../offer/list/url_utils/tags";
import {DealTypeSlug} from "../../offer/utils/constants_offer";
import {getThemeBreakpointCorrect, getThemeVariable} from "../../styles/linaria_variable_factory";
import {CityListFilterTabCities, IOfferTypeLinks, offerTypeLinks, regionsPrimaryMarket} from "../constatns/constants";
import {CitiesSlideArrow} from "./CitiesSlideArrow";
import {ShowMoreCities} from "./ShowMoreCities";

interface IProps {
    activeFilter: CityListFilterTabCities | null;
    onFilterChange: (filter: CityListFilterTabCities) => void;
}

const breakpoints = [
    {
        breakpoint: 1200,
        slidesToShow: 8,
        slidesToScroll: 8
    },
    {
        breakpoint: 1024,
        slidesToShow: 6,
        slidesToScroll: 6
    },
    {
        breakpoint: 1,
        slidesToShow: 2,
        slidesToScroll: 2
    }
];

const getActiveFilterSubLinksLength = (activeFilter: CityListFilterTabCities | null) => {
    const foundOffer = offerTypeLinks.find((offer) => offer.filter === activeFilter);
    if (foundOffer) {
        return foundOffer.subLinks.length;
    }
    return 0;
};

export const CitiesSectionTabs = (props: IProps) => {
    const {activeFilter} = props;

    const [breakpointSettings, setBreakpointSettings] = useState<IBreakpointSettings>({breakpoint: 1200, slidesToShow: 8, slidesToScroll: 8});
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        setBreakpointSettings(getClosestBreakpoint(breakpoints, windowWidth));
    }, []);

    const getPageCount = () => {
        let slides = breakpointSettings.slidesToShow;
        if (breakpointSettings.breakpoint === 0) {
            slides = 8;
        }

        const length = getActiveFilterSubLinksLength(activeFilter);
        if (length > 0) {
            return Math.ceil(length / slides);
        }
        return "";
    };

    const getLink = (element: IOfferTypeLinks, location: string) => {
        return getOfferListUrl({
            offerType: element.offerType,
            type: element.type,
            location: location,
            ...(element.tag ? {tag: element.tag} : {})
        });
    };

    const onSlideChange = (currentIndex: number, nextIndex: number): void => {
        const slides = breakpointSettings.slidesToShow;

        const nextPage = Math.floor(nextIndex / slides) + 1;
        setCurrentPage(nextPage);
    };

    const onFilterChange = (filter: CityListFilterTabCities) => {
        props.onFilterChange(filter);
        setCurrentPage(1);
    };

    return (
        <>
            <ul className={listMain}>
                {offerTypeLinks.map((elem, i) => {
                    const isActive = activeFilter === elem.filter;
                    return (
                        <li key={i} className={cx(isActive && "active", listItem)}>
                            <div
                                onClick={() => {
                                    onFilterChange(elem.filter);
                                }}
                                className={cx(listItemContainer, listItemContainerDesktop)}
                            >
                                <span className={listItemLink}>
                                    <span>{elem.filter}</span>
                                </span>
                                <ShowMoreCities active={isActive} />
                            </div>
                        </li>
                    );
                })}
                <li className={cx(activeFilter === CityListFilterTabCities.PRIMARY_MARKET && "active", listItem)}>
                    <div
                        onClick={() => {
                            onFilterChange(CityListFilterTabCities.PRIMARY_MARKET);
                        }}
                        className={cx(listItemContainer, listItemContainerDesktop)}
                    >
                        <span className={listItemLink}>{CityListFilterTabCities.PRIMARY_MARKET}</span>

                        <ShowMoreCities active={activeFilter === CityListFilterTabCities.PRIMARY_MARKET} />
                    </div>
                </li>
            </ul>

            <ul className={cx(activeFilter && "active", list)}>
                {offerTypeLinks.map((elem, i) => {
                    const isActive = activeFilter === elem.filter;
                    return (
                        <li key={i} className={cx(isActive && "active", listItem)}>
                            {
                                <div
                                    className={cx(listItemContainer, listItemContainerMobile)}
                                    onClick={() => {
                                        onFilterChange(elem.filter);
                                    }}
                                >
                                    <span className={listItemLink}>{elem.filter}</span>
                                    <ShowMoreCities active={isActive} />
                                </div>
                            }
                            <ul className={secondLevelList}>
                                {isActive ? (
                                    <SliderGallery
                                        onSlideChange={onSlideChange}
                                        getBreakpointSettings={setBreakpointSettings}
                                        clonedSlidesCount={0}
                                        breakpoints={breakpoints}
                                        arrows={{
                                            left: (
                                                <>
                                                    <CitiesSlideArrow orientation="left" />
                                                    <div className={paginationTotal}>
                                                        {currentPage}/{getPageCount()}
                                                    </div>
                                                </>
                                            ),
                                            right: <CitiesSlideArrow orientation="right" />
                                        }}
                                        slides={elem.subLinks.map((el, i) => (
                                            <SecondLevelListItem key={i}>
                                                <a
                                                    className={secondLevelListItemLink}
                                                    href={getLink(elem, el.slug)}
                                                    title={`${elem.title} ${el.name} ${elem.subTitle ? elem.subTitle : ""}`}
                                                >
                                                    {el.name}
                                                </a>

                                                <ThirdLevelList>
                                                    {el.cities.map((e, i) => (
                                                        <li key={i} className={thirdLevelListItem}>
                                                            <a
                                                                href={getLink(elem, e.slug)}
                                                                title={`${elem.title} ${e.name} ${elem.subTitle ? elem.subTitle : ""}`}
                                                                className={thirdLevelListItemLink}
                                                            >
                                                                {e.name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ThirdLevelList>
                                            </SecondLevelListItem>
                                        ))}
                                        singleSlideStyle={{alignItems: "baseline"}}
                                    />
                                ) : (
                                    elem.subLinks.map((el, i) => (
                                        <SecondLevelListItem key={i}>
                                            <a
                                                className={secondLevelListItemLink}
                                                href={getLink(elem, el.slug)}
                                                title={`${elem.title} ${el.name} ${elem.subTitle ? elem.subTitle : ""}`}
                                            >
                                                {el.name}
                                            </a>

                                            <ThirdLevelList>
                                                {el.cities.map((e, i) => (
                                                    <li key={i} className={thirdLevelListItem}>
                                                        <a
                                                            href={getLink(elem, e.slug)}
                                                            title={`${elem.title} ${e.name} ${elem.subTitle ? elem.subTitle : ""}`}
                                                            className={thirdLevelListItemLink}
                                                        >
                                                            {e.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ThirdLevelList>
                                        </SecondLevelListItem>
                                    ))
                                )}
                            </ul>
                        </li>
                    );
                })}

                <li className={cx(activeFilter === CityListFilterTabCities.PRIMARY_MARKET && "active", listItem)}>
                    <div
                        onClick={() => {
                            onFilterChange(CityListFilterTabCities.PRIMARY_MARKET);
                        }}
                        className={cx(listItemContainer, listItemContainerMobile)}
                    >
                        <span className={listItemLink}>{CityListFilterTabCities.PRIMARY_MARKET}</span>

                        <ShowMoreCities active={activeFilter === CityListFilterTabCities.PRIMARY_MARKET} />
                    </div>

                    <ul className={secondLevelList}>
                        {regionsPrimaryMarket.map((el, i) => (
                            <SecondLevelListItem key={i}>
                                <a
                                    className={secondLevelListItemLink}
                                    href={getOfferListUrl({
                                        offerType: el.offerType,
                                        type: DealTypeSlug.SELL,
                                        tag: UrlTagSlug.PRIMARY_MARKET
                                    })}
                                    title={el.name}
                                >
                                    {el.name}
                                </a>

                                <ThirdLevelList isWrapped dir="row">
                                    {el.cities.map((e, i) => (
                                        <li key={i} className={thirdLevelListItem}>
                                            <a
                                                href={getOfferListUrl(
                                                    {
                                                        offerType: el.offerType,
                                                        type: DealTypeSlug.SELL,
                                                        tag: UrlTagSlug.PRIMARY_MARKET,
                                                        location: e.slug
                                                    },
                                                    LocationTypes.CITY
                                                )}
                                                title={e.name}
                                                className={thirdLevelListItemLink}
                                            >
                                                {e.name}
                                            </a>
                                        </li>
                                    ))}
                                </ThirdLevelList>
                            </SecondLevelListItem>
                        ))}
                    </ul>
                </li>
            </ul>
        </>
    );
};

const listMain = css`
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        row-gap: 1rem;
        flex-direction: row;
        column-gap: 1rem;
    }
`;
const list = css`
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    row-gap: 1rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_xl}) {
        max-width: 1650px;
        width: 1650px;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        width: 1235px;
        row-gap: 0;

        &.active {
            margin: 3rem auto 0;
        }
    }
`;

const listItemContainer = css`
    display: flex;
    align-items: center;
    border: 1px solid ${getThemeVariable("colors-gray_very_bright")};
    align-self: stretch;
    background: #fff;
    font-size: 1.5rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        align-items: baseline;
        flex-direction: column;
        padding-bottom: 0.4rem;
    }

    &:hover {
        cursor: pointer;
    }
`;

const listItemContainerMobile = css`
    display: flex;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: none;
    }
`;

const listItemContainerDesktop = css`
    display: none;

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        display: flex;
    }
`;

const secondLevelList = css`
    display: none;
    list-style: none;
    padding: 0 0 5rem 0;
    margin: 0;
    flex-direction: row;
`;

const listItem = css`
    &.active {
        .${listItemContainer} {
            border-color: ${getThemeVariable("colors-brand_primary")};
            background: rgba(144, 105, 192, 0.15);
        }

        .${secondLevelList} {
            display: flex;
        }
    }
`;

const linkItemBox = {
    padding: "0.5rem 1rem",
    textDecoration: "none",
    display: "flex",
    alignItems: "center"
};

const listItemLink = css`
    ${linkItemBox};
    height: 5rem;
    flex-grow: 1;
    flex-shrink: 1;

    &:is(a, :active, :hover, :focus) {
        color: ${getThemeVariable("colors-text_color")};
        text-decoration: none;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        height: auto;
    }
`;

const SecondLevelListItem = styled.li`
    white-space: nowrap;
    margin-top: 1rem;
    width: fit-content;
`;

const secondLevelListItemLink = css`
    ${linkItemBox};
    height: 3rem;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.5rem 1rem 3rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        padding: 0.5rem 0 3rem;
    }

    &:is(a, :active, :hover, :focus) {
        color: ${getThemeVariable("colors-text_color")};
        text-decoration: none;
    }
`;
interface IThirdLevelListProps {
    isWrapped?: boolean;
    dir?: "column" | "row";
}
const ThirdLevelList = styled.ul<IThirdLevelListProps>`
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: ${({dir}) => `${dir ? dir : "column"}`};
    row-gap: 1rem;
    margin: 0;
    flex-wrap: ${({isWrapped}) => `${isWrapped ? "wrap" : "no-wrap"}`};
`;

const thirdLevelListItem = css`
    flex: 0 0 100%;

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        flex: 0 0 50%;
    }
    @media (min-width: ${getThemeBreakpointCorrect().screen_lg}) {
        flex: 0 0 30%;
    }
`;

const thirdLevelListItemLink = css`
    ${linkItemBox};
    height: 3rem;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;

    @media (min-width: ${getThemeBreakpointCorrect().screen_sm}) {
        padding: 0.5rem 0;
    }

    &:is(a, :active, :hover, :focus) {
        color: ${getThemeVariable("colors-text_color")};
        text-decoration: none;
    }
`;

const paginationTotal = css`
    position: absolute;
    left: 49%;
    bottom: -4.4rem;
    font-weight: bold;
`;
