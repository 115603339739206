import * as React from "react";
import {ChangeEvent} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {Property} from "csstype";
import {FormErrorMessage} from "@web2/form_fields";

import {TextLimitCounter} from "./TextLimitCounter";

export interface ITextareaProps<T> {
    label?: string;
    labelClass?: string;
    className?: string;
    groupClassName?: string;
    maxLength?: number;
    onClick?: () => void;
    id?: string;
    placeholder?: string;
    rows?: number;
    style?: React.CSSProperties;
    errorOnBottom?: boolean;
    errorClassName?: string;
    isHorizontal?: boolean;
    required?: boolean;

    error?: string[] | null;
    name: string;
    onChange: (name: string, value: T) => void;
    value: T;
    resize?: Property.Resize;
    textareaFieldWrapperStyle?: React.CSSProperties;
}

export const Textarea: React.FC<ITextareaProps<string>> = (props) => {
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = (e.target as HTMLTextAreaElement).value;
        props.onChange(props.name, value);
    };

    const hasError = props.error;
    const groupClassName = classNames(props.groupClassName, hasError);
    const className = classNames(props.className);

    return (
        <InputWrapper isHorizontal={props.isHorizontal} error={props.error} errorOnBottom={props.errorOnBottom} className={groupClassName}>
            {props.label && (
                <Label className={props.labelClass} data-testid={`textarea-label-${props.name}`} htmlFor={props.id}>
                    {props.label}
                    {props.required && (
                        <span css={required} data-testid={`textarea-required-asterisk-${props.name}`}>
                            *
                        </span>
                    )}
                </Label>
            )}

            {props.error && !props.errorOnBottom && <FormErrorMessage className={props.errorClassName} error={props.error} />}

            <TextareaFieldWrapper rows={props.rows} style={props.textareaFieldWrapperStyle}>
                <TextareaField
                    required={props.required}
                    name={props.name}
                    value={props.value}
                    onChange={onChange}
                    className={className}
                    maxLength={props.maxLength}
                    id={props.id}
                    data-testid={`textarea-${props.name}`}
                    placeholder={props.placeholder}
                    style={props.style}
                    rows={props.rows}
                    resize={props.resize}
                />
            </TextareaFieldWrapper>

            {props.error && props.errorOnBottom && <FormErrorMessage className={props.errorClassName} error={props.error} />}
            {props.maxLength ? <TextLimitCounter value={props.value} maxLength={props.maxLength} /> : null}
        </InputWrapper>
    );
};

interface ITextareaFieldThemeProps {
    rows?: number;
    resize?: Property.Resize;
}

interface IInputWrapperThemeProps {
    isHorizontal?: boolean;
    error?: string[] | null;
    errorOnBottom?: boolean;
}

interface ILabelThemeProps {}

const TextareaField = styled.textarea<ITextareaFieldThemeProps>`
    display: block;
    width: 100%;
    resize: ${(props) => props.resize ?? "none"};
    ${(props) => !props.rows && `height: ${props.theme?.forms?.textarea_height ?? "8rem"};`};
    padding: ${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"} ${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"}
        ${(props) => props.theme?.padding?.padding_base_horizontal ?? "1.8rem"};
    background-image: none;
    border: 1px solid ${(props) => props.theme?.forms?.input_border ?? "#909090"};
    border-radius: ${(props) => props.theme?.forms?.input_border_radius ?? ".4rem"};
    background-color: ${(props) => props.theme?.forms?.input_bg ?? "#fff"};
    outline: 0;
    color: ${(props) => props.theme?.forms?.input_color ?? "#333"};
    font-size: ${(props) => props.theme?.forms?.input_font_size_base ?? "1.2rem"};
    font-weight: ${(props) => props.theme?.forms?.input_font_weight ?? 400};
    line-height: ${(props) => props.theme?.fonts?.line_height_base ?? 1.428571429};
    transition: ${(props) => props.theme?.forms?.input_transition ?? "border-color .2s ease-in-out, box-shadow .2s ease-in-out"};

    @media (max-width: ${(props) => props.theme?.breakpoints?.screen_md ?? "1024px"}) {
        // iOS font size zoom prevent hack
        //Base multiply value + 33.34%;
        font-size: 1.6rem;
        transform-origin: top left;
        transform: scale(0.75); //  12px to 16px

        //  size up
        width: calc(100% * 1.3334);
        ${(props) => !props.rows && `height: calc(${props.theme?.forms?.textarea_height ?? "8rem"} * 1.3334)`};

        padding: calc(${(props) => props.theme?.padding?.padding_base_vertical ?? ".9rem"} * 1.3334)
            calc(${(props) => props.theme?.padding?.padding_base_vertical} * 1.3334)
            calc(${(props) => props.theme?.padding?.padding_base_horizontal ?? ".9rem"} * 1.3334);
        border-radius: calc(${(props) => props.theme?.forms?.input_border_radius ?? ".4rem"} * 1.3334);
        border: calc(1px * 1.3334) solid ${(props) => props.theme?.forms?.input_border ?? "#909090"};
    }

    &:active,
    &:focus {
        border: 1px solid ${(props) => props.theme?.forms?.input_border_focus ?? "#90caf9"};
        box-shadow: ${(props) => props.theme?.forms?.input_box_shadow_focus ?? "inset 0 0 0 1px #90caf9"};
    }

    &::placeholder {
        color: ${(props) => props.theme?.forms?.input_color_placeholder ?? "#333"};
    }
`;

const InputWrapper = styled.div<IInputWrapperThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${(props) => props.theme?.forms?.form_group_margin_bottom ?? "2rem"};

    ${(props) =>
        props.error &&
        props.errorOnBottom &&
        css`
            margin-bottom: 0;
        `}

    ${(props) =>
        props.isHorizontal &&
        css`
            flex-direction: row;
            align-items: center;

            > label {
                flex-grow: 0;
                width: auto;
                margin-bottom: 0;
            }

            > div {
                flex-grow: 1;
            }
        `}

    ${(props) =>
        props.error &&
        css`
            ${TextareaField} {
                border-color: ${props.theme?.forms?.error_message_color ?? "#ff5a5f"};
                box-shadow: ${props.theme?.forms?.error_message_input_box_shadow ?? "inset 0 0 0 1px #ff5a5f"};

                &:active,
                &:focus {
                    border-color: ${props.theme?.forms?.error_message_color ?? "#ff5a5f"};
                }
        `}
`;

const Label = styled.label<ILabelThemeProps>`
    text-align: left;
    font-size: ${(props) => props.theme?.forms?.label_font_size ?? "1.2rem"};
    width: 100%;
    margin-bottom: ${(props) => props.theme?.forms?.label_vertical_margin ?? ".5rem"};
    margin-right: ${(props) => props.theme?.forms?.label_horizontal_margin ?? "2rem"};
    font-weight: ${(props) => props.theme?.forms?.label_font_weight ?? 500};
    color: ${(props) => props.theme?.forms?.label_color ?? "#909090"};
    line-height: ${(props) => props.theme?.fonts?.line_height_base ?? 1.428571429};
`;

interface ITextareaFieldWrapperThemeProps {
    rows?: number;
    textareaFieldWrapperStyle?: React.CSSProperties;
}

const TextareaFieldWrapper = styled.div<ITextareaFieldWrapperThemeProps>`
    position: relative;

    ${(props) =>
        !props.textareaFieldWrapperStyle &&
        css`
        @media (max-width: ${props.theme?.breakpoints?.screen_md ?? "1024px"}) {
            ${props.rows && `height: ${props.theme?.forms?.textarea_height ?? "8rem"};`};
        
    `};

    textarea::-webkit-inner-spin-button,
    textarea::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const required = (theme: Theme) => css`
    color: ${theme.colors?.brand_danger || "inherit"};
`;
