import {getOfferListUrl} from "../../offer/list/url_utils/get_offer_list_url";
import {UrlTagSlug} from "../../offer/list/url_utils/tags";
import {DealTypeSlug, OfferType} from "../../offer/utils/constants_offer";

// cities section
import gdansk from "../../styles/assets/images/homepage/districts_section/gdansk-panorama-miasta.jpg";
import katowice from "../../styles/assets/images/homepage/districts_section/katowice-panorama-miasta.jpg";
import krakow from "../../styles/assets/images/homepage/districts_section/krakow-panorama-miasta.jpg";
import lodz from "../../styles/assets/images/homepage/districts_section/lodz-panorama-miasta.jpg";
import poznan from "../../styles/assets/images/homepage/districts_section/poznan-panorama-miasta.jpg";
import szczecin from "../../styles/assets/images/homepage/districts_section/szczecin-panorama-miasta.jpg";
// districts section
import warszawa from "../../styles/assets/images/homepage/districts_section/warsaw_district.jpg";
import wroclaw from "../../styles/assets/images/homepage/districts_section/wroclaw-panorama-miasta.jpg";

export enum CityListFilterTabCities {
    FLATS_FOR_SALE = "Mieszkania sprzedaż",
    FLATS_FOR_RENT = "Mieszkania wynajem",
    HOUSES_FOR_SALE = "Domy sprzedaż",
    LOTS_FOR_SALE = "Działki sprzedaż",
    ONE_ROOM_FOR_SALE = "Kawalerki sprzedaż",
    ONE_ROOM_FOR_RENT = "Kawalerki wynajem",
    PRIMARY_MARKET = "Rynek pierwotny"
}

export interface IHomepageDistrict {
    name: string;
    slug: string;
}

export interface ICityDistrictProps {
    headline: string;
    imageSrc: string;
    popularDistricts: IHomepageDistrict[];
    trendingDistricts: IHomepageDistrict[];
}

export const cityDistrictsList: ICityDistrictProps[] = [
    {
        headline: "Warszawa",
        imageSrc: warszawa,
        popularDistricts: [
            {name: "Bemowo", slug: "warszawa/bemowo"},
            {name: "Białołęka", slug: "warszawa/bialoleka"},
            {name: "Bielany", slug: "warszawa/bielany"},
            {name: "Mokotów", slug: "warszawa/mokotow"},
            {name: "Ursus", slug: "warszawa/ursus"},
            {name: "Ursynów", slug: "warszawa/ursynow"},
            {name: "Wawer", slug: "warszawa/wawer"},
            {name: "Wilanów", slug: "warszawa/wilanow"},
            {name: "Wola", slug: "warszawa/wola"}
        ],
        trendingDistricts: [
            {name: "Śródmieście", slug: "warszawa/srodmiescie"},
            {name: "Żoliborz", slug: "warszawa/zoliborz"},
            {name: "Ochota", slug: "warszawa/ochota"},
            {name: "Praga-Północ", slug: "warszawa/praga-polnoc"},
            {name: "Praga-Południe", slug: "warszawa/praga-poludnie"},
            {name: "Rembertów", slug: "warszawa/rembertow"},
            {name: "Targówek", slug: "warszawa/targowek"},
            {name: "Włochy", slug: "warszawa/wlochy"},
            {name: "Wesoła", slug: "warszawa/wesola"}
        ]
    },
    {
        headline: "Kraków",
        imageSrc: krakow,
        popularDistricts: [
            {name: "Bieńczyce", slug: "krakow/bienczyce"},
            {name: "Bronowice", slug: "krakow/bronowice"},
            {name: "Czyżyny", slug: "krakow/czyzyny"},
            {name: "Dębniki", slug: "krakow/debniki"},
            {name: "Grzegórzki", slug: "krakow/grzegorzki"},
            {name: "Krowodrza", slug: "krakow/krowodrza"},
            {name: "Mistrzejowice", slug: "krakow/mistrzejowice"},
            {name: "Nowa Huta", slug: "krakow/nowa-huta"},
            {name: "Podgórze", slug: "krakow/podgorze"}
        ],
        trendingDistricts: [
            {name: "Podgórze Duchackie", slug: "krakow/podgorze-duchackie"},
            {name: "Prądnik Biały", slug: "krakow/pradnik-bialy"},
            {name: "Prądnik Czerwony", slug: "krakow/pradnik-czerwony"},
            {name: "Stare Miasto", slug: "krakow/stare-miasto"},
            {name: "Swoszowice", slug: "krakow/swoszowice"},
            {name: "Zwierzyniec", slug: "krakow/zwierzyniec"}
        ]
    },
    {
        headline: "Wrocław",
        imageSrc: wroclaw,
        popularDistricts: [
            {name: "Fabryczna", slug: "wroclaw/fabryczna"},
            {name: "Krzyki", slug: "wroclaw/krzyki"},
            {name: "Psie Pole", slug: "wroclaw/psie-pole"},
            {name: "Maślice", slug: "wroclaw/maslice"},
            {name: "Nowy Dwór", slug: "wroclaw/nowy-dwor"},
            {name: "Brochów", slug: "wroclaw/brochow"},
            {name: "Jagodno", slug: "wroclaw/jagodno"},
            {name: "Tarnogaj", slug: "wroclaw/tarnogaj"},
            {name: "Muchobór Wielki", slug: "wroclaw/muchobor-wielki"},
            {name: "Klecina", slug: "wroclaw/klecina"}
        ],
        trendingDistricts: [
            {name: "Śródmieście", slug: "wroclaw/srodmiescie"},
            {name: "Stare Miasto", slug: "wroclaw/stare-miasto"},
            {name: "Stabłowice", slug: "wroclaw/stablowice"},
            {name: "Gaj", slug: "wroclaw/gaj"},
            {name: "Oporów", slug: "wroclaw/oporow"},
            {name: "Różanka", slug: "wroclaw/rozanka"},
            {name: "Popowice", slug: "wroclaw/popowice"}
        ]
    },
    {
        headline: "Gdańsk",
        imageSrc: gdansk,
        popularDistricts: [
            {name: "Oliwa", slug: "gdansk/oliwa"},
            {name: "Brzeźno", slug: "gdansk/brzezno"},
            {name: "Osowa", slug: "gdansk/osowa"},
            {name: "Stogi", slug: "gdansk/stogi"},
            {name: "Śródmieście", slug: "gdansk/srodmiescie"},
            {name: "Jasień", slug: "gdansk/jasien"},
            {name: "Wrzeszcz", slug: "gdansk/wrzeszcz"},
            {name: "Matarnia", slug: "gdansk/matarnia"},
            {name: "Kokoszki", slug: "gdansk/kokoszki"}
        ],
        trendingDistricts: [
            {name: "Letnica", slug: "gdansk/letnica"},
            {name: "Wyspa Sobieszewska", slug: "gdansk/wyspa-sobieszewska"},
            {name: "Nowy Port", slug: "gdansk/nowy-port"},
            {name: "Olszynka", slug: "gdansk/olszynka"},
            {name: "Siedlce", slug: "gdansk/siedlce"},
            {name: "Aniołki", slug: "gdansk/aniolki"},
            {name: "Strzyża", slug: "gdansk/strzyza"},
            {name: "Przeróbka", slug: "gdansk/przerobka"}
        ]
    },
    {
        headline: "Łódź",
        imageSrc: lodz,
        popularDistricts: [
            {name: "Bałuty", slug: "lodz/baluty"},
            {name: "Górna", slug: "lodz/gorna"},
            {name: "Polesie", slug: "lodz/polesie"},
            {name: "Radogoszcz", slug: "lodz/radogoszcz"},
            {name: "Zarzew", slug: "lodz/zarzew"},
            {name: "Koziny", slug: "lodz/koziny"},
            {name: "Złotno", slug: "lodz/zlotno"},
            {name: "Olechów-Janów", slug: "lodz/olechow-janow"}
        ],
        trendingDistricts: [
            {name: "Śródmieście", slug: "lodz/srodmiescie"},
            {name: "Widzew", slug: "lodz/widzew/"},
            {name: "Stoki", slug: "lodz/stoki/"},
            {name: "Chojny", slug: "lodz/chojny/"},
            {name: "Rokicie", slug: "lodz/rokicie/"},
            {name: "Lublinek-Plenista", slug: "lodz/lublinek-pienista/"},
            {name: "Górniak", slug: "lodz/gorniak/"},
            {name: "Katedralna", slug: "lodz/katedralna/"}
        ]
    },
    {
        headline: "Poznań",
        imageSrc: poznan,
        popularDistricts: [
            {name: "Grunwald", slug: "poznan/grunwald"},
            {name: "Jeżyce", slug: "poznan/jezyce"},
            {name: "Nowe Miasto", slug: "poznan/nowe-miasto"},
            {name: "Rataje", slug: "poznan/rataje"},
            {name: "Piątkowo", slug: "poznan/piatkowo"},
            {name: "Naramowice", slug: "poznan/naramowice"},
            {name: "Podolany", slug: "poznan/podolany"},
            {name: "Górczyn", slug: "poznan/gorczyn"}
        ],
        trendingDistricts: [
            {name: "Stare Miasto", slug: "poznan/stare-miasto"},
            {name: "Wilda", slug: "poznan/wilda"},
            {name: "Strzeszyn", slug: "poznan/strzeszyn"},
            {name: "Łazarz", slug: "poznan/lazarz"},
            {name: "Ogrody", slug: "poznan/ogrody"},
            {name: "Junikowo", slug: "poznan/junikowo"},
            {name: "Ławica", slug: "poznan/lawica"},
            {name: "Starołęka", slug: "poznan/staroleka-minikowo-marlewo"}
        ]
    },
    {
        headline: "Katowice",
        imageSrc: katowice,
        popularDistricts: [
            {name: "Zespół Dzielnic Północnych", slug: "katowice/zespol-dzielnic-polnocnych"},
            {name: "Zespół Dzielnic Południowych", slug: "katowice/zespol-dzielnic-poludniowych"},
            {name: "Zespół Dzielnic Śródmiejskich", slug: "katowice/zespol-dzielnic-srodmiejskich"}
        ],
        trendingDistricts: [
            {name: "Zespół Dzielnic Wschodnich", slug: "katowice/zespol-dzielnic-wschodnich"},
            {name: "Zespół Dzielnic Zachodnich", slug: "katowice/zespol-dzielnic-zachodnich"}
        ]
    },
    {
        headline: "Szczecin",
        imageSrc: szczecin,
        popularDistricts: [
            {name: "Północ", slug: "szczecin/polnoc"},
            {name: "Prawobrzeże", slug: "szczecin/prawobrzeze"},
            {name: "Pogodno", slug: "szczecin/pogodno"},
            {name: "Centrum", slug: "szczecin/centrum"},
            {name: "Pomorzany", slug: "szczecin/pomorzany"},
            {name: "Dąbie", slug: "szczecin/dabie"},
            {name: "Gumieńce", slug: "szczecin/gumience"},
            {name: "Warszewo", slug: "szczecin/warszewo"}
        ],
        trendingDistricts: [
            {name: "Śródmieście", slug: "szczecin/srodmiescie"},
            {name: "Zachód", slug: "szczecin/zachod"},
            {name: "Słoneczne", slug: "szczecin/sloneczne"},
            {name: "Zawadzkiego-Klonowica", slug: "szczecin/zawadzkiego-klonowica"},
            {name: "Bukowe-Klęskowo", slug: "szczecin/bukowe-kleskowo"},
            {name: "Niebuszewo", slug: "szczecin/niebuszewo"}
        ]
    }
];

const regionsFlatsForSale = [
    {
        name: "Dolnośląskie",
        slug: "dolnoslaskie",
        cities: [
            {name: "Bolesławiec", slug: "boleslawiec"},
            {name: "Lubin", slug: "lubin"},
            {name: "Jelenia Góra", slug: "jelenia-gora"},
            {name: "Legnica", slug: "legnica"},
            {name: "Wałbrzych", slug: "walbrzych"},
            {name: "Wrocław", slug: "wroclaw"}
        ]
    },
    {
        name: "Kujawsko-pomorskie",
        slug: "kujawsko-pomorskie",
        cities: [
            {name: "Inowrocław", slug: "inowroclaw"},
            {name: "Grudziądz", slug: "grudziadz"},
            {name: "Włocławek", slug: "wloclawek"},
            {name: "Toruń", slug: "torun"},
            {name: "Bydgoszcz", slug: "bydgoszcz"}
        ]
    },
    {
        name: "Lubelskie",
        slug: "lubelskie",
        cities: [
            {name: "Puławy", slug: "pulawy"},
            {name: "Biała Podlaska", slug: "biala-podlaska"},
            {name: "Chełm", slug: "chelm"},
            {name: "Zamość", slug: "zamosc"},
            {name: "Lublin", slug: "lublin"}
        ]
    },
    {
        name: "Lubuskie",
        slug: "lubuskie",
        cities: [
            {name: "Zielona Góra", slug: "zielona-gora"},
            {name: "Gorzów Wielkopolski", slug: "gorzow-wielkopolski"},
            {name: "Nowa Sól", slug: "nowa-sol"}
        ]
    },
    {
        name: "Łódzkie",
        slug: "lodzkie",
        cities: [
            {name: "Skierniewice", slug: "skierniewice"},
            {name: "Bełchatów", slug: "belchatow"},
            {name: "Pabianice", slug: "pabianice"},
            {name: "Piotrków Trybunalski", slug: "piotrkow-trybunalski"},
            {name: "Łódź", slug: "lodz"}
        ]
    },
    {
        name: "Małopolskie",
        slug: "malopolskie",
        cities: [
            {name: "Oświęcim", slug: "oswiecim"},
            {name: "Nowy Sącz", slug: "nowy-sacz"},
            {name: "Kraków", slug: "krakow"}
        ]
    },
    {
        name: "Mazowieckie",
        slug: "mazowieckie",
        cities: [
            {name: "Ciechanów", slug: "ciechanow"},
            {name: "Piaseczno", slug: "piaseczno"},
            {name: "Siedlce", slug: "siedlce"},
            {name: "Płock", slug: "plock"},
            {name: "Radom", slug: "radom"},
            {name: "Warszawa", slug: "warszawa"}
        ]
    },
    {
        name: "Opolskie",
        slug: "opolskie",
        cities: [
            {name: "Nysa", slug: "nysa"},
            {name: "Opole", slug: "opole"}
        ]
    },
    {
        name: "Podkarpackie",
        slug: "podkarpackie",
        cities: [
            {name: "Przemyśl", slug: "przemysl"},
            {name: "Dębica", slug: "debica"},
            {name: "Rzeszów", slug: "rzeszow"}
        ]
    },
    {
        name: "Podlaskie",
        slug: "podlaskie",
        cities: [
            {name: "Łomża", slug: "lomza"},
            {name: "Suwałki", slug: "suwalki"},
            {name: "Białystok", slug: "bialystok"}
        ]
    },
    {
        name: "Pomorskie",
        slug: "pomorskie",
        cities: [
            {name: "Pruszcz Gdański", slug: "pruszcz-gdanski"},
            {name: "Kwidzyn", slug: "kwidzyn"},
            {name: "Tczew", slug: "tczew"},
            {name: "Słupsk", slug: "slupsk"},
            {name: "Gdynia", slug: "gdynia"},
            {name: "Gdańsk", slug: "gdansk"}
        ]
    },
    {
        name: "Śląskie",
        slug: "slaskie",
        cities: [
            {name: "Mysłowice", slug: "myslowice"},
            {name: "Jaworzno", slug: "jaworzno"},
            {name: "Dąbrowa Górnicza", slug: "dabrowa-gornicza"},
            {name: "Tychy", slug: "tychy"},
            {name: "Rybnik", slug: "rybnik"},
            {name: "Gliwice", slug: "gliwice"},
            {name: "Sosnowiec", slug: "sosnowiec"},
            {name: "Częstochowa", slug: "czestochowa"},
            {name: "Katowice", slug: "katowice"}
        ]
    },
    {
        name: "Świętokrzyskie",
        slug: "swietokrzyskie",
        cities: [
            {name: "Końskie", slug: "konskie"},
            {name: "Starachowice", slug: "starachowice"},
            {name: "Kielce", slug: "kielce"}
        ]
    },
    {
        name: "Warmińsko-mazurskie",
        slug: "warminsko-mazurskie",
        cities: [
            {name: "Szczytno", slug: "szczytno"},
            {name: "Ostróda", slug: "ostroda"},
            {name: "Iława", slug: "ilawa"},
            {name: "Ełk", slug: "elk"},
            {name: "Elbląg", slug: "elblag"},
            {name: "Olsztyn", slug: "olsztyn"}
        ]
    },
    {
        name: "Wielkopolskie",
        slug: "wielkopolskie",
        cities: [
            {name: "Leszno", slug: "leszno"},
            {name: "Gniezno", slug: "gniezno"},
            {name: "Piła", slug: "pila"},
            {name: "Kalisz", slug: "kalisz"},
            {name: "Poznań", slug: "poznan"}
        ]
    },
    {
        name: "Zachodniopomorskie",
        slug: "zachodniopomorskie",
        cities: [
            {name: "Szczecinek", slug: "szczecinek"},
            {name: "Kołobrzeg", slug: "kolobrzeg"},
            {name: "Stargard", slug: "stargard"},
            {name: "Koszalin", slug: "koszalin"},
            {name: "Szczecin", slug: "szczecin"}
        ]
    }
];

const regionsHousesForSale = [
    {
        name: "Dolnośląskie",
        slug: "dolnoslaskie",
        cities: [
            {name: "Legnica", slug: "legnica"},
            {name: "Głogów", slug: "glogow"},
            {name: "Wałbrzych", slug: "walbrzych"},
            {name: "Lubin", slug: "lubin"},
            {name: "Wrocław", slug: "wroclaw"}
        ]
    },
    {
        name: "Kujawsko-pomorskie",
        slug: "kujawsko-pomorskie",
        cities: [
            {name: "Inowrocław", slug: "inowroclaw"},
            {name: "Włocławek", slug: "wloclawek"},
            {name: "Toruń", slug: "torun"},
            {name: "Bydgoszcz", slug: "bydgoszcz"}
        ]
    },
    {
        name: "Lubelskie",
        slug: "lubelskie",
        cities: [
            {name: "Chełm", slug: "chelm"},
            {name: "Lublin", slug: "lublin"}
        ]
    },
    {
        name: "Lubuskie",
        slug: "lubuskie",
        cities: [
            {name: "Zielona Góra", slug: "zielona-gora"},
            {name: "Gorzów Wielkopolski", slug: "gorzow-wielkopolski"}
        ]
    },
    {
        name: "Łódzkie",
        slug: "lodzkie",
        cities: [
            {name: "Radomsko", slug: "radomsko"},
            {name: "Skierniewice", slug: "skierniewice"},
            {name: "Pabianice", slug: "pabianice"},
            {name: "Zduńska Wola", slug: "zdunska-wola"},
            {name: "Zgierz", slug: "zgierz"},
            {name: "Piotrków Trybunalski", slug: "piotrkow-trybunalski"},
            {name: "Tomaszów Mazowiecki", slug: "tomaszow-mazowiecki"},
            {name: "Bełchatów", slug: "belchatow"},
            {name: "Łódź", slug: "lodz"}
        ]
    },
    {
        name: "Małopolskie",
        slug: "malopolskie",
        cities: [
            {name: "Nowy Targ", slug: "nowy-targ"},
            {name: "Zakopane", slug: "zakopane"},
            {name: "Tarnów", slug: "tarnow"},
            {name: "Nowy Sącz", slug: "nowy-sacz"},
            {name: "Kraków", slug: "krakow"}
        ]
    },
    {
        name: "Mazowieckie",
        slug: "mazowieckie",
        cities: [
            {name: "Piaseczno", slug: "piaseczno"},
            {name: "Siedlce", slug: "siedlce"},
            {name: "Radom", slug: "radom"},
            {name: "Warszawa", slug: "warszawa"}
        ]
    },
    {
        name: "Opolskie",
        slug: "opolskie",
        cities: [
            {name: "Opole", slug: "opole"},
            {name: "Namysłów", slug: "namyslow"}
        ]
    },
    {
        name: "Podkarpackie",
        slug: "podkarpackie",
        cities: [
            {name: "Dębica", slug: "debica"},
            {name: "Rzeszów", slug: "rzeszow"}
        ]
    },
    {
        name: "Podlaskie",
        slug: "podlaskie",
        cities: [
            {name: "Łomża", slug: "lomza"},
            {name: "Białystok", slug: "bialystok"}
        ]
    },
    {
        name: "Pomorskie",
        slug: "pomorskie",
        cities: [
            {name: "Słupsk", slug: "slupsk"},
            {name: "Gdańsk", slug: "gdansk"}
        ]
    },
    {
        name: "Śląskie",
        slug: "slaskie",
        cities: [
            {name: "Ruda Śląska", slug: "ruda-slaska"},
            {name: "Zabrze", slug: "zabrze"},
            {name: "Sosnowiec", slug: "sosnowiec"},
            {name: "Gliwice", slug: "gliwice"},
            {name: "Dąbrowa Górnicza", slug: "dabrowa-gornicza"},
            {name: "Rybnik", slug: "rybnik"},
            {name: "Katowice", slug: "katowice"},
            {name: "Częstochowa", slug: "czestochowa"}
        ]
    },
    {
        name: "Świętokrzyskie",
        slug: "swietokrzyskie",
        cities: [
            {name: "Jędrzejów", slug: "jedrzejow"},
            {name: "Ostrowiec Świętokrzyski", slug: "ostrowiec-swietokrzyski"},
            {name: "Kielce", slug: "kielce"}
        ]
    },
    {
        name: "Warmińsko-mazurskie",
        slug: "warminsko-mazurskie",
        cities: [
            {name: "Elbląg", slug: "elblag"},
            {name: "Iława", slug: "ilawa"},
            {name: "Ełk", slug: "elk"},
            {name: "Olsztyn", slug: "olsztyn"}
        ]
    },
    {
        name: "Wielkopolskie",
        slug: "wielkopolskie",
        cities: [
            {name: "Gniezno", slug: "gniezno"},
            {name: "Kalisz", slug: "kalisz"},
            {name: "Leszno", slug: "leszno"},
            {name: "Piła", slug: "pila"},
            {name: "Poznań", slug: "poznan"}
        ]
    },
    {
        name: "Zachodniopomorskie",
        slug: "zachodniopomorskie",
        cities: [
            {name: "Koszalin", slug: "koszalin"},
            {name: "Stargard", slug: "stargard"},
            {name: "Szczecin", slug: "szczecin"}
        ]
    }
];

const regionsLotsForSale = [
    {
        name: "Dolnośląskie",
        slug: "dolnoslaskie",
        cities: [
            {name: "Głogów", slug: "glogow"},
            {name: "Lubin", slug: "lubin"},
            {name: "Jelenia Góra", slug: "jelenia-gora"},
            {name: "Wrocław", slug: "wroclaw"}
        ]
    },
    {
        name: "Kujawsko-pomorskie",
        slug: "kujawsko-pomorskie",
        cities: [
            {name: "Brodnica", slug: "brodnica"},
            {name: "Włocławek", slug: "wloclawek"},
            {name: "Toruń", slug: "torun"},
            {name: "Bydgoszcz", slug: "bydgoszcz"}
        ]
    },
    {
        name: "Lubelskie",
        slug: "lubelskie",
        cities: [
            {name: "Świdnik", slug: "swidnik"},
            {name: "Biłgoraj", slug: "bilgoraj"},
            {name: "Biała Podlaska", slug: "biala-podlaska"},
            {name: "Chełm", slug: "chelm"},
            {name: "Lublin", slug: "lublin"}
        ]
    },
    {
        name: "Lubuskie",
        slug: "lubuskie",
        cities: [
            {name: "Gorzów Wielkopolski", slug: "gorzow-wielkopolski"},
            {name: "Zielona Góra", slug: "zielona-gora"}
        ]
    },
    {
        name: "Łódzkie",
        slug: "lodzkie",
        cities: [
            {name: "Łódź", slug: "lodz"},
            {name: "Zduńska Wola", slug: "zdunska-wola"},
            {name: "Bełchatów", slug: "belchatow"},
            {name: "Aleksandrów Łódzki", slug: "aleksandrow-lodzki"},
            {name: "Zgierz", slug: "zgierz"}
        ]
    },
    {
        name: "Małopolskie",
        slug: "malopolskie",
        cities: [
            {name: "Kraków", slug: "krakow"},
            {name: "Bochnia", slug: "bochnia"},
            {name: "Zakopane", slug: "zakopane"},
            {name: "Nowy Sącz", slug: "nowy-sacz"},
            {name: "Niepołomice", slug: "niepolomice"},
            {name: "Wieliczka", slug: "wieliczka"}
        ]
    },
    {
        name: "Mazowieckie",
        slug: "mazowieckie",
        cities: [
            {name: "Otwock", slug: "otwock"},
            {name: "Warszawa", slug: "warszawa"},
            {name: "Halinów", slug: "halinow"},
            {name: "Siedlce", slug: "siedlce"},
            {name: "Radom", slug: "radom"}
        ]
    },
    {
        name: "Opolskie",
        slug: "opolskie",
        cities: [
            {name: "Namysłów", slug: "namyslow"},
            {name: "Opole", slug: "opole"}
        ]
    },
    {
        name: "Podkarpackie",
        slug: "podkarpackie",
        cities: [
            {name: "Rzeszów", slug: "rzeszow"},
            {name: "Dębica", slug: "debica"}
        ]
    },
    {
        name: "Podlaskie",
        slug: "podlaskie",
        cities: [
            {name: "Białystok", slug: "bialystok"},
            {name: "Augustów", slug: "augustow"},
            {name: "Choroszcz", slug: "choroszcz"},
            {name: "Suwałki", slug: "suwalki"}
        ]
    },
    {
        name: "Pomorskie",
        slug: "pomorskie",
        cities: [
            {name: "Gdańsk", slug: "gdansk"},
            {name: "Władysławowo", slug: "wladyslawowo"},
            {name: "Pruszcz Gdański", slug: "pruszcz-gdanski"},
            {name: "Słupsk", slug: "slupsk"},
            {name: "Gdynia", slug: "gdynia"}
        ]
    },
    {
        name: "Śląskie",
        slug: "slaskie",
        cities: [
            {name: "Gliwice", slug: "gliwice"},
            {name: "Częstochowa", slug: "czestochowa"},
            {name: "Żory", slug: "zory"},
            {name: "Mysłowice", slug: "myslowice"},
            {name: "Sosnowiec", slug: "sosnowiec"}
        ]
    },
    {
        name: "Świętokrzyskie",
        slug: "swietokrzyskie",
        cities: [
            {name: "Kielce", slug: "kielce"},
            {name: "Sandomierz", slug: "sandomierz"}
        ]
    },
    {
        name: "Warmińsko-mazurskie",
        slug: "warminsko-mazurskie",
        cities: [
            {name: "Olsztyn", slug: "olsztyn"},
            {name: "Ełk", slug: "elk"},
            {name: "Ostróda", slug: "ostroda"},
            {name: "Iława", slug: "ilawa"}
        ]
    },
    {
        name: "Wielkopolskie",
        slug: "wielkopolskie",
        cities: [
            {name: "Gniezno", slug: "gniezno"},
            {name: "Kalisz", slug: "kalisz"},
            {name: "Poznań", slug: "poznan"}
        ]
    },
    {
        name: "Zachodniopomorskie",
        slug: "zachodniopomorskie",
        cities: [
            {name: "Szczecin", slug: "szczecin"},
            {name: "Stargard", slug: "stargard"},
            {name: "Koszalin", slug: "koszalin"},
            {name: "Mielno", slug: "mielno"},
            {name: "Kołobrzeg", slug: "kolobrzeg"}
        ]
    }
];

const regionsFlatsForRent = [
    {
        name: "Dolnośląskie",
        slug: "dolnoslaskie",
        cities: [
            {name: "Głogów", slug: "glogow"},
            {name: "Lubin", slug: "lubin"},
            {name: "Wałbrzych", slug: "walbrzych"},
            {name: "Wrocław", slug: "wroclaw"}
        ]
    },
    {
        name: "Kujawsko-Pomorskie",
        slug: "kujawsko-pomorskie",
        cities: [
            {name: "Inowrocław", slug: "inowroclaw"},
            {name: "Bydgoszcz", slug: "bydgoszcz"},
            {name: "Toruń", slug: "torun"}
        ]
    },
    {
        name: "Lubelskie",
        slug: "lubelskie",
        cities: [
            {name: "Lublin", slug: "lublin"},
            {name: "Chełm", slug: "chelm"}
        ]
    },
    {
        name: "Lubuskie",
        slug: "lubuskie",
        cities: [
            {name: "Zielona Góra", slug: "zielona-gora"},
            {name: "Gorzów Wielkopolski", slug: "gorzow-wielkopolski"}
        ]
    },
    {
        name: "Łódzkie",
        slug: "lodzkie",
        cities: [
            {name: "Łódź", slug: "lodz"},
            {name: "Piotrków Trybunalski", slug: "piotrkow-trybunalski"},
            {name: "Zduńska Wola", slug: "zdunska-wola"}
        ]
    },
    {
        name: "Małopolskie",
        slug: "malopolskie",
        cities: [
            {name: "Kraków", slug: "krakow"},
            {name: "Nowy Sącz", slug: "nowy-sacz"},
            {name: "Tarnów", slug: "tarnow"}
        ]
    },
    {
        name: "Mazowieckie",
        slug: "mazowieckie",
        cities: [
            {name: "Warszawa", slug: "warszawa"},
            {name: "Radom", slug: "radom"},
            {name: "Legionowo", slug: "legionowo"},
            {name: "Siedlce", slug: "siedlce"}
        ]
    },
    {
        name: "Podkarpackie",
        slug: "podkarpackie",
        cities: [
            {name: "Rzeszów", slug: "rzeszow"},
            {name: "Przemyśl", slug: "przemysl"}
        ]
    },
    {
        name: "Podlaskie",
        slug: "podlaskie",
        cities: [
            {name: "Białystok", slug: "bialystok"},
            {name: "Augustów", slug: "augustow"}
        ]
    },
    {
        name: "Pomorskie",
        slug: "pomorskie",
        cities: [
            {name: "Gdańsk", slug: "gdansk"},
            {name: "Gdynia", slug: "gdynia"}
        ]
    },
    {
        name: "Śląskie",
        slug: "slaskie",
        cities: [
            {name: "Chorzów", slug: "chorzow"},
            {name: "Ruda Śląska", slug: "ruda-slaska"},
            {name: "Częstochowa", slug: "czestochowa"},
            {name: "Katowice", slug: "katowice"},
            {name: "Rybnik", slug: "rybnik"},
            {name: "Bytom", slug: "bytom"},
            {name: "Sosnowiec", slug: "sosnowiec"}
        ]
    },
    {
        name: "Świętokrzyskie",
        slug: "swietokrzyskie",
        cities: [{name: "Kielce", slug: "kielce"}]
    },
    {
        name: "Warmińsko-Mazurskie",
        slug: "warminsko-mazurskie",
        cities: [
            {name: "Olsztyn", slug: "olsztyn"},
            {name: "Ełk", slug: "elk"}
        ]
    },
    {
        name: "Wielkopolskie",
        slug: "wielkopolskie",
        cities: [
            {name: "Poznań", slug: "poznan"},
            {name: "Piła", slug: "pila"},
            {name: "Leszno", slug: "leszno"},
            {name: "Kalisz", slug: "kalisz"}
        ]
    },
    {
        name: "Zachodniopomorskie",
        slug: "zachodniopomorskie",
        cities: [
            {name: "Kołobrzeg", slug: "kolobrzeg"},
            {name: "Szczecin", slug: "szczecin"}
        ]
    }
];

const regionsOneRoomForSale = [
    {
        name: "Dolnośląskie",
        slug: "dolnoslaskie",
        cities: [
            {name: "Wrocław", slug: "wroclaw"},
            {name: "Głogów", slug: "glogow"}
        ]
    },
    {
        name: "Kujawsko-Pomorskie",
        slug: "kujawsko-pomorskie",
        cities: [
            {name: "Bydgoszcz", slug: "bydgoszcz"},
            {name: "Toruń", slug: "torun"}
        ]
    },
    {
        name: "Lubelskie",
        slug: "lubelskie",
        cities: [
            {name: "Lublin", slug: "lublin"},
            {name: "Puławy", slug: "pulawy"},
            {name: "Biała Podlaska", slug: "biala-podlaska"}
        ]
    },
    {
        name: "Lubuskie",
        slug: "lubuskie",
        cities: [
            {name: "Zielona Góra", slug: "zielona-gora"},
            {name: "Gorzów Wielkopolski", slug: "gorzow-wielkopolski"}
        ]
    },
    {
        name: "Łódzkie",
        slug: "lodzkie",
        cities: [{name: "Łódź", slug: "lodz"}]
    },
    {
        name: "Małopolskie",
        slug: "malopolskie",
        cities: [
            {name: "Kraków", slug: "krakow"},
            {name: "Nowy Sącz", slug: "nowy-sacz"}
        ]
    },
    {
        name: "Mazowieckie",
        slug: "mazowieckie",
        cities: [
            {name: "Warszawa", slug: "warszawa"},
            {name: "Radom", slug: "radom"},
            {name: "Siedlce", slug: "siedlce"},
            {name: "Legionowo", slug: "legionowo"},
            {name: "Pruszków", slug: "pruszkow"}
        ]
    },
    {
        name: "Opolskie",
        slug: "opolskie",
        cities: [{name: "Opole", slug: "opole"}]
    },
    {
        name: "Podkarpackie",
        slug: "podkarpackie",
        cities: [
            {name: "Rzeszów", slug: "rzeszow"},
            {name: "Mielec", slug: "mielec"}
        ]
    },
    {
        name: "Podlaskie",
        slug: "podlaskie",
        cities: [{name: "Białystok", slug: "bialystok"}]
    },
    {
        name: "Pomorskie",
        slug: "pomorskie",
        cities: [
            {name: "Gdańsk", slug: "gdansk"},
            {name: "Gdynia", slug: "gdynia"},
            {name: "Słupsk", slug: "slupsk"}
        ]
    },
    {
        name: "Śląskie",
        slug: "slaskie",
        cities: [
            {name: "Katowice", slug: "katowice"},
            {name: "Częstochowa", slug: "czestochowa"},
            {name: "Gliwice", slug: "gliwice"},
            {name: "Sosnowiec", slug: "sosnowiec"},
            {name: "Tychy", slug: "tychy"},
            {name: "Zabrze", slug: "zabrze"}
        ]
    },
    {
        name: "Świętokrzyskie",
        slug: "swietokrzyskie",
        cities: [{name: "Kielce", slug: "kielce"}]
    },
    {
        name: "Warmińsko-Mazurskie",
        slug: "warminsko-mazurskie",
        cities: [
            {name: "Olsztyn", slug: "olsztyn"},
            {name: "Ełk", slug: "elk"}
        ]
    },
    {
        name: "Wielkopolskie",
        slug: "wielkopolskie",
        cities: [{name: "Poznań", slug: "poznan"}]
    },
    {
        name: "Zachodniopomorskie",
        slug: "zachodniopomorskie",
        cities: [
            {name: "Szczecin", slug: "szczecin"},
            {name: "Koszalin", slug: "koszalin"},
            {name: "Stargard", slug: "stargard"}
        ]
    }
];

const regionsOneRoomForRent = [
    {
        name: "Dolnośląskie",
        slug: "dolnoslaskie",
        cities: [{name: "Wrocław", slug: "wroclaw"}]
    },
    {
        name: "Kujawsko-Pomorskie",
        slug: "kujawsko-pomorskie",
        cities: [
            {name: "Bydgoszcz", slug: "bydgoszcz"},
            {name: "Toruń", slug: "torun"}
        ]
    },
    {
        name: "Lubelskie",
        slug: "lubelskie",
        cities: [{name: "Lublin", slug: "lublin"}]
    },
    {
        name: "Lubuskie",
        slug: "lubuskie",
        cities: [{name: "Zielona Góra", slug: "zielona-gora"}]
    },
    {
        name: "Łódzkie",
        slug: "lodzkie",
        cities: [{name: "Łódź", slug: "lodz"}]
    },
    {
        name: "Małopolskie",
        slug: "malopolskie",
        cities: [
            {name: "Kraków", slug: "krakow"},
            {name: "Tarnów", slug: "tarnow"}
        ]
    },
    {
        name: "Mazowieckie",
        slug: "mazowieckie",
        cities: [
            {name: "Warszawa", slug: "warszawa"},
            {name: "Radom", slug: "radom"}
        ]
    },
    {
        name: "Opolskie",
        slug: "opolskie",
        cities: [{name: "Opole", slug: "opole"}]
    },
    {
        name: "Podkarpackie",
        slug: "podkarpackie",
        cities: [{name: "Rzeszów", slug: "rzeszow"}]
    },
    {
        name: "Podlaskie",
        slug: "podlaskie",
        cities: [{name: "Białystok", slug: "bialystok"}]
    },
    {
        name: "Pomorskie",
        slug: "pomorskie",
        cities: [
            {name: "Gdańsk", slug: "gdansk"},
            {name: "Gdynia", slug: "gdynia"},
            {name: "Sopot", slug: "sopot"}
        ]
    },
    {
        name: "Śląskie",
        slug: "slaskie",
        cities: [
            {name: "Katowice", slug: "katowice"},
            {name: "Gliwice", slug: "gliwice"},
            {name: "Sosnowiec", slug: "sosnowiec"}
        ]
    },
    {
        name: "Świętokrzyskie",
        slug: "swietokrzyskie",
        cities: [{name: "Kielce", slug: "kielce"}]
    },
    {
        name: "Warmińsko-Mazurskie",
        slug: "warminsko-mazurskie",
        cities: [
            {name: "Olsztyn", slug: "olsztyn"},
            {name: "Ełk", slug: "elk"}
        ]
    },
    {
        name: "Wielkopolskie",
        slug: "wielkopolskie",
        cities: [
            {name: "Poznań", slug: "poznan"},
            {name: "Kalisz", slug: "kalisz"}
        ]
    },
    {
        name: "Zachodniopomorskie",
        slug: "zachodniopomorskie",
        cities: [
            {name: "Szczecin", slug: "szczecin"},
            {name: "Stargard", slug: "stargard"}
        ]
    }
];

export const regionsPrimaryMarket = [
    {
        name: "Nowe Mieszkania",
        offerType: OfferType.apartment,
        cities: [
            {name: "nowe mieszkania Warszawa", slug: "warszawa"},
            {name: "nowe mieszkania Kraków", slug: "krakow"},
            {name: "nowe mieszkania Wrocław", slug: "wroclaw"},
            {name: "nowe mieszkania Łódź", slug: "lodz"},
            {name: "nowe mieszkania Poznań", slug: "poznan"},
            {name: "nowe mieszkania Lublin", slug: "lublin"},
            {name: "nowe mieszkania Gdańsk", slug: "gdansk"},
            {name: "nowe mieszkania Szczecin", slug: "szczecin"},
            {name: "nowe mieszkania Bydgoszcz", slug: "bydgoszcz"},
            {name: "nowe mieszkania Białystok", slug: "bialystok"},
            {name: "nowe mieszkania Katowice", slug: "katowice"},
            {name: "nowe mieszkania Olsztyn", slug: "olsztyn"},
            {name: "nowe mieszkania Gdynia", slug: "gdynia"},
            {name: "nowe mieszkania Kielce", slug: "kielce"},
            {name: "nowe mieszkania Toruń", slug: "torun"},
            {name: "nowe mieszkania Rzeszów", slug: "rzeszow"}
        ]
    },
    {
        name: "Nowe Domy",
        offerType: OfferType.house,
        cities: [
            {name: "nowe domy Warszawa", slug: "warszawa"},
            {name: "nowe domy Łódź", slug: "lodz"},
            {name: "nowe domy Szczecin", slug: "szczecin"},
            {name: "nowe domy Kraków", slug: "krakow"},
            {name: "nowe domy Katowice", slug: "katowice"},
            {name: "nowe domy Gdańsk", slug: "gdansk"},
            {name: "nowe domy Wrocław", slug: "wroclaw"},
            {name: "nowe domy Poznań", slug: "poznan"}
        ]
    }
];

export interface IOfferTypeLinks {
    filter: CityListFilterTabCities;
    offerType: OfferType;
    type: DealTypeSlug;
    subLinks: {
        name: string;
        slug: string;
        cities: {name: string; slug: string}[];
    }[];
    title: string;
    tag?: UrlTagSlug;
}

export const offerTypeLinks = [
    {
        filter: CityListFilterTabCities.FLATS_FOR_SALE,
        offerType: OfferType.apartment,
        type: DealTypeSlug.SELL,
        subLinks: regionsFlatsForSale,
        title: "mieszkania na sprzedaż"
    },
    {
        filter: CityListFilterTabCities.HOUSES_FOR_SALE,
        offerType: OfferType.house,
        type: DealTypeSlug.SELL,
        subLinks: regionsHousesForSale,
        title: "domy na sprzedaż"
    },
    {
        filter: CityListFilterTabCities.LOTS_FOR_SALE,
        offerType: OfferType.lot,
        type: DealTypeSlug.SELL,
        subLinks: regionsLotsForSale,
        title: "działki"
    },
    {
        filter: CityListFilterTabCities.FLATS_FOR_RENT,
        offerType: OfferType.apartment,
        type: DealTypeSlug.RENT,
        subLinks: regionsFlatsForRent,
        title: "mieszkania do wynajęcia"
    },
    {
        filter: CityListFilterTabCities.ONE_ROOM_FOR_SALE,
        offerType: OfferType.apartment,
        type: DealTypeSlug.SELL,
        tag: UrlTagSlug.STUDIO,
        subLinks: regionsOneRoomForSale,
        title: "kawalerka"
    },
    {
        filter: CityListFilterTabCities.ONE_ROOM_FOR_RENT,
        offerType: OfferType.apartment,
        type: DealTypeSlug.RENT,
        tag: UrlTagSlug.STUDIO,
        subLinks: regionsOneRoomForRent,
        title: "kawalerka",
        subTitle: "wynajem"
    }
];
