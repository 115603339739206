import * as React from "react";
import {css} from "@emotion/react";

import {cityDistrictsList} from "../../constatns/constants";
import {sectionWrapper} from "../atoms";
import {DistrictsSectionCity} from "./DistrictsSectionCity";

export const DistrictsSection = () => {
    return (
        <section css={[sectionWrapper, wrapper]}>
            {cityDistrictsList.map((city) => (
                <DistrictsSectionCity
                    key={city.headline}
                    headline={city.headline}
                    imageSrc={city.imageSrc}
                    popularDistricts={city.popularDistricts}
                    trendingDistricts={city.trendingDistricts}
                />
            ))}
        </section>
    );
};

const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
