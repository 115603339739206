import {Dispatch, SetStateAction, Suspense, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {RouteComponentProps, useLocation, withRouter} from "react-router";
import {css} from "@linaria/core";
import {MultiPolygon} from "geojson";
import {includes} from "lodash";
import {Checkbox} from "@web2/form";
import {debounce, throttle} from "@web2/nodash";
import {convertToArrayOfLatLngLiterals, IGestureHandling, IMarker, IPolygon, LatLngTuple, LeafletMap, LeafletMouseEvent} from "@web2/open-street-map";
import {useStateRef} from "@web2/react_utils";
import {useUserDevice} from "@web2/user-device";

import {OpenStreetMapGH} from "../../../app/components/OpenStreetMapGH";
import {useTransitioningHistoryPush} from "../../../app/hooks/use_transitioning_history_push";
import {getNormalizedCoordinates} from "../../../app/utils/get_normalized_coordinates";
import {RequestState} from "../../../app/utils/request_response_utils/factories/reduce_request_state";
import {parseSearch} from "../../../app/utils/request_response_utils/parse_search";
import {getThemeBreakpoint, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {autoSearchGTMEvent} from "../../../tracking/google_tag_manager/auto_search_toggle";
import {gtmOfferListEvents} from "../../../tracking/google_tag_manager/gtm_offer_list_events";
import {DEFAULT_MARKERS_PER_PAGE, fetchOfferListMarkers, IListingMarker} from "../actions/fetch_offer_list_markers";
import {renderDesktopOfferTooltip} from "../map_utils/render_desktop_offer_tooltip";
import {BigMapIcon} from "./icons/BigMapIcon";
import {RefreshIcon} from "./icons/RefreshIcon";
import {SmallMapIcon} from "./icons/SmallMapIcon";
import {useTriggerOsmMapSearch} from "./map/desktop/use_osm_map_trigger_search";
import {
    limitInfo,
    mapAndControlsHolder,
    MapHolder,
    MapLoader,
    RefreshButtonsHolder,
    refreshHolder,
    ToggleMapSize,
    topMapElementsHolder
} from "./OfferListMapDesktop";
import {SortType} from "./OfferListSortButton";

import aftermarketIcon from "../../../styles/assets/svg/aftermarket_marker.svg";
import lotMarketIcon from "../../../styles/assets/svg/lot_market_marker.svg";
import primaryMarketIcon from "../../../styles/assets/svg/primary_market_marker.svg";

interface IProps extends RouteComponentProps<{}> {
    hoveredOfferId: string | null;
    sort?: SortType;
    isMobileModal?: boolean;
    isMobileOfferOpen?: boolean;
    onOfferClick: (offerSlug: string, sort?: SortType) => void;
    displayLimitInfo?: boolean;
    markersRequestState: RequestState;
    isMapBig?: boolean;
    setMapBig?: Dispatch<SetStateAction<boolean>>;
    markers: IListingMarker[] | null;
    initBounds?: LatLngTuple[];
    locationData: {
        location: {
            name: string | undefined;
            outline?: MultiPolygon;
        } | null;
    };
    gestureHandling?: IGestureHandling;
}

const fitBoundsOn = {initBounds: true, markers: true, polylines: true, polygons: true, circles: true, popup: false};
const fitBoundsOff = {markers: false, polylines: false, circles: false, polygons: false, popup: false};

const boundsOfPoland: LatLngTuple[] = [
    [48, 13],
    [56, 25]
];

const firePopup = (marker: IListingMarker | undefined) => (
    <div className={popupStyles} dangerouslySetInnerHTML={{__html: marker ? renderDesktopOfferTooltip(marker) : ""}}></div>
);

export const OfferListMapOsmC = (props: IProps) => {
    const [userInteracted, setUserInteracted] = useStateRef(false);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [dragSearchEnabled, setDragSearchEnabled] = useStateRef(false);
    const {setMapBig, isMapBig} = props;
    const [popup, setPopup] = useState<any>();
    const [enableFitBounds, setEnableFitBounds] = useState(true);
    const leafletRef = useRef<LeafletMap | null>(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const {isMobile} = useUserDevice();
    const {isTransitioning, transitionHistory} = useTransitioningHistoryPush();
    // zoom events can be triggered "externally" ie. when location is searched and map pans and zooms to show all markers.
    // We don't want to trigger search via such unintentional events. `allowZoomSearchRef` can be set to
    // TODO: handle pointer (touch) events like pinch to zoom
    const allowZoomSearchRef = useRef(false);

    const triggerSearch = useTriggerOsmMapSearch({leafletRef, onAfterSearch: () => setUserInteracted(false)});
    const [markerClick, setMarkerClick] = useState(false);
    function getMarkerFromHoverId() {
        if (!props.markers) {
            return;
        }
        return props.markers.find((marker) => marker.id === props.hoveredOfferId);
    }

    // fetch markers on mount in desktop view
    useEffect(() => {
        dispatch(fetchOfferListMarkers({}));
    }, []);

    const geoParams: {geo_box_bottom_left?: string; geo_box_top_right?: string} = parseSearch(location.search);
    useEffect(() => {
        if (!geoParams.geo_box_top_right || !geoParams.geo_box_bottom_left) {
            setEnableFitBounds(true);
        }
    }, [location]);
    const normalizedCoords = getMarkerFromHoverId()?.coordinates;

    useEffect(() => {
        if (props.hoveredOfferId && normalizedCoords) {
            setPopup({
                coords: getNormalizedCoordinates(normalizedCoords),
                content: firePopup(getMarkerFromHoverId())
            });
        }
        if (!props.hoveredOfferId && popup) {
            setPopup(null);
        }
    }, [props.hoveredOfferId]);

    const toggleMapSize = () => {
        isMapBig ? setMapBig?.(false) : setMapBig?.(true);
    };

    const moveTo = (markerId: string, markerCoordinates: {lat: number; lng: number}) => {
        const pushToHistoryHandler = () => {
            const bounds = leafletRef.current?.getBounds();
            if (bounds) {
                const boundsCoords1 = bounds.getNorthEast();
                const boundsCoords2 = bounds.getSouthWest();
                transitionHistory(location.pathname + location.search + location.hash, {
                    currentBounds: [
                        {
                            lat: boundsCoords1.lat,
                            lng: boundsCoords1.lng
                        },
                        {
                            lat: boundsCoords2.lat,
                            lng: boundsCoords2.lng
                        }
                    ],
                    offerId: markerId
                });
            }
        };
        leafletRef.current?.setView(markerCoordinates).once("moveend", pushToHistoryHandler);
    };

    const onMarkerClick = async (event: LeafletMouseEvent, marker: {id: string; slug: string}) => {
        gtmOfferListEvents.pin.click();

        if (isMobile) {
            setDragSearchEnabled(false);

            if (leafletRef.current) {
                const clickCenter = leafletRef.current?.latLngToContainerPoint(event.latlng);
                const mapCenterOffset = clickCenter?.subtract([0, -150]);
                const offsetCoordinates = leafletRef.current?.containerPointToLatLng(mapCenterOffset);

                moveTo(marker.id, offsetCoordinates);
                setMarkerClick(true);
            }
            props.onOfferClick(marker.id);
            return;
        }
        props.onOfferClick(marker.slug, props.sort);
    };

    const _markers: IMarker[] | undefined = useMemo(
        () =>
            props.markers?.map((marker) => {
                return {
                    id: marker.id,
                    coords: getNormalizedCoordinates(marker.coordinates),
                    icon: {
                        url: includes(marker.offer_type, "lot") ? lotMarketIcon : marker.market_type === "aftermarket" ? aftermarketIcon : primaryMarketIcon,
                        sizes: [15, 15]
                    },
                    onClick: (e: LeafletMouseEvent) => onMarkerClick(e, marker),
                    popup: () => firePopup(marker)
                };
            }),
        [props.markers, props.sort, firePopup]
    );

    const onMouseUp = () => {
        enableZoomEvents();
    };

    const onPointerUp = (event: PointerEvent) => {
        enableZoomEvents();
    };

    const onMapSearch = debounce(() => {
        if (props.isMobileOfferOpen) {
            return;
        }

        setEnableFitBounds(false);
        if (!dragSearchEnabled && !props.isMobileOfferOpen) {
            setShowRefreshButton(true);
            setUserInteracted(true);
        }
        if (dragSearchEnabled) {
            triggerSearch();
        }
    }, 300);

    const offerListLocation = props.locationData.location;

    const onWheelEvent = throttle(() => {
        enableZoomEvents();
    }, 300);

    const onMapZoom = () => {
        if (allowZoomSearchRef.current) {
            setUserInteracted(true);
            onMapSearch();
            disableZoomEvents();
        }
    };

    const enableZoomEvents = () => {
        if (!allowZoomSearchRef.current) allowZoomSearchRef.current = true;
    };

    const disableZoomEvents = () => {
        if (allowZoomSearchRef.current) allowZoomSearchRef.current = false;
    };

    const polygons = useMemo(() => {
        let polygons: IPolygon[] | undefined = [];
        if (props.locationData.location?.outline?.coordinates) {
            polygons.push({
                id: (offerListLocation && offerListLocation?.name) || "",
                positions: convertToArrayOfLatLngLiterals(props.locationData.location.outline?.coordinates, {reversedValues: true}),
                color: "#e81d31",
                opacity: 0.5,
                fillOpacity: 0.02
            });
        } else {
            polygons = undefined;
        }
        return polygons;
    }, [props.locationData]);

    const areMarkersLoading = props.markersRequestState === RequestState.Waiting;
    const toggleDragSearch = () => {
        autoSearchGTMEvent(!dragSearchEnabled);
        setDragSearchEnabled(!dragSearchEnabled);
    };

    const onMapInit = () => {
        if (leafletRef.current && props.initBounds !== undefined) {
            setTimeout(() => {
                leafletRef.current?.fitBounds(props.initBounds as LatLngTuple[]);
            }, 0);
        }
    };

    useEffect(() => {
        if (leafletRef.current && props.initBounds !== undefined) {
            setTimeout(() => {
                if (!markerClick && props.initBounds) {
                    leafletRef.current?.fitBounds(props.initBounds);
                }
                setMarkerClick(false);
            }, 0);
        }
    }, [props.initBounds]);

    return (
        <>
            <MapHolder isMapBig={props.isMapBig} isMobileModal={props.isMobileModal}>
                {props.displayLimitInfo && (
                    <div className={limitInfo}>Na mapie pokazujemy do {DEFAULT_MARKERS_PER_PAGE} wyników. Przybliż mapę lub wybierz filtry</div>
                )}
                <div className={mapAndControlsHolder}>
                    <div className={topMapElementsHolder}>
                        <ToggleMapSize onClick={toggleMapSize} title="Powiększ mapę">
                            {!props.isMapBig && <BigMapIcon />}
                            {props.isMapBig && <SmallMapIcon />}
                        </ToggleMapSize>
                        {(userInteracted || areMarkersLoading || showRefreshButton) && (
                            <RefreshButtonsHolder userInteracted={!areMarkersLoading && userInteracted}>
                                {userInteracted ? (
                                    <div
                                        onClick={() => {
                                            setUserInteracted(true);
                                            setEnableFitBounds(false);
                                            triggerSearch();
                                        }}
                                        className={refreshHolder}
                                    >
                                        <RefreshIcon />
                                        Ponów wyszukiwanie tutaj
                                    </div>
                                ) : (
                                    <>
                                        {areMarkersLoading ? (
                                            <div>Ładowanie danych...</div>
                                        ) : (
                                            <Checkbox
                                                name="refresh_on_move"
                                                onAfterChange={() => null}
                                                onChange={toggleDragSearch}
                                                value={dragSearchEnabled}
                                                label="Wyszukuj podczas przesuwania mapy"
                                            />
                                        )}
                                    </>
                                )}
                            </RefreshButtonsHolder>
                        )}
                    </div>
                    {props.markers || polygons ? (
                        <Suspense fallback={<MapLoader />}>
                            <OpenStreetMapGH
                                markers={_markers}
                                polygons={polygons}
                                initBounds={props.initBounds}
                                onMapDragEnd={onMapSearch}
                                onMouseUp={onMouseUp}
                                onMapMove={disableZoomEvents}
                                onMapZoom={onMapZoom}
                                onPointerUp={onPointerUp}
                                maxBounds={boundsOfPoland}
                                maxBoundsViscosity={0.8}
                                onPointerLeave={disableZoomEvents}
                                onWheelEvent={onWheelEvent}
                                fitBounds={enableFitBounds ? fitBoundsOn : fitBoundsOff}
                                setExternalRef={(instance) => (leafletRef.current = instance)}
                                popup={popup}
                                zoomSnap={0.5}
                                labelsInsideMarkers={true}
                                gestureHandling={props.gestureHandling}
                                onMapInit={onMapInit}
                            />
                        </Suspense>
                    ) : (
                        <MapLoader />
                    )}
                </div>
            </MapHolder>
        </>
    );
};

export const OfferListMapOsm = withRouter(OfferListMapOsmC);

export const popupStyles = css`
    .map-tooltip-holder {
        user-select: none;
        display: none;

        @media (min-width: ${getThemeBreakpoint().screen_md}) {
            display: block;
        }

        .map-tooltip {
            background-color: rgba(55, 71, 79, 0.85);
            box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px 0;
            padding: 1rem 2rem;
            color: #fff;
            border-radius: 4px;

            &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                content: "";
                bottom: -0.945rem;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid rgba(55, 71, 79, 0.85);
                z-index: 1;
            }

            &--tag {
                border-radius: 0.4rem;
                padding: 0.4rem 0.8rem;
                margin-bottom: 0.4rem;
                margin-left: -0.1rem;

                background-color: ${getThemeVariable("buttons-btn_default_bg")};
                font-size: 1rem;
                font-weight: 600;
            }

            &--first-line {
                display: block;

                font-size: 1.4rem;
                font-weight: 400;
            }

            &--second-line {
                display: block;
                margin-top: 0.7rem;

                font-weight: 600;
                font-size: 1.2rem;
            }

            &--inaccurate-location {
                display: block;
                margin-top: 0.7rem;

                font-weight: 400;
                font-size: 1.2rem;
            }
        }
    }
`;
