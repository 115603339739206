import {css, Theme} from "@emotion/react";

import {ICityDistrictProps} from "../../constatns/constants";
import {sectionHeadline} from "../atoms";
import {DistrictSectionCityDistrictList} from "./DistrictSectionCityDistrictList";

interface IProps extends ICityDistrictProps {}

export const DistrictsSectionCity = (props: IProps) => {
    return (
        <div css={cityWrapper}>
            <h2 css={sectionHeadline}>{props.headline}</h2>

            <div css={innerWrapper}>
                <div css={imageHolder}>
                    <img css={imageCover} src={props.imageSrc} alt={props.headline} title={props.headline} loading="lazy" />
                </div>

                <div css={districtHolder}>
                    <DistrictSectionCityDistrictList title="Popularne dzielnice" isPopular districts={props.popularDistricts} mainDistrict={props.headline} />

                    <DistrictSectionCityDistrictList title="Rosnąca popularność" districts={props.trendingDistricts} mainDistrict={props.headline} />
                </div>
            </div>
        </div>
    );
};

const innerWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const cityWrapper = (theme: Theme) => css`
    margin: 1.5rem;
    max-width: 100%;

    @media (min-width: ${theme.breakpoints_correct.screen_xl}) {
        max-width: 1650px;
        width: 1650px;
    }

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        width: 1235px;
    }
`;

const imageHolder = (theme: Theme) => css`
    display: none;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 1.5rem;

    background-color: ${theme.colors.gray_very_bright};
    border-radius: ${theme.other.border_radius};

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        display: inline-block;
        flex: 0 0 40%;
    }
`;

const imageCover = css`
    position: absolute;
    left: 50%;
    height: 100%;
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    transform: translateX(-50%);
`;

const districtHolder = css`
    width: 100%;
`;
