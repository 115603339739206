import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {ParagraphGray} from "../../app/atoms/Paragraph";
import {BuylandoContainer} from "./styles/buylando_styles";

import avatar from "../assets/Marek_Wielgo.png";
import quote from "../assets/Quote.svg";

export const OurExpertQuote = () => (
    <QuoteWrapper>
        <ImageQuote src={quote} height={290} width={334} alt={"quote-icon"} />

        <FlexContainer>
            <div>
                <Image src={avatar} height={200} width={200} css={avatarRadius} alt={"marek-wielgo"} />
            </div>
            <Name>Marek Wielgo</Name>
            <Role>Ekspert portalu GetHome.pl</Role>

            <article>
                <QuoteParagraph>
                    &quot;W ostatnich latach niewiele rzeczy w Polsce drożało tak bardzo jak grunty. Do zakupu działek i rozpoczynania inwestycji
                    mieszkaniowych, także deweloperskich, zniechęcają rosnące koszty budowy oraz inflacja, która w konsekwencji obniża możliwości finansowe
                    zainteresowanych budową domów lub zakupem działek rekreacyjnych. Nie ma jednak mowy o załamaniu popytu, jak ma to miejsce na rynku
                    mieszkaniowym. Nabywcy działek stają się po prostu bardziej wybredni i nie zadowolą się każdym kawałkiem ziemi. Z tego powodu tak kluczowe
                    jest teraz staranne zaprezentowanie nieruchomości i skierowanie oferty do jak najszerszego grona potencjalnych zainteresowanych.&quot;
                </QuoteParagraph>
            </article>
        </FlexContainer>
    </QuoteWrapper>
);

/*
QuoteSection Styles
 */

const QuoteWrapper = styled.section`
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: auto;
    background: #f2f2f2;
    padding: 20rem 2rem 5rem 2rem;
`;

const FlexContainer = styled(BuylandoContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const QuoteParagraph = styled(ParagraphGray)`
    font-style: italic;
    font-weight: 400;
    line-height: 2.7rem;
    max-width: 655px;
    text-align: center;
    font-size: 1.8rem;
    margin-top: 3rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        margin-top: 5rem;
    }
`;

const Name = styled(ParagraphGray)`
    margin: 3.5rem auto 0;
    line-height: 3.3rem;
    font-size: 2.2rem;
    font-weight: 600;
`;
const Role = styled(ParagraphGray)`
    font-size: 1.6rem;
`;

interface IImageStyles {
    theme?: Theme;
    height: number;
    width: number;
}

const avatarRadius = css`
    box-shadow: 0px 4.5rem 2.9rem 3rem rgba(144, 105, 192, 0.12);
    border-radius: 100%;
`;
const imageStyles = (props: IImageStyles) => {
    return css`
        height: ${`${props.height}px`};
        width: ${`${props.width}px`};
    `;
};

const Image = styled.img(imageStyles);

const ImageQuote = styled.img`
    ${imageStyles};
    position: absolute;
    top: 8%;
    left: 50%;
    margin-bottom: 1rem;
    transform: translateX(-50%);

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        transform: translateX(0%);
        top: 16%;
        left: 5%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        left: 10%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xl}) {
        left: 15%;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xxl}) {
        left: 20%;
    }
`;
