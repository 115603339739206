import * as React from "react";

import {Footer} from "../footer/Footer";
import {CitesSection} from "./components/CitiesSection";
import {DistrictsSection} from "./components/districts_section/DistrictsSection";
import {HeroSection} from "./components/HeroSection";
import {HomePageAdPlacements} from "./components/HomePageAdPlacements";
import {OfferTypeSection} from "./components/OfferTypeSection";
import {SchemaOrganization} from "./schemas/SchemaOrganization";
import {SchemaWebsite} from "./schemas/SchemaWebsite";

export const HomepageDynamic = () => {
    return (
        <>
            <HeroSection />

            <HomePageAdPlacements />

            <OfferTypeSection />

            <CitesSection />

            <DistrictsSection />

            <SchemaOrganization />

            <SchemaWebsite />

            <Footer />
        </>
    );
};
