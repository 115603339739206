import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

interface IParagraphStylesProps {
    color?: string;
    fontSize?: number;
    lineHeight?: string;
    marginBottom?: number;
    theme?: Theme;
}

export const paragraphStyles = (props: IParagraphStylesProps) => css`
    margin: 0.5rem 0 ${props.marginBottom ? `${props.marginBottom}rem` : "0.5rem"} 0;
    color: #fff;
    font-weight: 400;
    line-height: ${props.lineHeight || 1.6};
    font-size: ${props.fontSize ? `${props.fontSize}px` : "1.2rem"};

    a {
        color: ${props.theme?.colors.blue};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Paragraph = styled.p<IParagraphStylesProps>`
    ${paragraphStyles};
    color: ${(props) => props.color || props.theme.colors.text_color};
`;

export const ParagraphDark = styled(Paragraph)`
    color: #536168;
`;

export const ParagraphGray = styled(Paragraph)`
    color: #37474f;
`;

export const ParagraphLight = styled(Paragraph)`
    color: #607d8b;
`;
