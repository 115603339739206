import React from "react";

export const SchemaWebsite = () => {
    const json = {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        name: "GetHome",
        url: "https://gethome.pl/",
        potentialAction: {
            "@type": "SearchAction",
            target: {
                "@type": "EntryPoint",
                urlTemplate: "https://gethome.pl/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
        }
    };

    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} />;
};
