import * as React from "react";
import styled from "@emotion/styled";
import {map} from "lodash";

import {FlexColumn} from "../../app/atoms/Flex";
import {AboutUsLPHeader} from "../atoms/about_us_landing_page_styles";
import {team} from "../constants/about_us_data";
import {TeamDetail} from "./TeamDetail";

import quoteImgBg from "../../styles/assets/background/blue_wave_bg.jpg";
import teamIcon from "../../styles/assets/svg/team.svg";

export const OurTeam = () => {
    const teamAnchor = {
        name: "Zespół 100 osób rynekpierwotny.pl",
        source: teamIcon,
        email: "",
        title: ""
    };

    const redirectToPG = () => window.open("https://propertygroup.pl/o-nas/", "_blank");

    return (
        <Wrapper>
            <Container>
                <SectionHeader>Nasz zespół</SectionHeader>

                <InnerWrapper>
                    {map(team, (item, idx) => (
                        <TeamDetail {...item} key={idx} />
                    ))}

                    <Anchor onClick={redirectToPG}>
                        <TeamDetail {...teamAnchor} className="team-anchor" />
                    </Anchor>
                </InnerWrapper>
            </Container>
        </Wrapper>
    );
};

/*
Styles
 */

const Wrapper = styled.section`
    padding: 1rem;
    height: auto;
    background-image: url("${quoteImgBg}");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
`;

const Container = styled("div")`
    max-width: 1200px;
    margin: 0 auto;
`;

const InnerWrapper = styled(FlexColumn)`
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
`;

const Anchor = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_xs}) {
        width: 50%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        width: 25%;
    }
`;

const SectionHeader = styled(AboutUsLPHeader)`
    text-align: center;
    color: #fff;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        margin: 4rem 0;
    }
`;
