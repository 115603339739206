import {css} from "@emotion/react";

import checked from "../assets/check_circle_outline.svg";
const sellTypes = ["Budowlaną", "Rolną", "Siedliskową", "Leśną", "Rekreacyjną", "Inwestycyjną", "Przemysłową"];

export const WhatCanISellList = (): JSX.Element => {
    return (
        <ul css={listItem}>
            {sellTypes.map((item, index) => {
                return (
                    <li key={index}>
                        <img src={checked} alt={item} />
                        <p>{item}</p>
                    </li>
                );
            })}
        </ul>
    );
};

const listItem = css`
    margin: 0;
    padding: 2.5rem 0 0;
    li {
        display: flex;
        padding-bottom: 0.8rem;

        p {
            margin: 0.5rem 1rem;
            font-size: 1.8rem;
        }
    }
`;
