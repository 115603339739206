import {css, Theme} from "@emotion/react";

export const blogHeadHolder = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    margin-top: 2rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        flex-direction: column;
        align-items: start;
    }
`;

export const blogHeading = (theme: Theme) => css`
    display: block;
    color: ${theme.colors.gray_dark};
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.5rem;
    margin-bottom: 0;
    margin-right: 1rem;
    margin-top: 0;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 3.4rem;
        margin-bottom: 3rem;

        > span {
            font-size: 2.4rem;
        }
    }
`;

export const blogLoadingAnimation = css`
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #fff;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 800px 104px;
    position: relative;

    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
`;
