import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {ParagraphGray} from "../../app/atoms/Paragraph";
import {mb_xl} from "../../styles/helpers";
import {BuylandoContainer, BuylandoLPHeader} from "./styles/buylando_styles";
import {StyledBuylandoButton} from "./atoms";

import nextStep from "../assets/next_step.svg";
import prostyFormularz from "../assets/prosty_formularz.svg";
import publikacja from "../assets/publikacja.svg";
import weryfikacja from "../assets/szybka_weryfikacja.svg";

export const StepByStep = () => (
    <BuylandoWrapper>
        <BuylandoContainer>
            <BuylandoLPHeader>Jak krok po kroku dodać ofertę?</BuylandoLPHeader>

            <Container justifyContent="center" css={mb_xl}>
                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={157} width={145} src={prostyFormularz} alt={"team"} />

                    <Paragraph>Wypełnij prosty formularz na stronie naszego partnera</Paragraph>
                </FlexColumn>
                <FlexColumn alignItems="center" justifyContent="center">
                    <Image height={51} width={45} src={nextStep} alt={"step one"} isArrow={true} negativeMargin={40} />
                </FlexColumn>
                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={147} width={156} src={weryfikacja} alt={"big-data"} />

                    <Paragraph>Poczekaj na szybką weryfikację</Paragraph>
                </FlexColumn>
                <FlexColumn alignItems="center" justifyContent="center">
                    <Image height={51} width={45} src={nextStep} alt={"step two"} isArrow={true} negativeMargin={40} />
                </FlexColumn>
                <FlexColumn alignItems="center" justifyContent="space-between">
                    <Image height={159} width={156} src={publikacja} alt={"effects"} />

                    <Paragraph>
                        Twoja oferta zostanie opublikowana <strong css={dBlock}>w serwisie Buylando</strong>
                    </Paragraph>
                </FlexColumn>
            </Container>

            <StyledBuylandoButton href="https://buylando.pl/panel/rynek-pierwotny/" variant="success" size="lg" css={styledBuylandoButton}>
                Sprzedaj działkę już teraz
            </StyledBuylandoButton>
        </BuylandoContainer>
    </BuylandoWrapper>
);

/*
MarketSection Styles
 */
const dBlock = css`
    display: block;
`;
const BuylandoWrapper = styled.section`
    padding: 2rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        padding: 12.5rem 2rem 5rem;
        background: #fff;
        margin-top: -8rem;
    }
`;

const Container = styled(Flex)`
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        flex-direction: row;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        justify-content: space-between;
        max-width: 100%;
    }
`;

const SectionHeader = styled(BuylandoLPHeader)`
    text-align: center;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_md}) {
        margin-bottom: 8.5rem;
    }
`;

const Paragraph = styled(ParagraphGray)`
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.7rem;
    max-width: 278px;
    margin-bottom: 2rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        height: 75px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_lg}) {
        margin-bottom: 0.5rem;
    }
`;

interface IImageStylesProps {
    theme?: Theme;
    negativeMargin?: number;
    isArrow?: boolean;
    height: number;
    width: number;
}

const Image = styled.img<IImageStylesProps>`
    height: ${(props) => (props.isArrow ? "34px" : "120px")};
    width: ${(props) => (props.isArrow ? "30px" : "100px")};
    ${(props) =>
        props.isArrow &&
        `
        margin-top: 20px;
    transform: rotate(90deg); 
    margin-bottom: 50px;
    `}
    @media screen and (min-width:  ${(props) => props.theme?.breakpoints_correct.screen_md}) {
        height: ${(props) => props.height}px;
        width: ${(props) => props.width}px;
        margin-top: ${(props) => (props.negativeMargin ? "-" + props.negativeMargin + "px" : "0px")};
        transform: rotate(0deg);
    }
`;

const styledBuylandoButton = css`
    margin: 0 auto;
    height: 65px;
`;
