import * as React from "react";
import styled from "@emotion/styled";
import {map} from "lodash";

import {Flex, FlexColumn} from "../../app/atoms/Flex";
import {ParagraphGray} from "../../app/atoms/Paragraph";

import arrow from "../../styles/assets/svg/arrow_icon.svg";
import step5 from "../../styles/assets/svg/chain_icon.svg";
import step2 from "../../styles/assets/svg/clock_icon.svg";
import step1 from "../../styles/assets/svg/form_icon.svg";
import step4 from "../../styles/assets/svg/lead_icon.svg";
import step6 from "../../styles/assets/svg/no_fees_icon.svg";
import step3 from "../../styles/assets/svg/send_icon.svg";

const CollaborationGraphSteps = [
    {
        icon: step1,
        text: "Wypełnij formularz rejestracyjny",
        height: 130,
        width: 95
    },
    {
        icon: step2,
        text: "Poczekaj na szybką weryfikację z naszej strony",
        height: 130,
        width: 118
    },
    {
        icon: step3,
        text: "Wyślemy Ci login i hasło do eksportu w ASARI, EstiCRM, Galactica Virgo, Mediarent oraz IMO",
        height: 130,
        width: 136
    },
    {
        icon: step4,
        text: "Leady będziemy przekazywać na adresy email pośredników",
        height: 130,
        width: 120
    },
    {
        icon: step5,
        text: "Wyślemy Ci link do podglądu ofert swojego biura nieruchomości",
        height: 130,
        width: 101
    },
    {
        icon: step6,
        text: "Płacisz tylko za efekt - dostarczonego przez nas klienta!",
        height: 130,
        width: 115
    }
];

export const CollaborationGraph = () => (
    <CollaborationGraphWrapper justifyContent={"center"}>
        {map(CollaborationGraphSteps, (item, idx) => (
            <CollaborationStep key={idx} alignItems={"center"}>
                <Image src={item.icon} height={item.height} width={item.width} alt={"icon"} />
                <Paragraph fontSize={16}>{item.text}</Paragraph>
            </CollaborationStep>
        ))}
    </CollaborationGraphWrapper>
);

/*
CollaborationGraph Styles
 */

const CollaborationGraphWrapper = styled(Flex)`
    max-width: 920px;
    margin: 0 auto;
    flex-wrap: wrap;
`;

const CollaborationStep = styled(FlexColumn)`
  width: 100%;
  position: relative;
  margin-bottom: 7rem;
  &:after {
    content: url('${arrow}');
    position: absolute;
    bottom: -40px;
    right: 50%;
    height: 37px;
    width: 30px;
    transform: translate(50%, 0%) rotate(90deg);
   }
   &:last-child:after {
      content: "";
   }
   @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
     width: calc(100%/3);
     &:after {
      top: 40px;
      right: 0;
      transform: translate(50%, 0%);
   }
    &:nth-of-type(3){
      margin-bottom: 10rem;
      &:after {
        top: initial;
        right: 50%;
        bottom: -70px;
        transform: translate(50%, 0%) rotate(90deg);
      }
    }
    &:nth-of-type(4){
      &:after {
        content: "";
      }
    }
    &:nth-of-type(5),
    &:nth-of-type(6) {
      &:after {
         content: url('${arrow}');
        right: initial;
        left: 0;
        bottom: initial;
        top: 40px;
        transform: translate(-50%, 0%) rotate(180deg);
      }
    }
   }

   // re-ordeding on mobile (4 and 6 child switch its place, rest stays untouched)
   @media screen and (max-width: 767px) {
      &:nth-of-type(4) {
        order: 6;
        &:after {
        content: "";
      }}
      &:nth-of-type(5) { order: 5;}
      &:nth-of-type(6) {
        order: 4;
      &:after {
        content: url('${arrow}');
        position: absolute;
        bottom: -40px;
        right: 50%;
        height: 37px;
        width: 30px;
        transform: translate(50%, 0%) rotate(90deg);
      }
   }
`;

const Paragraph = styled(ParagraphGray)`
    text-align: center;
    color: ${(props) => props.theme.colors.gray_dark};
    max-width: 225px;
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_sm}) {
        max-width: 278px;
    }
`;

interface IImageStylesProps {
    height?: number;
    width?: number;
}

const Image = styled("img")<IImageStylesProps>`
    height: auto;
    width: 60px;

    @media screen and (min-width: ${(props) => props.theme?.breakpoints.screen_sm}) {
        margin-bottom: 3.5rem;
        height: ${(props) => props.height}px;
        width: ${(props) => props.width}px;
    }
`;
