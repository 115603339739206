import * as React from "react";
import {css, Theme} from "@emotion/react";

import {Footer} from "../../footer/Footer";
import {QualitySection} from "../../for_agent/components/QualitySection";
import {sectionHeaderBase} from "../../for_agent/components/RegisterChecklist";
import {AboutUsContactSection} from "./AboutUsContactSection";
import {AboutUsGallerySection} from "./AboutUsGallerySection";
import {AboutUsHeroSection} from "./AboutUsHeroSection";
import {NumberSection} from "./NumerSection";
import {OurTeam} from "./OurTeam";

export const AboutUsDynamic = () => {
    return (
        <>
            <AboutUsHeroSection />

            <div css={qualitySectionWrapper}>
                <QualitySection />
            </div>

            <NumberSection />

            <OurTeam />

            <AboutUsGallerySection />

            <AboutUsContactSection />

            <Footer />
        </>
    );
};

/*
Styles
 */

const qualitySectionWrapper = (theme: Theme) => css`
    .quality-section-header {
        ${sectionHeaderBase(theme)};
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.3rem;

        @media screen and (min-width: ${theme.breakpoints.screen_md}) {
            margin-bottom: 4rem;
        }
    }
`;
