import React from "react";
import {css, Theme} from "@emotion/react";

import {AuthorArticleCount} from "./AuthorArticleCount";
import {AuthorAvatar} from "./AuthorAvatar";
import {AuthorSocial} from "./AuthorSocial";

interface IProps {
    author: {
        article_count: number;
        description: string | null;
        email: string | null;
        facebook_profile: string | null;
        image: {
            aut_img_160x160: string;
            aut_img_240x240: string;
        } | null;
        instagram_profile: string | null;
        linkedin_profile: string | null;
        name: string;
        twitter_profile: string | null;
        web_page: string | null;
    };
    page: number;
}

export const AuthorDetailAuthorInfo = (props: IProps) => {
    const {author, page} = props;

    return (
        <figure css={authorInfoHolder}>
            <AuthorAvatar name={author.name} image={author.image?.aut_img_240x240} size={240} />

            <figcaption css={authorDataHolder}>
                <div css={autorDataHeader}>
                    <div>
                        <h1 css={authorInfoHeading}>
                            {author.name}
                            {page > 1 && ` - strona ${page}`}
                        </h1>

                        <AuthorArticleCount authorArticleCount={author.article_count} />
                    </div>

                    <AuthorSocial
                        authorEmail={author.email}
                        authorFacebook={author.facebook_profile}
                        authorTwitter={author.twitter_profile}
                        authorInstagram={author.instagram_profile}
                        authorLinkedin={author.linkedin_profile}
                        authorWebPage={author.web_page}
                        css={authorSocial}
                    />
                </div>

                <p css={authorDescription}>{author.description}</p>
            </figcaption>
        </figure>
    );
};

const authorInfoHolder = (theme: Theme) => css`
    margin: 3rem 0 5rem;
    border-radius: 0.8rem;
    border: 1px solid ${theme.colors.gray_very_bright};
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        margin-top: 4rem;
        padding: 3rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
`;

const authorDataHolder = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        margin-left: 2.5rem;
        flex-grow: 1;
        flex-shrink: 1;
    }
`;

const authorSocial = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        margin-top: 0.5rem;
    }
`;

const autorDataHeader = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        flex-direction: row;

        > div {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
`;

const authorInfoHeading = (theme: Theme) => css`
    margin-top: 2.5rem;
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        font-size: 2.4rem;
        font-weight: 500;
        margin-top: 0;
        text-align: left;
    }
`;

const authorDescription = (theme: Theme) => css`
    margin: 2rem 0 0;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.colors.gray_darker};
    text-align: center;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        text-align: left;
        margin-top: 1rem;
    }
`;
