import * as React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {FlexColumn} from "../../app/atoms/Flex";
import {Paragraph} from "../../app/atoms/Paragraph";
import {BuylandoContainer, BuylandoLPHeader} from "./styles/buylando_styles";

import contact_bg from "../assets/contact_bg.png";
import contact_bg_desktop_xl from "../assets/contact_bg_desktop_xl.png";
import contact_bg_mobile from "../assets/contact_bg_mobile.png";

const BlogData = [
    {
        id: 1,
        url: "https://gethome.pl/blog/jak-sprzedac-dzialke-praktyczny-poradnik/",
        title: "Jak sprzedać działkę? Praktyczny poradnik.",
        imgUrl: "https://thumbs.gethome.pl/19910099:INuB85svzVV4gHQzDpii3_y22sg/414x200/mdb_articles/article/image/9351c5.jpg"
    },
    {
        id: 2,
        url: "https://gethome.pl/blog/wycena-dzialki-krok-po-kroku/",
        title: "Wycena działki krok po kroku.",
        imgUrl: "https://thumbs.gethome.pl/19910099:_4PcWpP0EeymTMJ7oZl3st2hLs0/414x200/mdb_articles/article/image/ecc738.jpg"
    },
    {
        id: 3,
        url: "https://gethome.pl/blog/najwieksze-bledy-przy-sprzedazy-dzialki-jak-ich-unikac",
        title: "Największe błędy przy sprzedaży działki – jak ich unikać?",
        imgUrl: "https://thumbs.gethome.pl/19910099:8OVEY0Dh2k6EbnYLiVD8cBcIE2s/414x200/mdb_articles/article/image/1ac215.jpg"
    },
    {
        id: 4,
        url: "https://gethome.pl/blog/podatek-od-sprzedazy-dzialki-ile-wynosi/",
        title: "Podatek od sprzedaży działki – ile wynosi?",
        imgUrl: "https://thumbs.gethome.pl/19910099:BFAWc3ZIGFMN-u0pXtIfLcetj7k/414x200/mdb_articles/article/image/426eb2.jpg"
    },
    {
        id: 5,
        url: "https://gethome.pl/blog/dzialka-bez-drogi-dojazdowej-jak-zwiekszyc-szanse-na-sprzedaz/",
        title: "Działka bez drogi dojazdowej – jak zwiększyć szanse na sprzedaż?",
        imgUrl: "https://thumbs.gethome.pl/19910099:BA-rAmZBJ4zkaIGuQHxMq45ZaWE/414x200/mdb_articles/article/image/e8465e.jpg"
    }
];

export const BlogSection = () => (
    <ContactWrapper>
        <Container>
            <SectionHeader>Chcesz sprzedać działkę? To prostsze niż myślisz!</SectionHeader>
            <ParagraphSection>
                Porady ekspertów i serię przydatnych artykułów, związanych z szeroko rozumianą tematyką gruntów znajdziesz na naszym blogu.
            </ParagraphSection>
            <div css={blogContainer}>
                {BlogData.map((blog) => {
                    return (
                        <BlogCard key={blog.id}>
                            <a href={blog.url}>
                                <BlogCardImage image={blog.imgUrl} />
                                <BlogCardTitle> {blog.title}</BlogCardTitle>
                            </a>
                        </BlogCard>
                    );
                })}
            </div>
        </Container>
    </ContactWrapper>
);

/*
ContactSection Styles
 */
const blogContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 7rem;
`;
const BlogCard = styled(FlexColumn)`
    background: #fff;
    padding: 0rem;
    margin: 0 0.5rem 1rem 0.5rem;
    flex: 1;
    overflow: hidden;
    min-width: 230px;
    max-width: 230px;
`;
const BlogCardTitle = styled.h3`
    font-size: 1.4rem;
    padding: 0 1rem;
    text-align: center;
    color: #37474f;
`;

interface IBlogCardImageProps {
    image: string;
}
const BlogCardImage = styled("div")`
    height: 100px;
    background-image: url("${(props: IBlogCardImageProps) => props.image}");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    overflow: hidden;
`;

const ContactWrapper = styled.section`
    background-color: #755faa;
    position: relative;
    display: flex;
    justify-content: flex-start;
    background-image: url("${contact_bg_mobile}");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: auto;
    padding: 2rem;
    min-height: 600px;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xs}) {
        background-image: url("${contact_bg}");
        background-position: left;
        background-size: cover;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        background-position: bottom;
        padding: 5rem 2rem;
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xxxl}) {
        background-image: url("${contact_bg_desktop_xl}");

        background-size: auto 100%;
    }
`;

const SectionHeader = styled(BuylandoLPHeader)`
    text-align: center;
    color: #fff;
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 2.5rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        font-size: 2.8rem;
        line-height: 4.2rem;
        margin-bottom: 3.5rem;
    }
`;

const ParagraphSection = styled(Paragraph)`
    font-size: 1.8rem;
    line-height: 2.7rem;
    max-width: 700px;
    text-align: center;
    margin: 0 auto 3rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_sm}) {
        margin: 0 auto 5rem;
    }
`;

const Container = styled(BuylandoContainer)`
    display: flex;
    flex-direction: column;
    justify-content: start;

    @media screen and (min-width: ${(props) => props.theme.breakpoints_correct.screen_xs}) {
        justify-content: center;
    }
`;
