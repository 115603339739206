import React from "react";

import {currentStaticsDir} from "../../app/constants/current_statics_dir";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";

export const SchemaOrganization = () => {
    const json = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        name: "Gethome",
        url: `${ghCommonUrl}`,
        logo: `${ghCommonUrl}/${currentStaticsDir}/gethome_logo_600x138.png`,
        image: `${ghCommonUrl}/${currentStaticsDir}/gethome_logo_600x138.png`,
        email: "biuro@gethome.pl",
        description: "Oferty mieszkań i domów na sprzedaż oraz na wynajem. Bezpośrednio od deweloperów i biur nieruchomości.",
        address: {
            "@type": "PostalAddress",
            postalCode: "02627",
            streetAddress: "Naruszewicza 27/101",
            addressCountry: "PL",
            addressRegion: "Mazowieckie",
            addressLocality: "Warszawa",
            telephone: "22-825-60-71"
        },
        sameAs: [
            "https://www.facebook.com/gethomepl/",
            "https://www.linkedin.com/showcase/gethomepl/",
            "https://www.instagram.com/portal_gethome/",
            "https://gethome.pl/",
            "https://www.youtube.com/channel/UCXrxhSeBouvm4bGlP5IvSsw",
            "https://www.wikidata.org/wiki/Q115516631"
        ],
        contactPoint: {
            "@type": "ContactPoint",
            contactType: "Biuro",
            telephone: "22-825-60-71"
        },
        funder: {
            "@type": "Organization",
            name: "Property Group Sp z. o.o.",
            logo: "https://propertygroup.pl/wp-content/uploads/2020/11/PG-kolor.svg",
            url: "https://propertygroup.pl/",
            location: "Warszawa"
        },
        slogan: "Czas na mieszkanie!"
    };

    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} />;
};
