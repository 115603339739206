import React from "react";
import {css, Theme, useTheme} from "@emotion/react";
import {EditIcon} from "@web2/icons";
import {pluralize} from "@web2/string_utils";

interface IProps {
    authorArticleCount: number;
}

export const AuthorArticleCount = (props: IProps) => {
    const theme = useTheme();
    const articlePlural = pluralize(["artykuł", "artykuły", "artykułów"]);

    return (
        <p css={articleCount}>
            <EditIcon width={14} height={14} css={editIcon} mainColor={theme.colors.navy} /> {props.authorArticleCount}{" "}
            {articlePlural(props.authorArticleCount)}
        </p>
    );
};

const articleCount = (theme: Theme) => css`
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${theme.colors.gray_lighter};
    text-align: center;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        text-align: left;
    }
`;

const editIcon = css`
    position: relative;
    top: 1px;
    margin-right: 0.3rem;
`;
