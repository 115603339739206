import * as React from "react";

import {CollaborationSection} from "./CollaborationSection";
import {CollaborationStartSection} from "./CollaborationStartSection";
import {ContactSection} from "./ContactSection";
import {HeroSection} from "./HeroSection";
import {MapSearchSection} from "./MapSearchSection";
import {MarketSection} from "./MarketSection";
import {PartnerCollaborationSection} from "./PartnerCollaborationSection";
import {QualitySection} from "./QualitySection";
import {QuoteSection} from "./QuoteSection";
import {RegisterChecklist} from "./RegisterChecklist";

export const ForAgentDynamic = () => {
    return (
        <>
            <HeroSection />
            <RegisterChecklist />
            <CollaborationSection />
            <MarketSection />
            <QualitySection showRegistrationButton />
            <QuoteSection />
            <MapSearchSection />
            <CollaborationStartSection />
            <PartnerCollaborationSection />
            <ContactSection />
        </>
    );
};
