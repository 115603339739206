import React, {useState} from "react";
import {css, useTheme} from "@emotion/react";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button} from "@web2/button";
import {Checkbox} from "@web2/form";
import {getFormikFieldProps, handleFormikPost, IFormikSubmitFn} from "@web2/formik_utils";
import {appLink} from "@web2/gh_routes";
import {AtEmailIcon, BuildingsIcon, PersonIcon, PhoneIcon} from "@web2/icons";
import {Input} from "@web2/input";

import {errorMessages} from "../../app/utils/form_error_messages";
import {mb_0, mt_xxs, pb_xl} from "../../styles/helpers";
import {gtmRegisterAgencySend} from "../../tracking/google_tag_manager/gtm_register_agency_send";
import {agencyRegistrationPost} from "../actions/agency_register_post";

interface IProps {
    setIsSent: (isSent: boolean) => void;
    isPremium?: boolean;
}

export const agencyRegistrationInitialValues = {
    full_name: "",
    business_name: "",
    phone: "",
    email: "",
    is_portal_terms_of_use_active: false
};

const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(errorMessages.required),
    business_name: Yup.string().required(errorMessages.required),
    phone: Yup.string().required(errorMessages.required),
    email: Yup.string().required(errorMessages.required),
    is_portal_terms_of_use_active: Yup.boolean().oneOf([true], errorMessages.required)
});

export const AgencyRegisterForm = (props: IProps) => {
    const [formError, setFormError] = useState<string | null>(null);
    const onSubmit: IFormikSubmitFn<typeof agencyRegistrationInitialValues> = async (values, formikHelpers) => {
        setFormError(null);

        handleFormikPost(agencyRegistrationPost(values, props.isPremium), formikHelpers, {
            onSuccess: () => {
                gtmRegisterAgencySend();
                props.setIsSent(true);
            },
            onNonFieldError: (error) => setFormError(error)
        });
    };

    const theme = useTheme();

    return (
        <Formik initialValues={agencyRegistrationInitialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit} css={agencyRegisterForm}>
                    <Input
                        errorOnBottom
                        {...getFormikFieldProps(formikProps, "full_name")}
                        placeholder="Imię i nazwisko"
                        icon={<PersonIcon fillColor={theme.colors.gray_lighter} />}
                    />

                    <Input
                        errorOnBottom
                        {...getFormikFieldProps(formikProps, "business_name")}
                        placeholder="Nazwa agencji"
                        icon={<BuildingsIcon fillColor={theme.colors.gray_lighter} />}
                    />

                    <Input
                        errorOnBottom
                        {...getFormikFieldProps(formikProps, "phone")}
                        placeholder="Telefon"
                        type="phone"
                        icon={<PhoneIcon fillColor={theme.colors.gray_lighter} />}
                    />

                    <Input
                        errorOnBottom
                        {...getFormikFieldProps(formikProps, "email")}
                        placeholder="Adres email"
                        type="email"
                        icon={<AtEmailIcon fillColor={theme.colors.gray_lighter} />}
                    />

                    <div css={termsHolder}>
                        <Checkbox
                            label={
                                <p css={[mb_0, mt_xxs]}>
                                    Administratorem danych jest Property Group Sp. z o.o.{" "}
                                    <a href={appLink.termsRodo()} target="_blank" rel="noopener nofollow">
                                        więcej o ochronie danych
                                    </a>
                                    .<br />
                                    Akceptuję{" "}
                                    <a href={appLink.terms()} target="_blank">
                                        regulamin serwisu
                                    </a>
                                    .
                                </p>
                            }
                            {...getFormikFieldProps(formikProps, "is_portal_terms_of_use_active")}
                            errorOnBottom
                            css={pb_xl}
                        />
                    </div>

                    <Button type="submit" variant="success" disabled={formikProps.isSubmitting} fullWidth>
                        Wyślij
                    </Button>

                    {formError && <div css={errorContainer}>{formError}</div>}
                </form>
            )}
        </Formik>
    );
};

interface ITheme {
    breakpoints: {
        screen_md: string;
    };
    colors: {
        gray_very_dark: string;
        gray_warmer: string;
        gray_lighter: string;
        text_color: string;
        brand_danger: string;
    };
}

const agencyRegisterForm = (theme: ITheme) => css`
    input {
        font-size: 1.6rem;
        font-weight: 500;
        color: ${theme.colors.text_color};

        &::placeholder {
            color: ${theme.colors.gray_lighter};
        }

        @media screen and (min-width: ${theme.breakpoints.screen_md}) {
            font-size: 1.4rem;
        }

        & ~ svg {
            transform: scale(1.5);
            margin-left: -0.3rem;

            @media screen and (min-width: ${theme.breakpoints.screen_md}) {
                transform: scale(1.8);
                margin-left: 0;
            }
        }
    }
`;

const termsHolder = (theme: ITheme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 0;

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        padding: 1.5rem 0 1rem;
    }
`;

const errorContainer = (theme: ITheme) => css`
    color: ${theme.colors.brand_danger};
    padding: 2rem;
    text-align: center;
`;
