import * as React from "react";
import {css, keyframes} from "@emotion/react";

interface IProps {
    size?: string;
    color?: string;
    margin?: string;
    speedMultiplier?: number;
    className?: string;
}

const pulse = keyframes`
    0% {transform: scale(1);opacity: 1}
    45% {transform: scale(0.1);opacity: 0.7}
    80% {transform: scale(1);opacity: 1}
`;

export const PulseLoader = (props: IProps) => {
    return (
        <span data-testid="loader" className={props.className}>
            <span css={pulseLoader(1, props)} />
            <span css={pulseLoader(2, props)} />
            <span css={pulseLoader(3, props)} />
        </span>
    );
};

PulseLoader.defaultProps = {
    margin: "2px",
    speedMultiplier: 1,
    color: "#9069c0",
    size: "13px"
};

const pulseLoader = (i: number, props: IProps) => {
    const {speedMultiplier} = props;

    return css`
        background-color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        margin: ${props.margin};
        border-radius: 100%;
        display: inline-block;
        ${speedMultiplier &&
        css`
            animation: ${pulse} ${0.75 / speedMultiplier}s ${(i * 0.12) / speedMultiplier}s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        `};
        animation-fill-mode: both;
    `;
};
